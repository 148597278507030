import {
  CalendarOutlined,
  CreditCardOutlined,
  PercentageOutlined,
  PieChartOutlined,
  RetweetOutlined,
  SmileOutlined,
  StarOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { ReactComponent as AwardIcon } from '@assets/svg/award_icon.svg';
import { ReactComponent as ChatIcon } from '@assets/svg/chat_icon.svg';
import { i18next } from '@i18n';
import {
  LINK_BOOKING,
  LINK_CHAT,
  LINK_CLIENT,
  LINK_COLLECTION,
  LINK_COMMISSION,
  LINK_COUPON,
  LINK_EXPERT,
  LINK_PAYOUT,
  LINK_REVIEW,
  LINK_TRANSACTION,
} from '@utils';

export const mainMenu = [
  [
    /* {
      title: i18next.t('Dashboard'),
      link: '/',
      icon: <PieChartOutlined />,
    }, */
    {
      title: i18next.t('Clients'),
      link: `/${LINK_CLIENT}`,
      icon: <UserOutlined />,
    },
    {
      title: i18next.t('Psychologists'),
      link: `/${LINK_EXPERT}`,
      icon: <SmileOutlined />,
    },
    {
      title: i18next.t('Collections'),
      link: `/${LINK_COLLECTION}`,
      icon: <AwardIcon />,
    },
    {
      title: i18next.t('Bookings'),
      link: `/${LINK_BOOKING}`,
      icon: <CalendarOutlined />,
    },
    {
      title: i18next.t('Chats'),
      link: `/${LINK_CHAT}`,
      icon: <ChatIcon />,
    },
    {
      title: i18next.t('Reviews'),
      link: `/${LINK_REVIEW}`,
      icon: <StarOutlined />,
    },
    {
      title: i18next.t('Transactions'),
      link: `/${LINK_TRANSACTION}`,
      icon: <RetweetOutlined />,
    },
    {
      title: i18next.t('Payouts'),
      link: `/${LINK_PAYOUT}`,
      icon: <CreditCardOutlined />,
    },
    {
      title: i18next.t('Commissions'),
      link: `/${LINK_COMMISSION}`,
      icon: <PercentageOutlined />,
    },
    {
      title: i18next.t('Coupons'),
      link: `/${LINK_COUPON}`,
      icon: <TagOutlined />,
    },
  ],
  [],
];
