import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { SessionStatistic } from '@entities/Statistic';
import { StatisticHttp } from '@services/http';
import { alertError } from '@store/Alert';

import { getSessionFailure, getSessionSuccess } from './Statistic.action';
import { GET_SESSION_REQUEST } from './Statistic.constant';
import { IGetSessionRequest } from './Statistic.type';

function* workerGetSession({ payload }: IGetSessionRequest) {
  try {
    const { query } = payload;

    const data: SessionStatistic[] = yield call(
      StatisticHttp.getSession,
      query,
    );

    yield put(getSessionSuccess(data));
  } catch (error) {
    yield put(getSessionFailure());
    yield put(alertError(error));
  }
}

function* watchGetSession() {
  yield takeLatest(GET_SESSION_REQUEST, workerGetSession);
}

export const statisticWatchers: ForkEffect[] = [fork(watchGetSession)];
