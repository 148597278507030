import { Button, Radio, RadioChangeEvent, Space } from 'antd';

import { FilterRadioOption } from '@entities/Filter';
import { i18next } from '@i18n';

import { Props } from './index.type';

const { Group } = Radio;

const WidgetModalFilterRadio = (props: Props) => {
  const { filterValue, options, onFilterUpdate } = props;

  const changeHandler = ({ target: { value } }: RadioChangeEvent) => {
    onFilterUpdate(value as FilterRadioOption);
  };

  const clearHandler = () => {
    onFilterUpdate(null);
  };

  return (
    <Space direction="vertical">
      <Group onChange={changeHandler} value={filterValue}>
        <Space direction="vertical">
          {options?.map((option, id) => (
            <Radio value={option.value} key={`radio_${id}`}>
              {option.label}
            </Radio>
          ))}
        </Space>
      </Group>
      <Button
        type="link"
        className="modal-filter__button-clear"
        onClick={clearHandler}
      >
        {i18next.t('Clear')}
      </Button>
    </Space>
  );
};

export default WidgetModalFilterRadio;
