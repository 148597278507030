import { FullChat, FullChatOne } from '@entities/Chat';

import {
  DELETE_CHAT_FAILURE,
  DELETE_CHAT_REQUEST,
  DELETE_CHAT_SUCCESS,
  GET_LIST_CHAT_FAILURE,
  GET_LIST_CHAT_REQUEST,
  GET_LIST_CHAT_SUCCESS,
  GET_ONE_CHAT_FAILURE,
  GET_ONE_CHAT_REQUEST,
  GET_ONE_CHAT_SUCCESS,
} from './Chat.constant';
import { ChatActionsTypes } from './Chat.type';

export function getListChatRequest(query?: Query['query']): ChatActionsTypes {
  return {
    type: GET_LIST_CHAT_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListChatSuccess(
  payload: DataResponse<FullChat[]>,
): ChatActionsTypes {
  return {
    type: GET_LIST_CHAT_SUCCESS,
    payload,
  };
}

export function getListChatFailure(): ChatActionsTypes {
  return {
    type: GET_LIST_CHAT_FAILURE,
  };
}

export function getOneChatRequest(id: FullChat['id']): ChatActionsTypes {
  return {
    type: GET_ONE_CHAT_REQUEST,
    payload: {
      id,
    },
  };
}

export function getOneChatSuccess(payload: FullChatOne): ChatActionsTypes {
  return {
    type: GET_ONE_CHAT_SUCCESS,
    payload,
  };
}

export function getOneChatFailure(): ChatActionsTypes {
  return {
    type: GET_ONE_CHAT_FAILURE,
  };
}

export function deleteChatRequest(id: FullChat['id']): ChatActionsTypes {
  return {
    type: DELETE_CHAT_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteChatSuccess(): ChatActionsTypes {
  return {
    type: DELETE_CHAT_SUCCESS,
  };
}

export function deleteChatFailure(): ChatActionsTypes {
  return {
    type: DELETE_CHAT_FAILURE,
  };
}
