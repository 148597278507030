import {
  GET_LIST_TIMEZONE_FAILURE,
  GET_LIST_TIMEZONE_REQUEST,
  GET_LIST_TIMEZONE_SUCCESS,
} from './Timezone.constant';
import { ITimezoneState, TimezoneActionsTypes } from './Timezone.type';

const initialState: ITimezoneState = {
  timezoneList: [],
  loading: false,
};

export default function timezoneReducer(
  state = initialState,
  action: TimezoneActionsTypes,
): ITimezoneState {
  switch (action.type) {
    case GET_LIST_TIMEZONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_TIMEZONE_SUCCESS: {
      return {
        ...state,
        timezoneList: action.payload.timezones,
      };
    }
    case GET_LIST_TIMEZONE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
