import { LayoutMenu } from '@layouts';
import { ModalType, showModal } from '@store/Modal';
import { deleteReviewRequest } from '@store/Review';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const ReviewUIActions = ({ id, dispatch }: Props) => {
  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.EDIT_REVIEW,
        modalProps: { reviewId: id },
      }),
    );
  };

  const onDeleteReviewHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: <p>Are you sure you want to delete the review?</p>,
          action: () => dispatch(deleteReviewRequest({ id })),
        },
      }),
    );
  };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [{ title: 'Delete', onClick: onDeleteReviewHandler }],
    }),
    buttons: [{ title: 'Edit', onClick: onEditClickHandler }],
  });
};

export default ReviewUIActions;
