import { FullTransaction } from '@entities/Transaction';

import {
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  CLEAR_LIST_TRANSACTION,
  GET_LIST_TRANSACTION_FAILURE,
  GET_LIST_TRANSACTION_REQUEST,
  GET_LIST_TRANSACTION_SUCCESS,
} from './Transaction.constant';
import { TransactionActionsTypes } from './Transaction.type';

export function getListTransactionRequest(query = ''): TransactionActionsTypes {
  return {
    type: GET_LIST_TRANSACTION_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListTransactionSuccess(
  payload: DataResponse<FullTransaction[]>,
): TransactionActionsTypes {
  return {
    type: GET_LIST_TRANSACTION_SUCCESS,
    payload,
  };
}

export function getListTransactionFailure(): TransactionActionsTypes {
  return {
    type: GET_LIST_TRANSACTION_FAILURE,
  };
}

export function clearListTransaction(): TransactionActionsTypes {
  return {
    type: CLEAR_LIST_TRANSACTION,
  };
}

export function addTransactionRequest(payload = {}): TransactionActionsTypes {
  return {
    payload,
    type: ADD_TRANSACTION_REQUEST,
  };
}
export function addTransactionSuccess(
  payload: DataResponse<FullTransaction>,
): TransactionActionsTypes {
  return {
    type: ADD_TRANSACTION_SUCCESS,
    payload,
  };
}

export function addTransactionFailure(): TransactionActionsTypes {
  return {
    type: GET_LIST_TRANSACTION_FAILURE,
  };
}
