import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useQuery } from '@hooks';
import { columns } from '@modules/Review/constant';
import { RootState, useAppDispatch } from '@store/index';
import { getListReviewRequest } from '@store/Review';
import { UITable } from '@ui';
import { Role } from '@utils';

import { Props } from './index.type';

import './style.scss';

const UserDetailReview = ({ role }: Props) => {
  const dispatch = useAppDispatch();

  const { reviewList, meta, loading } = useSelector(
    (state: RootState) => state.review,
  );

  const { userId } = useParams<'userId'>();
  const { query, updateQuery } = useQuery({
    filter: {
      [role === Role.ANGEL ? 'angelId' : 'userId']: userId ? +userId : null,
    },
  });

  const getRequest = useCallback(() => {
    dispatch(getListReviewRequest(query));
  }, [dispatch, query]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  return (
    <UITable
      dataSource={reviewList}
      loading={loading}
      columns={columns({ role })}
      meta={meta}
      onPaginationChange={onPaginationChange}
    />
  );
};

export default UserDetailReview;
