import { useCallback, useEffect } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { getListChatRequest } from '@store/Chat';
import { RootState, useAppDispatch } from '@store/index';
import { UIHeader, UITable } from '@ui';

import { columns, filterOptions, sortOption } from '../constant';

const ChatList = () => {
  const dispatch = useAppDispatch();
  const { query, updateQuery } = useQuery();
  const { chatList, meta, loading } = useSelector(
    (state: RootState) => state.chat,
  );

  const getRequest = useCallback(() => {
    dispatch(getListChatRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <>
      <UIHeader
        title={i18next.t('Chats')}
        isSearch
        searchTitle={i18next.t('Filter chats')}
        viewContainerName="Chats"
        sortOption={sortOption({})}
        filterOptions={filterOptions}
      />
      <Row>
        <UITable
          dataSource={chatList}
          loading={loading}
          columns={columns()}
          meta={meta}
          onPaginationChange={onPaginationChange}
          isScrollDefault
        />
      </Row>
    </>
  );
};

export default ChatList;
