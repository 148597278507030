/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Col,
  Form,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from 'antd';
import Text from 'antd/lib/typography/Text';

import { CouponUsage } from '@entities/Coupon';
import { i18next } from '@i18n';

import { CouponUIItemAdd } from '../../../ui';
import { Props } from './index.type';

import './style.scss';

const CouponDetailUsageLimits = ({ form }: Props) => {
  const onChange = (e: RadioChangeEvent) => {
    const value = e?.target?.value || CouponUsage.ONE;

    if (value !== CouponUsage.LIMIT) {
      form.setFieldsValue({ quantity: null });
    }

    form.setFieldsValue({ usage: value });
  };

  return (
    <CouponUIItemAdd title={i18next.t('Usage limits')}>
      <div className="usage-wrapper">
        <Row>
          <Form.Item name="usage">
            <Radio.Group onChange={onChange}>
              <Space direction="vertical">
                <Radio value={CouponUsage.ONE}>{i18next.t('One use')}</Radio>
                <Radio value={CouponUsage.LIMIT}>
                  {i18next.t('Limited use')}
                </Radio>
                <Radio value={CouponUsage.UNLIMIT}>
                  {i18next.t('Unlimited use')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Row>
        <Row className="quantity-value">
          <Col span={24}>
            <Text type="secondary">{i18next.t('Quantity')}</Text>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.usage !== currentValues.usage
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="quantity"
                  {...(getFieldValue('usage') === CouponUsage.LIMIT && {
                    rules: [
                      {
                        required: true,
                      },
                      {
                        min: 1,
                        type: 'number',
                      },
                    ],
                  })}
                >
                  <InputNumber
                    {...(getFieldValue('usage') !== CouponUsage.LIMIT && {
                      disabled: true,
                    })}
                    min={1}
                    step={1}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </CouponUIItemAdd>
  );
};

export default CouponDetailUsageLimits;
