import Text from 'antd/lib/typography/Text';

import { Props } from './index.type';

const UserUITariff = ({ tariff }: Props) => {
  return (
    <Text>
      €{tariff?.rate}/<Text type="secondary">min</Text>
    </Text>
  );
};

export default UserUITariff;
