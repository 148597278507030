import { i18next } from '@i18n';
import { LayoutContainerDivider } from '@layouts';

import { UserUIItemInfo } from '../../ui';
import { Props } from './index.type';

const UserDetailInfo = (props: Props) => {
  const {
    user: { specialties, therapies, languages },
  } = props;

  const children = [
    specialties && (
      <UserUIItemInfo
        title={i18next.t('Specialties')}
        data={specialties}
        key={1}
      />
    ),
    therapies && (
      <UserUIItemInfo
        title={i18next.t('Types of Therapy')}
        data={therapies}
        key={2}
      />
    ),
    languages && (
      <UserUIItemInfo title={i18next.t('Languages')} data={languages} key={3} />
    ),
  ];

  return <LayoutContainerDivider children={children} />;
};

export default UserDetailInfo;
