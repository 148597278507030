import { ColumnType, ColumnsType } from 'antd/lib/table';

import type {
  FilterOption,
  FullReview,
  FullUser,
  SortOption,
} from '@entities/index';
import { i18next } from '@i18n';
import type { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink, UICellRate, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  LINK_CLIENT,
  LINK_EXPERT,
  LINK_REVIEW,
  Role,
} from '@utils';

import { ReviewUIActions } from './ui';

const clientColumn: ColumnType<FullReview> = {
  title: i18next.t('Client'),
  dataIndex: 'user',
  key: 'user',
  width: COLUMN_WIDTH_NAME,
  render: (value: FullUser) =>
    UICellFullName({
      lastName: value.profile.lastName,
      firstName: value.profile.firstName,
      src: value.avatar?.path,
      link: `/${LINK_CLIENT}/${value.id}`,
    }),
};

const angelColumn: ColumnType<FullReview> = {
  title: i18next.t('Expert'),
  dataIndex: 'angel',
  key: 'angel',
  width: COLUMN_WIDTH_NAME,
  render: (value: FullUser) =>
    UICellFullName({
      lastName: value.profile.lastName,
      firstName: value.profile.firstName,
      src: value.avatar?.path,
      link: `/${LINK_EXPERT}/${value.id}`,
    }),
};

const renderColumn = (role?: Role.ANGEL | Role.USER) => {
  if (role === Role.ANGEL) {
    return [clientColumn];
  }

  if (role === Role.USER) {
    return [angelColumn];
  }

  return [angelColumn, clientColumn];
};

const renderActionColumn = (
  dispatch?: AppDispatch,
): ColumnsType<FullReview> => {
  if (dispatch) {
    return [
      {
        title: i18next.t('Actions'),
        key: 'action',
        width: COLUMN_WIDTH_ACTIONS,
        render: (_value: FullReview, { id }: FullReview) =>
          ReviewUIActions({
            id,
            dispatch,
          }),
        align: 'right',
      },
    ];
  }

  return [];
};

export const columns = ({
  role,
  dispatch,
}: {
  dispatch?: AppDispatch;
  role?: Role.ANGEL | Role.USER;
}): ColumnsType<FullReview> => {
  return [
    {
      title: i18next.t('Id'),
      key: 'id',
      width: COLUMN_WIDTH_ID,
      render: (value: FullReview) =>
        UICellLink({
          link: `/${LINK_REVIEW}/${value.id}`,
          value: value.id,
        }),
    },
    ...renderColumn(role),
    {
      title: i18next.t('Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
    },
    {
      title: i18next.t('Text'),
      dataIndex: 'message',
      key: 'message',
      ellipsis: true,
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: i18next.t('Rating'),
      dataIndex: 'rating',
      key: 'rating',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value) =>
        UICellRate({ rating: value, count: 5, disabled: true }),
    },
    ...renderActionColumn(dispatch),
  ];
};

const clientSort: SortOption[] = [
  {
    label: i18next.t('Client name (A-Z)'),
    name: 'user_full_name',
    direction: 'asc',
  },
  {
    label: i18next.t('Client name (Z-A)'),
    name: 'user_full_name',
    direction: 'desc',
  },
];

const angelSort: SortOption[] = [
  {
    label: i18next.t('Expert name (A-Z)'),
    name: 'angel_full_name',
    direction: 'asc',
  },
  {
    label: i18next.t('Expert name (Z-A)'),
    name: 'angel_full_name',
    direction: 'desc',
  },
];

const renderSort = (role?: Role) => {
  if (role === Role.ANGEL) {
    return clientSort;
  }

  if (role === Role.USER) {
    return angelSort;
  }

  return [...angelSort, ...clientSort];
};

export const sortOption = ({ role }: { role?: Role }): SortOption[] => [
  {
    label: i18next.t('Id (ascending)'),
    name: 'id',
    direction: 'asc',
  },
  {
    label: i18next.t('Id (descending)'),
    name: 'id',
    direction: 'desc',
  },
  ...renderSort(role),
  {
    label: i18next.t('Date (oldest first)'),
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: i18next.t('Date (newest first)'),
    name: 'created_at',
    direction: 'desc',
  },
  {
    label: i18next.t('Rating (ascending)'),
    name: 'rating',
    direction: 'asc',
  },
  {
    label: i18next.t('Rating (descending)'),
    name: 'rating',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions: FilterOption[] = [
  {
    name: 'created_at',
    label: i18next.t('Date'),
    type: 'radio-range',
    options: [
      {
        label: i18next.t('Today'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: i18next.t('Last 7 days'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: i18next.t('Last 30 days'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
  {
    name: 'rating',
    label: i18next.t('Rating'),
    type: 'number-range',
    step: 1,
    min: 0,
    max: 5,
  },
];
