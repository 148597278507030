import { ChangeEvent, useState } from 'react';

import { Button, Input, Space, Typography } from 'antd';

import { useValidation } from '@hooks';
import { i18next } from '@i18n';

import UIViewTag from '../Tag';
import { Props } from './index.type';

import './style.scss';

const { Title, Text } = Typography;

const UIViewOverlay = (props: Props) => {
  const { onSaveView, ...tagProps } = props;

  const [name, setName] = useState('');

  const { trimValue } = useValidation({ value: name });

  const onChangeNameHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const onSaveHandler = () => {
    setName('');
    onSaveView(name);
  };

  return (
    <Space direction="vertical" className="user-view__overlay">
      <UIViewTag {...tagProps} />
      <Title level={5}>{i18next.t('View name')}</Title>
      <Input
        placeholder={i18next.t('Enter a name')}
        allowClear
        value={trimValue}
        onChange={onChangeNameHandler}
      />
      <Text type="secondary">
        {i18next.t('Views are saved as a new tab at the top of this list.')}
      </Text>
      <Button
        type="primary"
        size="large"
        className="user-view__button-save"
        onClick={onSaveHandler}
      >
        {i18next.t('Save view')}
      </Button>
    </Space>
  );
};

export default UIViewOverlay;
