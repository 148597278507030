import { all } from 'redux-saga/effects';

import { authWatchers } from './Auth';
import { bookingWatchers } from './Booking';
import { chatWatchers } from './Chat';
import { collectionWatchers } from './Collection';
import { commentWatchers } from './Comment';
import { commissionWatchers } from './Commission';
import { couponWatchers } from './Coupon';
import { languageWatchers } from './Language/Language.saga';
import { mediaWatchers } from './Media';
import { paymentWatchers } from './Payment/Payment.saga';
import { profileWatchers } from './Profile';
import { reviewWatchers } from './Review';
import { scheduleWatchers } from './Schedule';
import { specialtyWatchers } from './Specialty/Specialty.saga';
import { statisticWatchers } from './Statistic';
import { therapyWatchers } from './Therapy/Therapy.saga';
import { timezoneWatchers } from './Timezone';
import { transactionWatchers } from './Transaction';
import { userWatchers } from './User';
import { userActionWatchers } from './UserAction';

export function* rootSagas() {
  const watchers = [
    ...authWatchers,
    ...chatWatchers,
    ...profileWatchers,
    ...userWatchers,
    ...collectionWatchers,
    ...timezoneWatchers,
    ...reviewWatchers,
    ...bookingWatchers,
    ...scheduleWatchers,
    ...statisticWatchers,
    ...specialtyWatchers,
    ...transactionWatchers,
    ...commentWatchers,
    ...couponWatchers,
    ...therapyWatchers,
    ...userActionWatchers,
    ...languageWatchers,
    ...mediaWatchers,
    ...paymentWatchers,
    ...commissionWatchers,
  ];

  yield all(watchers);
}
