import { Therapy } from '@entities/Therapy';
import { http } from '@utils';

const basePath = '/api/therapies';

export const getList = async (): Promise<DataResponse<Therapy[]>> => {
  const { data } = await http.get<DataResponse<Therapy[]>>(basePath);

  return data;
};
