import { i18next } from '@i18n';
import { ModalType, showModal } from '@store/Modal';
import { deleteUserRequest, updateUserRequest } from '@store/User';
import { UICellAction } from '@ui';

import UserUIMenu from '../Menu';
import { Props } from './index.type';

const UserUIActions = ({ id, user, query, role, dispatch }: Props) => {
  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.USER,
        modalProps: { userId: id, query, role },
      }),
    );
  };

  const onDeleteUserHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: (
            <p>
              {i18next.t('Are you sure you want to delete the Client')}
              <b> {user?.profile?.fullName || ''}</b>?
            </p>
          ),
          action: () => dispatch(deleteUserRequest({ id, query })),
        },
      }),
    );
  };

  const onDeactivateUserHandler = () => {
    dispatch(
      updateUserRequest({
        id,
        body: { isActive: !user.isActive },
        query,
      }),
    );
  };

  return UICellAction({
    dropdownMenu: UserUIMenu({
      onDelete: onDeleteUserHandler,
      onDeactivate: onDeactivateUserHandler,
      user,
      role,
    }),
    buttons: [{ title: i18next.t('Edit'), onClick: onEditClickHandler }],
  });
};

export default UserUIActions;
