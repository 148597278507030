import { Button, Form, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';
import {
  removeAvatar,
  updateProfileRequest,
  uploadAvatarRequest,
} from '@store/Profile';
import {} from '@store/User';
import { UploadFile } from '@utils';

import WidgetModalEditProfileForm from './Form';
import { FormValues } from './Form/index.type';

const WidgetModalProfile = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const { profile, loading } = useSelector((state: RootState) => state.profile);

  const closeModal = () => {
    form.resetFields();
    dispatch(hideModal());
  };

  const saveProfile = () => {
    form.submit();
  };

  const onFormSubmitHandler = (values: FormValues) => {
    dispatch(updateProfileRequest(values));
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadAvatarRequest(file));
  };

  const onRemoveImageHandler = () => {
    dispatch(removeAvatar());
  };

  return (
    <Modal
      visible
      title={i18next.t('Edit profile')}
      onOk={saveProfile}
      onCancel={closeModal}
      footer={[
        <Button
          key="Save changes"
          type="primary"
          onClick={saveProfile}
          size="large"
          className="edit-form__save-button"
        >
          {i18next.t('Save changes')}
        </Button>,
      ]}
      className="edit-form"
    >
      <Spin spinning={loading}>
        {profile && (
          <WidgetModalEditProfileForm
            user={profile}
            form={form}
            onFormSubmit={onFormSubmitHandler}
            onUploadImage={onUploadImageHandler}
            onRemoveImage={onRemoveImageHandler}
          />
        )}
      </Spin>
    </Modal>
  );
};

export default WidgetModalProfile;
