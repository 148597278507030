import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { PaymentHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { getOneBookingRequest } from '@store/Booking';
import { hideModal } from '@store/Modal';

import { refundFailure, refundSuccess } from './Payment.action';
import { REFUND_REQUEST } from './Payment.constant';
import { IRefundRequest } from './Payment.type';

function* workerRefund({ payload }: IRefundRequest) {
  try {
    const { bookingId } = payload;

    yield call(PaymentHttp.refund, payload);

    yield put(refundSuccess());
    yield put(getOneBookingRequest(bookingId));
    yield put(alertSuccess('Refund was successfully'));
    yield put(hideModal());
  } catch (error) {
    yield put(refundFailure());
    yield put(alertError(error));
  }
}

function* watchRefund() {
  yield takeLatest(REFUND_REQUEST, workerRefund);
}

export const paymentWatchers: ForkEffect[] = [fork(watchRefund)];
