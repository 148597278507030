import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullTimezone } from '@entities/Timezone';
import { TimezoneHttp } from '@services/http';

import { alertError } from '../Alert';
import {
  getListTimezoneFailure,
  getListTimezoneSuccess,
} from './Timezone.action';
import { GET_LIST_TIMEZONE_REQUEST } from './Timezone.constant';

function* workerGetListTimezone() {
  try {
    const timezones: FullTimezone[] = yield call(TimezoneHttp.getListTimezone);

    yield put(getListTimezoneSuccess(timezones));
  } catch (error) {
    yield put(getListTimezoneFailure());
    yield put(alertError(error));
  }
}

function* watchGetListTimezone() {
  yield takeLatest(GET_LIST_TIMEZONE_REQUEST, workerGetListTimezone);
}

export const timezoneWatchers: ForkEffect[] = [fork(watchGetListTimezone)];
