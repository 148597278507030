import { Fragment, MouseEvent, useCallback, useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Col, InputNumber, Row, Select, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useSelector } from 'react-redux';

import { FullUser } from '@entities/User';
import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import {
  clearSearchUserList,
  getSearchListUserRequest,
  getSearchListUserSuccess,
} from '@store/User';
import { UIAvatar, UISearch } from '@ui';
import { QueryHelper } from '@utils';

import { Props } from './index.type';

import './style.scss';

const { Option } = Select;

const WidgetModalUIPeople = ({
  defaultValues,
  onChange,
  userSelectedError,
  setAmount,
  setOperation,
  params,
}: Props) => {
  const dispatch = useAppDispatch();
  const { searchUserList, loadingSearch } = useSelector(
    (state: RootState) => state.user,
  );
  const [selectedId, setSelectedId] = useState<number[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<FullUser[]>([]);
  const onChangeAmountHandler = (value: number) => setAmount(value);
  const onChangeOperationHandler = (value: string) => setOperation(value);

  const selectBefore = (
    <Select
      onChange={onChangeOperationHandler}
      defaultValue="income"
      style={{
        width: 60,
      }}
    >
      <Option value="income">+</Option>
      <Option value="outcome">-</Option>
    </Select>
  );

  const onSearchChange = (values: string | null) => {
    if (values && values.length > 2) {
      dispatch(
        getSearchListUserRequest(
          QueryHelper.stringify({
            filter: {
              role: 'user',
              search: values,
              ...params,
            },
          }),
        ),
      );
    }
    if (values && values.length === 0) {
      dispatch(getSearchListUserSuccess({ data: [] }));
    }
  };

  const onDeleteUser = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedId([]);
    onChange(0);
    setSelectedUsers([]);
  };
  const onChoiceUser = (e: MouseEvent<HTMLDivElement>, item: FullUser) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedId([item.id]);
    onChange(item.id);
    setSelectedUsers([item]);
    dispatch(clearSearchUserList());
  };

  useEffect(() => {
    setOperation('income');
    setAmount(100);
  }, []);
  useEffect(() => {
    if (defaultValues) {
      setSelectedId(defaultValues);
    }
  }, [defaultValues]);

  const renderUsers = useCallback(
    (list: FullUser[]) =>
      list?.map((item, key) => (
        <Fragment key={`people_${key}`}>
          <div
            className="user-select-list__item"
            onClick={(e) => onChoiceUser(e, item)}
          >
            <Col span={4}>
              <UIAvatar path={item.avatar?.path} />
            </Col>
            <Col span={18}>
              <Text strong>{item.profile?.fullName || ''}</Text>
            </Col>
            <Col span={1}>
              {!!selectedId.length &&
                selectedId.length > 0 &&
                (
                  <a href="#" onClick={onDeleteUser}>
                    <CloseOutlined />
                  </a>
                )!}
            </Col>
          </div>
        </Fragment>
      )),
    [selectedId],
  );

  return (
    <Space direction="vertical" size="large" className="people-search__wrap">
      {!selectedUsers.length && (
        <>
          <UISearch
            status={userSelectedError ? 'error' : ''}
            rules={[{ required: true }]}
            placeholder={i18next.t('Please input Name')}
            onSearchChange={onSearchChange}
          />
          {userSelectedError && (
            <div className="user-select-list__validation ant-form-item-explain-error">
              Необходимо выбрать пользователя
            </div>
          )}
        </>
      )}
      <Spin spinning={loadingSearch}>
        <Row justify="space-between" align="middle" gutter={[12, 12]}>
          {renderUsers(searchUserList)}
          {renderUsers(selectedUsers)}
          <div className={'transaction-create__amount'}>
            <InputNumber
              min={1}
              max={10000}
              defaultValue={100}
              addonBefore={selectBefore}
              addonAfter={'€'}
              placeholder={i18next.t('Enter amount')}
              onChange={onChangeAmountHandler}
            />
          </div>
        </Row>
      </Spin>
    </Space>
  );
};

export default WidgetModalUIPeople;
