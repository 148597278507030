import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullTransaction } from '@entities/Transaction';
import { TransactionHttp } from '@services/http';
import { alertError } from '@store/Alert';

import {
  addTransactionFailure,
  addTransactionSuccess,
  getListTransactionFailure,
  getListTransactionSuccess,
} from './Transaction.action';
import {
  ADD_TRANSACTION_REQUEST,
  GET_LIST_TRANSACTION_REQUEST,
} from './Transaction.constant';
import {
  IAddTransactionRequest,
  IGetListTransactionRequest,
} from './Transaction.type';

function* workerGetListTransaction(action: IGetListTransactionRequest) {
  try {
    const { query } = action.payload;

    const payload: DataResponse<FullTransaction[]> = yield call(
      TransactionHttp.getList,
      query,
    );

    yield put(getListTransactionSuccess(payload));
  } catch (error) {
    yield put(getListTransactionFailure());
    yield put(alertError(error));
  }
}
function* workerAddTransaction(action: IAddTransactionRequest) {
  try {
    const payload: DataResponse<FullTransaction> = yield call(
      TransactionHttp.create,
      action.payload,
    );

    yield put(addTransactionSuccess(payload));
  } catch (error) {
    yield put(addTransactionFailure());
    yield put(alertError(error));
  }
}

function* watchGetListTransaction() {
  yield takeLatest(GET_LIST_TRANSACTION_REQUEST, workerGetListTransaction);
}
function* watchAddTransaction() {
  yield takeLatest(ADD_TRANSACTION_REQUEST, workerAddTransaction);
}

export const transactionWatchers: ForkEffect[] = [
  fork(watchGetListTransaction),
  fork(watchAddTransaction),
];
