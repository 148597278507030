/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Col, Divider, Form, Row, Select } from 'antd';

import { CouponCodeType, PRECENTAGE } from '@entities/index';
import { i18next } from '@i18n';
import { useAppDispatch } from '@store/index';
import { refundRequest } from '@store/Payment';
import { UIModal } from '@ui';
import { NumberHelper, StringHelper } from '@utils';

import { INITIAL_VALUES } from './constant';
import { FormValue, Props } from './index.type';

import './index.scss';

const WidgetModalBookingRefund = ({
  id,
  price,
  discount,
  finalPrice,
  coupon,
  angel,
}: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValue>();

  const acceptHandler = () => {
    form.submit();
  };

  const platformPrc = angel?.tariff?.commission?.amount || 0;
  const angelPrc = 100 - platformPrc;

  const platformPrice = NumberHelper.percentSub(
    coupon?.isExpertComission ? finalPrice : price,
    platformPrc,
  );
  const angelPrice = NumberHelper.percentSub(
    coupon?.isExpertComission ? finalPrice : price,
    angelPrc,
  );

  const handlerFinish = (values: FormValue) => {
    dispatch(
      refundRequest({
        ...values,
        bookingId: id,
      }),
    );
  };

  return (
    <UIModal
      title={i18next.t('Make a Refund')}
      visible
      onAccept={acceptHandler}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 18 }}
        requiredMark={false}
        size="large"
        initialValues={INITIAL_VALUES}
        onFinish={handlerFinish}
        className="modal-create form-refund"
        autoComplete="off"
      >
        <div className="form-refund_child">
          <Divider orientation="left" plain orientationMargin="0">
            <strong>{i18next.t('Client pyament')}</strong>
          </Divider>
          <Row justify="space-between">
            <Col>{i18next.t('Price')}</Col>
            <Col>{StringHelper.toMoney(price)}</Col>
          </Row>
          {coupon && (
            <>
              <Row justify="space-between">
                <Col>{i18next.t('Coupon code')}</Col>
                <Col>{coupon.code}</Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  {i18next.t('Coupon discount')} (
                  {coupon.codeType === CouponCodeType.PERCENTAGE
                    ? StringHelper.toPercent(coupon.value)
                    : StringHelper.toMoney(coupon.value)}
                  )
                </Col>
                <Col>{StringHelper.toMoney(discount)}</Col>
              </Row>
            </>
          )}
          <Row justify="space-between">
            <Col>{i18next.t('Final price (for Client)')}</Col>
            <Col>{StringHelper.toMoney(finalPrice)}</Col>
          </Row>
        </div>
        <div className="form-refund_child">
          <Divider orientation="left" plain orientationMargin="0">
            <strong>{i18next.t('Angel reward')}</strong>
          </Divider>
          <Row justify="space-between">
            <Col>{i18next.t('Angel reward rate')}</Col>
            <Col>{StringHelper.toPercent(angelPrc)}</Col>
          </Row>
          {coupon && (
            <Row justify="space-between">
              <Col>{i18next.t("Coupon affects Angel's reward?")}</Col>
              <Col>
                {coupon.isExpertComission ? i18next.t('Yes') : i18next.t('No')}
              </Col>
            </Row>
          )}
          <Row justify="space-between">
            <Col>{i18next.t('Angel reward')}</Col>
            <Col>{StringHelper.toMoney(angelPrice)}</Col>
          </Row>
          <Row justify="space-between">
            <Col>{i18next.t('Platform commission')}</Col>
            <Col>{StringHelper.toMoney(platformPrice)}</Col>
          </Row>
        </div>
        <div className="form-refund_child">
          <Divider orientation="left" plain orientationMargin="0">
            <strong>{i18next.t('Refund parameters')}</strong>
          </Divider>
          <Form.Item
            label={i18next.t('Refund rate')}
            name="percentage"
            colon={false}
            labelAlign={'left'}
            labelCol={{ span: 14 }}
            rules={[
              {
                required: true,
                message: i18next.t('Please input percentage!'),
              },
            ]}
            style={{ marginBottom: 5 }}
          >
            <Select placeholder={i18next.t('Refund rate')}>
              {PRECENTAGE.map((pct) => (
                <Select.Option
                  value={pct}
                  key={`percent_${pct}`}
                  label={StringHelper.toPercent(pct)}
                >
                  {StringHelper.toPercent(pct)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.percentage !== currentValues.percentage ||
              prevValues.deductFromExpert !== currentValues.deductFromExpert
            }
          >
            {({ getFieldValue: getValue }) => (
              <Row justify="space-between">
                <Col>Client will get refund</Col>
                <Col>
                  {StringHelper.toMoney(
                    NumberHelper.percentSub(
                      finalPrice,
                      getValue('percentage') as number,
                    ),
                  )}
                </Col>
              </Row>
            )}
          </Form.Item>
          <Form.Item
            label={i18next.t('Deduct from expert')}
            name="deductFromExpert"
            colon={false}
            labelAlign={'left'}
            labelCol={{ span: 14 }}
            style={{ marginBottom: 5 }}
          >
            <Select>
              <Select.Option value={true}>{i18next.t('Yes')}</Select.Option>
              <Select.Option value={false}>{i18next.t('No')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.percentage !== currentValues.percentage ||
              prevValues.deductFromExpert !== currentValues.deductFromExpert
            }
          >
            {({ getFieldValue: getValue }) => {
              const prc = getValue('percentage') as number;

              const priceForExpert = getValue('deductFromExpert')
                ? NumberHelper.percentSub(angelPrice, 100 - prc)
                : angelPrice;

              const priceForPlatform = getValue('deductFromExpert')
                ? NumberHelper.percentSub(platformPrice, 100 - prc)
                : platformPrice;

              return (
                <>
                  <Row justify="space-between">
                    <Col>{i18next.t('Expert will get a reward')}</Col>
                    <Col>{StringHelper.toMoney(priceForExpert)}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>{i18next.t('Platform will get comission')}</Col>
                    <Col>{StringHelper.toMoney(priceForPlatform)}</Col>
                  </Row>
                </>
              );
            }}
          </Form.Item>
        </div>
      </Form>
    </UIModal>
  );
};

export default WidgetModalBookingRefund;
