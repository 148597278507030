import { useSelector } from 'react-redux';

import { RootState } from '@store/index';
import { Role } from '@utils';

const useAuth = () => {
  const user = useSelector((state: RootState) => state.auth.data);

  return { isAuth: user?.role === Role.ADMIN, user };
};

export default useAuth;
