import { ColumnsType } from 'antd/lib/table';

import { SortOption } from '@entities/Sort';
import { FullUser, Profile } from '@entities/User';
import { i18next } from '@i18n';
import type { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink } from '@ui';
import { LINK_EXPERT } from '@utils';

import { CollectionUIActionDetail } from '../ui';

export const columns = ({
  collectionId,
  query,
  dispatch,
}: {
  collectionId: number;
  dispatch: AppDispatch;
  query: Query['query'];
}): ColumnsType<FullUser> => [
  {
    title: i18next.t('Id'),
    dataIndex: 'id',
    key: 'id',
    render: (value, record) =>
      UICellLink({ value, link: `/${LINK_EXPERT}/${record.id}` }),
    width: 70,
  },
  {
    title: i18next.t('Expert'),
    dataIndex: ['profile'],
    key: 'name',
    render: (profile: Profile, record) =>
      UICellFullName({
        firstName: profile.firstName,
        lastName: profile.lastName,
        link: `/${LINK_EXPERT}/${record.id}`,
        src: record?.avatar?.path,
      }),
    width: 300,
  },
  {
    title: i18next.t('Booking count'),
    dataIndex: 'bookingCount',
    key: 'bookingCount',
    render: (value) => `${value}`,
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    width: 50,
    render: (id) =>
      CollectionUIActionDetail({ id, collectionId, query, dispatch }),
  },
];

export const orderOption: SortOption[] = [
  {
    label: i18next.t('Id (ascending)'),
    name: 'id',
    direction: 'asc',
  },
  {
    label: i18next.t('Id (descending)'),
    name: 'id',
    direction: 'desc',
  },
  {
    label: i18next.t('Name (A-Z)'),
    name: 'full_name',
    direction: 'asc',
  },
  {
    label: i18next.t('Name (Z-A)'),
    name: 'full_name',
    direction: 'desc',
  },
  {
    label: i18next.t('Booking count (ascending)'),
    name: 'booking_count',
    direction: 'asc',
  },
  {
    label: i18next.t('Booking count (descending)'),
    name: 'booking_count',
    direction: 'desc',
  },
];
