import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import cn from 'classnames';

import { Props } from './index.type';

const LayoutInput = (props: Props) => {
  const { children, title, className } = props;

  return (
    <Row className={cn('select', className)} wrap={false}>
      <Col className="title">
        <Text>{title}:</Text>
      </Col>
      <Col className="select">{children}</Col>
    </Row>
  );
};

export default LayoutInput;
