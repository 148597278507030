import { Button } from 'antd';
import { NavLink } from 'react-router-dom';

import { Props } from './index.type';
import './style.scss';

const UICellLink = ({ value, link }: Props) => {
  return (
    <NavLink to={link}>
      <Button id="ant-button-cell-link" type="link">
        {value}
      </Button>
    </NavLink>
  );
};

export default UICellLink;
