import cn from 'classnames';

import { Button, LINK_CLIENT, LINK_EXPERT, Role } from '@utils';

import UIAvatar from '../Avatar';
import UIButton from '../Button';
import { Props } from './index.type';

import './style.scss';

const UIUserCard = (props: Props) => {
  const { id, firstName, lastName, role, avatar, className } = props;
  const isExpert = role === Role.ANGEL;
  const link = `/${isExpert ? LINK_EXPERT : LINK_CLIENT}/${id}`;

  return (
    <div className={cn('ui-user-card', className)}>
      <div className="ui-user-card-role">{isExpert ? 'Expert' : 'Client'}</div>
      <UIAvatar className="ui-user-card-avatar" path={avatar} />
      <UIButton type={Button.LINK} link={link} className="ui-user-card-name">
        {firstName} {lastName}
      </UIButton>
    </div>
  );
};

export default UIUserCard;
