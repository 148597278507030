import { Route, Routes } from 'react-router-dom';

import { BookingDetail, BookingList } from '@modules/Booking';

const ViewBooking = () => {
  return (
    <Routes>
      <Route path=":bookingId" element={<BookingDetail />} />
      <Route path="/" element={<BookingList />} />
    </Routes>
  );
};

export default ViewBooking;
