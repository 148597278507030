import { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as EditIcon } from '@assets/svg/edit.svg';
import { i18next } from '@i18n';
import { LayoutContent } from '@layouts';
import { getOneBookingRequest, updateBookingRequest } from '@store/Booking';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, hideModal, showModal } from '@store/Modal';
import { UIButton, UIComment, UIHeader, UIOverview, UITag } from '@ui';
import {
  ActionStatus,
  Button,
  DateHelper,
  ProcessStatus,
  REFUND_DAY,
  Role,
  StringHelper,
  processAppreove,
  processStatusOptions,
} from '@utils';

const BookingDetail = () => {
  const { bookingId } = useParams<'bookingId'>();

  const dispatch = useAppDispatch();

  const { booking, status } = useSelector((state: RootState) => state.booking);

  useEffect(() => {
    if (bookingId) {
      dispatch(getOneBookingRequest(+bookingId));
    }
  }, [dispatch, bookingId]);

  useEffect(() => {
    if (bookingId && status === ActionStatus.UPDATED) {
      dispatch(getOneBookingRequest(+bookingId));
      dispatch(hideModal());
    }
  }, [bookingId, dispatch, status]);

  const users = () => {
    if (!booking) {
      return [];
    }

    const { angel, user } = booking;

    return [
      {
        id: angel.id,
        role: Role.ANGEL,
        firstName: angel.profile.firstName,
        lastName: angel.profile.lastName,
        avatar: angel.avatar?.path,
      },
      {
        id: user.id,
        role: Role.USER,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        avatar: user.avatar?.path,
      },
    ];
  };

  const infoList = () => [
    {
      name: i18next.t('Duration'),
      value: `${booking?.duration}m`,
    },
    {
      name: i18next.t('Payment info'),
      value: booking?.paymentInfo,
    },
    {
      name: i18next.t('Price'),
      value: StringHelper.toMoney(booking?.price),
    },
    {
      name: i18next.t('Discount'),
      value: StringHelper.toMoney(booking?.discount),
    },
    {
      name: i18next.t('Final price'),
      value: StringHelper.toMoney(booking?.finalPrice),
    },
    {
      name: i18next.t('Status'),
      value: UITag({
        value: booking?.status,
        values: processStatusOptions,
      }),
    },
    {
      name: i18next.t('Approve'),
      value: UITag({
        value: booking?.approve,
        values: processAppreove,
      }),
    },
  ];

  const editHandler = () => {
    if (bookingId) {
      dispatch(
        showModal({
          modalType: ModalType.BOOKING_EDIT,
          modalProps: { id: +bookingId, duration: booking?.duration },
        }),
      );
    }
  };

  const onCancelClickHandler = () => {
    if (bookingId) {
      dispatch(
        showModal({
          modalType: ModalType.CONFIRM,
          modalProps: {
            content: (
              <p>
                {i18next.t('Are you sure you want to cancel your booking?')}
              </p>
            ),
            action: () => {
              dispatch(
                updateBookingRequest({
                  id: +bookingId,
                  status: ProcessStatus.CANCELED,
                }),
              );
            },
          },
        }),
      );
    }
  };

  const onRefundClickHandler = () => {
    if (bookingId) {
      dispatch(
        showModal({
          modalType: ModalType.BOOKING_REFUND,
          modalProps: { id: +bookingId, ...booking },
        }),
      );
    }
  };

  const renderHeaderButton = () => {
    const endDateRefund = DateHelper.addDate(
      booking?.endDate,
      REFUND_DAY as number,
    );

    if (
      booking?.status === ProcessStatus.SUCCESS &&
      DateHelper.dateIsBefore(new Date(), booking?.startDate)
    ) {
      return (
        <Space>
          <UIButton
            type={Button.ANT_BUTTON}
            handler={onCancelClickHandler}
            typeAntBtn="default"
          >
            {i18next.t('Cancel a booking')}
          </UIButton>
        </Space>
      );
    }

    if (
      booking?.status === ProcessStatus.SUCCESS &&
      DateHelper.dateIsBefore(booking?.startDate, new Date()) &&
      DateHelper.dateIsBefore(new Date(), endDateRefund) &&
      booking?.finalPrice > 0
    ) {
      return (
        <Space>
          <UIButton type={Button.ANT_BUTTON} handler={onRefundClickHandler}>
            Refund
          </UIButton>
        </Space>
      );
    }

    return null;
  };

  const renderEditButton = () => {
    if (
      booking?.status === ProcessStatus.SUCCESS &&
      DateHelper.dateIsBefore(new Date(), booking?.startDate)
    ) {
      return (
        <UIButton
          type={Button.ANT_BUTTON}
          typeAntBtn="text"
          icon={<EditIcon />}
          handler={editHandler}
        >
          {i18next.t('Edit booking')}
        </UIButton>
      );
    }

    return null;
  };

  return (
    <>
      <UIHeader
        title={i18next.t('Booking')}
        isBack
        buttons={renderHeaderButton()}
      />
      <LayoutContent
        content={
          <UIComment messageAttribute={{ bookingId }} filter={{ bookingId }} />
        }
        aside={
          <UIOverview
            users={users()}
            date={booking?.startDate}
            infoList={infoList()}
            button={renderEditButton()}
          />
        }
      />
    </>
  );
};

export default BookingDetail;
