import { useEffect, useState } from 'react';

import { i18next } from '@i18n';
import { useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';
import { addTransactionRequest } from '@store/Transaction';
import { getSearchListUserSuccess } from '@store/User';
import { UIButton, UIModal } from '@ui';
import { Button } from '@utils';

import WidgetModalUIPeopleSelect from '../ui/PeopleSelect';
import { Props } from './index.type';

import './style.scss';

const WidgetModalCreateTransaction = ({ params }: Props) => {
  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [operation, setOperation] = useState<string>('income');
  const [amount, setAmount] = useState<number>(0);
  const [userSelectedError, setUserSelectedError] = useState<boolean>(false);

  const handleCancel = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (selectedId > 1) {
      setUserSelectedError(false);
    }
  }, [selectedId]);

  const addPsychologist = () => {
    if (selectedId < 1) {
      setUserSelectedError(true);

      return false;
    }
    dispatch(
      addTransactionRequest({
        amount,
        txnClass: 'internal',
        txnOperation: 'payment',
        txnStatus: 'success',
        txnType: operation,
        userId: selectedId,
      }),
    );
    handleCancel();

    return true;
  };

  useEffect(() => {
    return () => {
      dispatch(getSearchListUserSuccess({ data: [] }));
    };
  }, [dispatch]);

  const footer = [
    <UIButton
      key="Save"
      handler={addPsychologist}
      type={Button.ANT_BUTTON}
      disabled={true}
      size="large"
      className="edit-form__save-button"
    >
      {i18next.t('Create transaction')}
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      footer={footer}
      title={i18next.t('Create transaction for some user')}
      visible
    >
      <WidgetModalUIPeopleSelect
        userSelectedError={userSelectedError}
        setOperation={setOperation}
        setAmount={setAmount}
        onChange={setSelectedId}
        params={params}
      />
    </UIModal>
  );
};

export default WidgetModalCreateTransaction;
