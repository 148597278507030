import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useQuery } from '@hooks';
import { columns } from '@modules/Chat/constant';
import { getListChatRequest } from '@store/Chat';
import { RootState, useAppDispatch } from '@store/index';
import { UITable } from '@ui';

import { Props } from './index.type';

const UserDetailChat = ({ role }: Props) => {
  const dispatch = useAppDispatch();

  const { chatList, meta, loading } = useSelector(
    (state: RootState) => state.chat,
  );

  const { userId } = useParams<'userId'>();
  const { query, updateQuery } = useQuery({
    filter: {
      userId: userId ? +userId : null,
    },
  });

  const getRequest = useCallback(() => {
    dispatch(getListChatRequest(query));
  }, [dispatch, query]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  useEffect(() => {
    getRequest();
  }, [dispatch, getRequest]);

  return (
    <UITable
      dataSource={chatList}
      loading={loading}
      columns={columns(role)}
      meta={meta}
      onPaginationChange={onPaginationChange}
    />
  );
};

export default UserDetailChat;
