import { useState } from 'react';

import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { mainMenu } from '@data/menu';

const WidgetMenu = () => {
  const location = useLocation();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout.Sider
      width="255"
      breakpoint="xl"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Menu
        defaultSelectedKeys={['/']}
        selectedKeys={[
          location.pathname,
          location.pathname.split('/').slice(0, 2).join('/'),
        ]}
      >
        <Menu.Divider />
        {mainMenu[0]?.map(({ icon, title, link }) => (
          <Menu.Item icon={icon} key={link}>
            <Link to={link}>{title}</Link>
          </Menu.Item>
        ))}
        <Menu.Divider />
        {mainMenu[1]?.map(({ icon, title, link }) => (
          <Menu.Item icon={icon} key={link}>
            <Link to={link}>{title}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </Layout.Sider>
  );
};

export default WidgetMenu;
