import { Col, Row } from 'antd';

import { Props } from './index.type';

const UIOverviewInfo = (props: Props) => {
  const { name, value } = props;

  return (
    <Row className="overview-data" justify="space-between">
      <Col>{name}</Col>
      <Col className="overview-data-info">{value}</Col>
    </Row>
  );
};

export default UIOverviewInfo;
