import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullTherapy } from '@entities/Therapy';
import { TherapyHttp } from '@services/http';

import { getListTherapyFailure, getListTherapySuccess } from './Therapy.action';
import { GET_LIST_THERAPY_REQUEST } from './Therapy.constant';

function* workerGetListTherapy() {
  try {
    const res: DataResponse<FullTherapy[]> = yield call(TherapyHttp.getList);

    yield put(getListTherapySuccess(res));
  } catch (error) {
    yield put(getListTherapyFailure());
  }
}

function* watchGetListTherapy() {
  yield takeLatest(GET_LIST_THERAPY_REQUEST, workerGetListTherapy);
}

export const therapyWatchers: ForkEffect[] = [fork(watchGetListTherapy)];
