import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import { NavLink } from 'react-router-dom';

import { i18next } from '@i18n';
import { deleteCommissionRequest } from '@store/Commission';

import CommissionUIMenu from '../../Menu';
import { Props } from './index.type';

const CommissionUIActions = ({ id, link, dispatch }: Props) => {
  const onDeleteCommissionHandler = () => {
    dispatch(deleteCommissionRequest({ id }));
  };

  return (
    <Space size="small">
      <NavLink to={link}>
        <Button id="ant-button-cell-link" type="link">
          {i18next.t('Edit')}
        </Button>
      </NavLink>

      <Dropdown
        overlay={CommissionUIMenu({
          onDelete: onDeleteCommissionHandler,
        })}
        placement="bottomRight"
      >
        <MoreOutlined />
      </Dropdown>
    </Space>
  );
};

export default CommissionUIActions;
