import { Select } from 'antd';

import { LayoutInput } from '@layouts';
import { DateHelper, FORMAT_TIME, StringHelper, debounce } from '@utils';

import { OptionProps, Props } from './index.type';

const UISelect = (props: Props) => {
  const {
    options,
    setValue,
    searchHandler,
    isLoading,
    labelField,
    valueField,
    onSelectHandler,
    title,
    className,
    size,
    placeholder = 'Search...',
  } = props;

  const onSearchHandler = debounce((search: string) => {
    if (!searchHandler) {
      return;
    }
    searchHandler(search);
  });

  const renderOption = (val: OptionProps) => {
    const label = StringHelper.getValueFromObjectByKey(
      val,
      labelField,
    ) as string;
    const value = StringHelper.getValueFromObjectByKey(val, valueField);

    const time = DateHelper.formateDateToString(label, FORMAT_TIME);

    return (
      <Select.Option key={value} value={value}>
        {time}
      </Select.Option>
    );
  };

  const onChangeHandler = ({ value }: any) => {
    setValue(value);
  };

  return (
    <LayoutInput className={className} title={title}>
      <Select
        labelInValue={true}
        showSearch
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={onSearchHandler}
        onChange={onChangeHandler}
        notFoundContent={null}
        loading={isLoading}
        onSelect={onSelectHandler}
        size={size}
      >
        {options.map(renderOption)}
      </Select>
    </LayoutInput>
  );
};

export default UISelect;
