import { useEffect, useState } from 'react';

import { Form, Input } from 'antd';

import { i18next } from '@i18n';
import { Button } from '@utils';

import validation from '../../../i18n/validation';
import UIButton from '../../Button';
import { Props } from './index.type';

const UICommentCreate = (props: Props) => {
  const { onSubmitMessage } = props;
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = (data: { message: string }) => {
    onSubmitMessage(data);
    form.resetFields();
  };

  return (
    <Form
      form={form}
      name="horizontal_login"
      layout="inline"
      onFinish={onFinish}
      className="create-form"
      validateMessages={validation.messages}
    >
      <Form.Item
        name="message"
        rules={[{ required: true }]}
        className="create-form__input"
      >
        <Input size="large" placeholder={i18next.t('Leave a comment')} />
      </Form.Item>
      <Form.Item shouldUpdate className="create-form__submit">
        {() => (
          <UIButton
            type={Button.ANT_BUTTON}
            typeAntBtn="primary"
            prop={{
              htmlType: 'submit',
              disabled:
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length,
            }}
          >
            {i18next.t('Post')}
          </UIButton>
        )}
      </Form.Item>
    </Form>
  );
};

export default UICommentCreate;
