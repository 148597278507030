import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullSpecialty } from '@entities/Specialty';
import { SpecialtyHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';
import { getListUserRequest } from '@store/User';

import {
  addUserToCollectionFailure,
  addUserToCollectionSuccess,
  createCollectionFailure,
  createCollectionSuccess,
  deleteCollectionFailure,
  deleteCollectionSuccess,
  deleteUserFromCollectionFailure,
  deleteUserFromCollectionSuccess,
  getListCollectionFailure,
  getListCollectionRequest,
  getListCollectionSuccess,
  getOneCollectionFailure,
  getOneCollectionRequest,
  getOneCollectionSuccess,
  updateCollectionFailure,
  updateCollectionSuccess,
} from './Collection.action';
import {
  ADD_USER_TO_COLLECTION_REQUEST,
  CREATE_COLLECTION_REQUEST,
  DELETE_COLLECTION_REQUEST,
  DELETE_USER_FROM_COLLECTION_REQUEST,
  GET_LIST_COLLECTION_REQUEST,
  GET_ONE_COLLECTION_REQUEST,
  UPDATE_COLLECTION_REQUEST,
} from './Collection.constant';
import {
  IAddUserToCollectionRequest,
  ICreateCollectionRequest,
  IDeleteCollectionRequest,
  IDeleteUserFromCollectionRequest,
  IGetListCollectionRequest,
  IGetOneCollectionRequest,
  IUpdateCollectionRequest,
} from './Collection.type';

function* workerGetListCollection(action: IGetListCollectionRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullSpecialty[]> = yield call(
      SpecialtyHttp.getList,
      query,
    );

    yield put(getListCollectionSuccess(data));
  } catch (error) {
    yield put(getListCollectionFailure());
    yield put(alertError(error));
  }
}

function* workerCreateCollection(action: ICreateCollectionRequest) {
  try {
    const data = action.payload;

    yield call(SpecialtyHttp.create, data);

    yield put(createCollectionSuccess());
    yield put(alertSuccess('Collection added successfully!'));
    yield put(getListCollectionRequest());
    yield put(hideModal());
  } catch (error) {
    yield put(createCollectionFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteCollection(action: IDeleteCollectionRequest) {
  try {
    const { id } = action.payload;

    yield call(SpecialtyHttp.remove, id);

    yield put(deleteCollectionSuccess());
    yield put(alertSuccess('Collection successfully deleted!'));
    yield put(getListCollectionRequest());
  } catch (error) {
    yield put(deleteCollectionFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneCollection(action: IGetOneCollectionRequest) {
  try {
    const { id } = action.payload;
    const payload: FullSpecialty = yield call(SpecialtyHttp.getOne, id);

    yield put(getOneCollectionSuccess(payload));
  } catch (error) {
    yield put(getOneCollectionFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateCollection(action: IUpdateCollectionRequest) {
  try {
    const { id, ...body } = action.payload;

    yield call(SpecialtyHttp.update, id, body);

    yield put(updateCollectionSuccess());
    yield put(getListCollectionRequest());
    yield put(alertSuccess('Collection successfully update!'));
    yield put(getOneCollectionRequest(id));
    yield put(hideModal());
  } catch (error) {
    yield put(updateCollectionFailure());
    yield put(alertError(error));
  }
}

function* workerAddUserToCollection(action: IAddUserToCollectionRequest) {
  try {
    const { id, userId, query } = action.payload;

    yield call(SpecialtyHttp.addUserToSpecialty, { id, userId });

    yield put(addUserToCollectionSuccess());
    yield put(alertSuccess('Psychologist successfully added'));
    yield put(getListUserRequest(query));
  } catch (error) {
    yield put(addUserToCollectionFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteUserFromCollection(
  action: IDeleteUserFromCollectionRequest,
) {
  try {
    const { id, userId, query } = action.payload;

    yield call(SpecialtyHttp.deleteUserFromSpecialty, { id, userId });

    yield put(deleteUserFromCollectionSuccess());
    yield put(alertSuccess('Psychologist successfully deleted'));
    yield put(getListUserRequest(query));
  } catch (error) {
    yield put(deleteUserFromCollectionFailure());
    yield put(alertError(error));
  }
}

function* watchDeleteUserFromCollection() {
  yield takeLatest(
    DELETE_USER_FROM_COLLECTION_REQUEST,
    workerDeleteUserFromCollection,
  );
}

function* watchAddUserToCollection() {
  yield takeLatest(ADD_USER_TO_COLLECTION_REQUEST, workerAddUserToCollection);
}

function* watchUpdateCollection() {
  yield takeLatest(UPDATE_COLLECTION_REQUEST, workerUpdateCollection);
}

function* watchGetOneCollection() {
  yield takeLatest(GET_ONE_COLLECTION_REQUEST, workerGetOneCollection);
}

function* watchDeleteCollection() {
  yield takeLatest(DELETE_COLLECTION_REQUEST, workerDeleteCollection);
}

function* watchCreateCollection() {
  yield takeLatest(CREATE_COLLECTION_REQUEST, workerCreateCollection);
}

function* watchGetListCollection() {
  yield takeLatest(GET_LIST_COLLECTION_REQUEST, workerGetListCollection);
}

export const collectionWatchers: ForkEffect[] = [
  fork(watchGetListCollection),
  fork(watchCreateCollection),
  fork(watchDeleteCollection),
  fork(watchGetOneCollection),
  fork(watchUpdateCollection),
  fork(watchAddUserToCollection),
  fork(watchDeleteUserFromCollection),
];
