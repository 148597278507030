import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullChat, FullChatOne } from '@entities/Chat';
import { ChatHttp } from '@services/http';

import { alertError, alertSuccess } from '../Alert';
import {
  deleteChatFailure,
  deleteChatSuccess,
  getListChatFailure,
  getListChatRequest,
  getListChatSuccess,
  getOneChatFailure,
  getOneChatSuccess,
} from './Chat.action';
import {
  DELETE_CHAT_REQUEST,
  GET_LIST_CHAT_REQUEST,
  GET_ONE_CHAT_REQUEST,
} from './Chat.constant';
import {
  IDeleteChatRequest,
  IGetListChatRequest,
  IGetOneChatRequest,
} from './Chat.type';

function* workerGetListChat(action: IGetListChatRequest) {
  try {
    const { query } = action.payload;
    const payload: DataResponse<FullChat[]> = yield call(
      ChatHttp.getList,
      query,
    );

    yield put(getListChatSuccess(payload));
  } catch (error) {
    yield put(getListChatFailure());
    yield put(alertError(error));
  }
}

function* watchGetListChat() {
  yield takeLatest(GET_LIST_CHAT_REQUEST, workerGetListChat);
}

function* workerGetOneChat(action: IGetOneChatRequest) {
  try {
    const { id } = action.payload;
    const payload: FullChatOne = yield call(ChatHttp.getOne, id);

    yield put(getOneChatSuccess(payload));
  } catch (error) {
    yield put(getOneChatFailure());
    yield put(alertError(error));
  }
}

function* watchGetOneChat() {
  yield takeLatest(GET_ONE_CHAT_REQUEST, workerGetOneChat);
}

function* workerDeleteChat(action: IDeleteChatRequest) {
  try {
    const { id } = action.payload;

    yield call(ChatHttp.remove, id);

    yield put(deleteChatSuccess());
    yield put(alertSuccess('Chat successfully deleted!'));
    yield put(getListChatRequest());
  } catch (error) {
    yield put(deleteChatFailure());
    yield put(alertError(error));
  }
}

function* watchDeleteChat() {
  yield takeLatest(DELETE_CHAT_REQUEST, workerDeleteChat);
}

export const chatWatchers: ForkEffect[] = [
  fork(watchGetListChat),
  fork(watchGetOneChat),
  fork(watchDeleteChat),
];
