import { useCallback, useEffect } from 'react';

import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { getListCollectionRequest } from '@store/Collection';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIHeader, UITable } from '@ui';

import { columns, filterOptions, orderOption } from './constants';

const CollectionList = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { query, updateQuery } = useQuery();
  const { collectionList, loading, meta } = useSelector(
    (state: RootState) => state.collection,
  );

  const getRequest = useCallback(() => {
    dispatch(getListCollectionRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  const onCreateCollection = useCallback(() => {
    dispatch(
      showModal({
        modalType: ModalType.CREATE_COLLECTION,
        modalProps: { type: 'create' },
      }),
    );
  }, [dispatch]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const headerButtons = (
    <Button size="large" type="primary" onClick={onCreateCollection}>
      {i18next.t('Add collection')}
    </Button>
  );

  return (
    <>
      <UIHeader
        title={i18next.t('Collections')}
        isSearch={true}
        buttons={headerButtons}
        sortOption={orderOption}
        filterOptions={filterOptions}
        searchTitle={i18next.t('Filter collections')}
        viewContainerName={i18next.t('Collections')}
      />
      <Row>
        <UITable
          dataSource={collectionList}
          loading={loading}
          meta={meta}
          columns={columns({ path: pathname, dispatch })}
          onPaginationChange={onPaginationChange}
          isScrollDefault
        />
      </Row>
    </>
  );
};

export default CollectionList;
