import { DateHelper } from '@utils';

import { Props } from './index.type';

import './style.scss';

const ChatUiSeparator = ({ currentDate, prevDate, step }: Props) => {
  const currentDateFormat = DateHelper.getDateFormat(currentDate);
  const prevDateFormat = DateHelper.getDateFormat(prevDate);

  return step === 0 || currentDateFormat !== prevDateFormat ? (
    <div className="dayTitle">
      <span>{currentDateFormat}</span>
    </div>
  ) : null;
};

export default ChatUiSeparator;
