import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Button, Space, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActiveTab, useQuery } from '@hooks';
import { i18next } from '@i18n';
import { LayoutContent } from '@layouts';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { clearSession } from '@store/Statistic';
import {
  clearUserOneStore,
  getOneUserRequest,
  updateUserRequest,
} from '@store/User';
import { UIHeader } from '@ui';
import { LINK_CLIENT, LINK_EXPERT, Role } from '@utils';

import UserDetailBilling from './Billing';
import UserDetailBooking from './Booking';
import UserDetailCalendar from './Calendar';
// import UserDetailCategory from './Category';
import UserDetailChat from './Chat';
import UserDetailComment from './Comment';
import UserDetailFavorite from './Favorite';
import { Props } from './index.type';
import UserDetailInfo from './Info';
import UserDetailProfile from './Profile';
import UserDetailQualification from './Qualification';
import UserDetailReview from './Review';
import UserDetailSupport from './Support';
import UserDetailUserAction from './UserAction';

import './style.scss';

const UserDetail = ({ role }: Props) => {
  const { updateQuery } = useQuery();

  const [activeTab, setActiveTab] = useState<string>('');
  const dispatch = useAppDispatch();
  const { userId } = useParams<'userId'>();
  const { user, loadingUser } = useSelector((state: RootState) => state.user);

  const { searchTitle, orderOption, filterOptions } = useActiveTab({
    activeTab,
    role,
  });

  useLayoutEffect(() => {
    setActiveTab(LocalStorage.get('activeItem') as string);
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(getOneUserRequest(+userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    return () => {
      dispatch(clearUserOneStore());
      dispatch(clearSession());
    };
  }, [dispatch]);

  const onChangeStatus = () => {
    if (user) {
      dispatch(
        showModal({
          modalType: ModalType.CONFIRM,
          modalProps: {
            content: (
              <p>
                Are you sure you want to{' '}
                {!user.isActive ? 'active' : 'deactive'} the{' '}
                {role === Role.USER ? 'client' : 'psychologist'}
                <b> {user?.profile?.fullName || ''}</b>?
              </p>
            ),
            action: () =>
              dispatch(
                updateUserRequest({
                  id: user.id,
                  body: { isActive: !user.isActive },
                  query: '',
                }),
              ),
          },
        }),
      );
    }
  };
  let txtButton = !user?.isActive ? 'Active' : 'Deactivate';

  txtButton += ' the ';
  txtButton += user && role === Role.USER ? 'client' : 'psychologist';
  const aside = useMemo(
    () => [
      user && <UserDetailProfile user={user} role={role} />,
      user && role === Role.ANGEL && (
        <>
          <UserDetailInfo user={user} />
          <UserDetailQualification user={user} />
        </>
      ),
      user && role === Role.ANGEL && <UserDetailCalendar />,
      userId && <UserDetailComment userId={userId} />,
      user && role === Role.USER && userId && (
        <UserDetailFavorite userId={userId} />
      ),
      <Button
        type="primary"
        className="user-card__delete-button"
        danger
        onClick={onChangeStatus}
      >
        {i18next.t(txtButton)}
      </Button>,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, userId],
  );

  if (!userId) {
    return null;
  }

  const tabs = [
    { tab: i18next.t('Billing History'), Component: UserDetailBilling, role },
    { tab: i18next.t('Bookings'), Component: UserDetailBooking, role },
    { tab: i18next.t('Reviews'), Component: UserDetailReview, role },
    { tab: i18next.t('Chats'), Component: UserDetailChat, role },
  ];

  const content = [
    <UIHeader
      isBack
      backLink={`/${user && role === Role.USER ? LINK_CLIENT : LINK_EXPERT}`}
      isSearch
      searchDisabled={activeTab === i18next.t('Billing History')}
      searchTitle={searchTitle}
      sortOption={orderOption}
      filterOptions={filterOptions}
      title={user?.profile?.fullName || ''}
      viewContainerName={i18next.t('Clients')}
    />,
    <Tabs
      activeKey={activeTab}
      onTabClick={(e) => {
        updateQuery({
          filter: null,
          order: null,
          limit: undefined,
          page: undefined,
        });
        setActiveTab(e);
        LocalStorage.set('activeItem', e);
      }}
    >
      {tabs.map(({ tab, Component, ...rest }) => {
        return (
          <Tabs.TabPane tab={tab} key={tab}>
            {tab === activeTab && <Component {...rest} />}
          </Tabs.TabPane>
        );
      })}
    </Tabs>,
    <UserDetailSupport />,
    <UserDetailUserAction userId={userId} />,
  ];

  return (
    <Space direction="vertical">
      <LayoutContent
        content={content}
        aside={aside}
        loadingAside={loadingUser}
        loadingContent={loadingUser}
      />
    </Space>
  );
};

export default React.memo(UserDetail);
