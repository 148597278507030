import { Refund } from '@entities/Payment';

import {
  REFUND_FAILURE,
  REFUND_REQUEST,
  REFUND_SUCCESS,
} from './Payment.constant';
import { PaymentActionsTypes } from './Payment.type';

export function refundRequest(payload: Refund): PaymentActionsTypes {
  return {
    type: REFUND_REQUEST,
    payload,
  };
}

export function refundSuccess(): PaymentActionsTypes {
  return {
    type: REFUND_SUCCESS,
  };
}

export function refundFailure(): PaymentActionsTypes {
  return {
    type: REFUND_FAILURE,
  };
}
