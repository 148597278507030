import { Spin, Table } from 'antd';

import { Props } from './index.type';

import './style.scss';

const UITable = <T extends Id>({
  dataSource,
  columns,
  loading,
  meta,
  onPaginationChange,
  scroll,
  isScrollDefault,
}: Props<T>) => {
  const isPagination = meta && dataSource.length > 0;

  return (
    <div className="ui-table">
      <Spin size="large" spinning={loading}>
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          pagination={
            (isPagination && {
              defaultPageSize: meta.limit,
              current: meta.currentPage,
              total: meta.totalItems,
              onChange: onPaginationChange,
            }) ||
            false
          }
          scroll={isScrollDefault ? { x: 1500 } : scroll}
        />
      </Spin>
    </div>
  );
};

export default UITable;
