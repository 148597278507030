import { useCallback, useEffect } from 'react';

import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { getListCouponRequest } from '@store/Coupon';
import { RootState, useAppDispatch } from '@store/index';
import { UIHeader, UITable } from '@ui';
import { LINK_COUPON_ADD } from '@utils';

import { columns, filterOptions, orderOption } from './constants';

const CouponList = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { query, updateQuery } = useQuery();
  const { couponList, loading, meta } = useSelector(
    (state: RootState) => state.coupon,
  );

  const getRequest = useCallback(() => {
    dispatch(getListCouponRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const onButtonClick = () => {
    navigate(`${pathname}/${LINK_COUPON_ADD}`);
  };

  const headerButtons = (
    <Button size="large" type="primary" onClick={onButtonClick}>
      {i18next.t('Add coupon')}
    </Button>
  );

  return (
    <>
      <UIHeader
        title={i18next.t('Coupons')}
        isSearch={true}
        buttons={headerButtons}
        sortOption={orderOption}
        filterOptions={filterOptions}
        searchTitle={i18next.t('Filter coupons')}
        viewContainerName={i18next.t('Coupons')}
      />
      <Row>
        <UITable
          dataSource={couponList}
          loading={loading}
          meta={meta}
          columns={columns({ path: pathname, dispatch })}
          onPaginationChange={onPaginationChange}
          isScrollDefault
        />
      </Row>
    </>
  );
};

export default CouponList;
