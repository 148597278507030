import { useCallback, useEffect } from 'react';

import { List, Row } from 'antd';
import { useSelector } from 'react-redux';

import { Comment } from '@entities/Comment';
import { LayoutContainerWhite } from '@layouts';
import { createCommentRequest, getListCommentRequest } from '@store/Comment';
import { RootState, useAppDispatch } from '@store/index';
import { LIMIT_DEFAULT, PAGE_DEFAULT, QueryHelper } from '@utils';

import UICommentCreate from './Create';
import { Props } from './index.type';
import UICommentItem from './Item';

import './style.scss';

const UIComment = ({
  title,
  filter,
  messageAttribute,
  limit = LIMIT_DEFAULT,
  paginationSize,
}: Props) => {
  const dispatch = useAppDispatch();

  const { commentList, loading, meta } = useSelector(
    (state: RootState) => state.comment,
  );

  const query = useCallback(
    (page?: number, pLimit?: number) =>
      QueryHelper.stringify({
        limit: pLimit || limit,
        page: page || PAGE_DEFAULT,
        filter,
        order: {
          created_at: 'desc',
        },
      }),
    [limit, filter],
  );

  const onPaginationChange = (page: number, limitPage?: number) => {
    dispatch(getListCommentRequest(query(page, limitPage)));
  };

  const onSubmitMessage = ({ message }: Pick<Comment, 'message'>) => {
    dispatch(createCommentRequest({ message, ...messageAttribute }, query()));
  };

  useEffect(() => {
    dispatch(getListCommentRequest(query()));
  }, [dispatch, query]);

  return (
    <Row className="comments-container">
      <LayoutContainerWhite span={24} title={title}>
        <UICommentCreate onSubmitMessage={onSubmitMessage} />
        <List
          itemLayout="vertical"
          loading={loading}
          pagination={
            (meta.totalItems > meta.limit && {
              size: paginationSize,
              pageSize: meta.limit,
              defaultPageSize: meta.limit,
              current: meta.currentPage,
              total: meta.totalItems,
              pageSizeOptions: [`${limit}`, '10', '20', '50'],
              onChange: onPaginationChange,
            }) ||
            false
          }
          split={false}
          dataSource={commentList}
          renderItem={(item) => (
            <UICommentItem {...item} query={query()} key={item.id} />
          )}
        />
      </LayoutContainerWhite>
    </Row>
  );
};

export default UIComment;
