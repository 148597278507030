import { Button, Menu } from 'antd';

import { i18next } from '@i18n';

import { Props } from './index.type';

const CommissionUIMenu = ({ onDelete }: Props) => {
  return (
    <Menu>
      <Menu.Item key={`DeleteCommissionMenu`} onClick={onDelete}>
        <Button type="text" danger={true}>
          {i18next.t('Delete')}
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default CommissionUIMenu;
