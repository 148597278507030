import { Comment, FullComment } from '@entities/Comment';
import { http } from '@utils';

const basePath = '/api/comments';

export const getList = async (
  query?: string,
): Promise<DataResponse<FullComment[]>> => {
  const { data } = await http.get<DataResponse<FullComment[]>>(
    `${basePath}${query || ''}`,
  );

  return data;
};

export const create = async (body: Comment): Promise<void> => {
  await http.post(basePath, body);
};

export const update = async ({
  id,
  message,
}: Pick<FullComment, 'id' | 'message'>): Promise<void> => {
  await http.put(`${basePath}/${id}`, { message });
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};
