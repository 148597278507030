import { Fragment, useMemo } from 'react';

import Title from 'antd/lib/typography/Title';

import { i18next } from '@i18n';
import { LayoutContainerDivider } from '@layouts';
import { UIDetailRow } from '@ui';

import { Props } from './index.type';

const UserDetailQualification = (props: Props) => {
  const {
    user: { qualifications },
  } = props;

  const renderItems = useMemo(
    () =>
      qualifications &&
      qualifications.map(({ code, name }) => (
        <UIDetailRow title={code} children={name} key={`qua_${name}`} />
      )),
    [qualifications],
  );

  const children = [
    <Fragment key={1}>
      <Title level={5} className="user-qualification__title">
        {i18next.t('Qualifications')}
      </Title>
      {renderItems}
    </Fragment>,
  ];

  return <LayoutContainerDivider children={children} />;
};

export default UserDetailQualification;
