import TextArea from 'antd/lib/input/TextArea';

import { Props } from './index.type';

const UITextArea = ({ onChangeHandler, value, rows }: Props) => {
  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChangeHandler(e.currentTarget.value);
  };

  return <TextArea onChange={onChange} value={value} rows={rows} />;
};

export default UITextArea;
