import { FullTimezone } from '@entities/Timezone';
import { http } from '@utils';

export const getListTimezone = async (): Promise<FullTimezone[]> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullTimezone[]>>('/api/timezones');

  return data;
};
