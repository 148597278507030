import { FullTherapy } from '@entities/Therapy';

import {
  GET_LIST_THERAPY_FAILURE,
  GET_LIST_THERAPY_REQUEST,
  GET_LIST_THERAPY_SUCCESS,
} from './Therapy.constant';
import { TherapyActionsTypes } from './Therapy.type';

export function getListTherapyRequest(): TherapyActionsTypes {
  return {
    type: GET_LIST_THERAPY_REQUEST,
  };
}

export function getListTherapySuccess(
  payload: DataResponse<FullTherapy[]>,
): TherapyActionsTypes {
  return {
    type: GET_LIST_THERAPY_SUCCESS,
    payload,
  };
}

export function getListTherapyFailure(): TherapyActionsTypes {
  return {
    type: GET_LIST_THERAPY_FAILURE,
  };
}
