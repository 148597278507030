import { ColumnType, ColumnsType } from 'antd/lib/table';

import { FullBooking } from '@entities/Booking';
import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import { FullUser } from '@entities/User';
import { i18next } from '@i18n';
import type { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink, UITag } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_TAG,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  FilterTab,
  LINK_BOOKING,
  LINK_CLIENT,
  LINK_EXPERT,
  ProcessStatus,
  Role,
  StringHelper,
  processStatusOptions,
} from '@utils';

import { BookingUIActions } from './ui';

const today = new Date();

export const tab: FilterTab[] = [
  { label: i18next.t('All'), options: { filter: null } },
  {
    label: i18next.t('Today'),
    options: {
      filter: {
        startDate: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    },
  },
  {
    label: i18next.t('Tomorrow'),
    options: {
      filter: {
        startDate: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: 1,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: 1,
          }),
        },
      },
    },
  },
];

const clientColumn: ColumnType<FullBooking> = {
  title: i18next.t('Client'),
  dataIndex: 'user',
  key: 'user',
  width: COLUMN_WIDTH_NAME,
  render: (value: FullUser) =>
    UICellFullName({
      lastName: value?.profile?.lastName,
      firstName: value?.profile?.firstName,
      src: value?.avatar?.path,
      link: `/${LINK_CLIENT}/${value.id}`,
    }),
};

const angelColumn: ColumnType<FullBooking> = {
  title: i18next.t('Expert'),
  dataIndex: 'angel',
  key: 'angel',
  width: COLUMN_WIDTH_NAME,
  render: (value: FullUser) =>
    UICellFullName({
      lastName: value?.profile?.lastName,
      firstName: value?.profile?.firstName,
      src: value?.avatar?.path,
      link: `/${LINK_EXPERT}/${value.id}`,
    }),
};

const renderColumn = (role?: Role.ANGEL | Role.USER) => {
  if (role === Role.ANGEL) {
    return [clientColumn];
  }

  if (role === Role.USER) {
    return [angelColumn];
  }

  return [angelColumn, clientColumn];
};

const renderActionColumn = (
  dispatch?: AppDispatch,
): ColumnsType<FullBooking> => {
  if (dispatch) {
    return [
      {
        title: i18next.t('Actions'),
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: COLUMN_WIDTH_ACTIONS,
        render: (_value: FullBooking, booking: FullBooking) =>
          BookingUIActions({ ...booking, dispatch }),
      },
    ];
  }

  return [];
};

export const columns = ({
  dispatch,
  role,
}: {
  dispatch?: AppDispatch;
  role?: Role.ANGEL | Role.USER;
}): ColumnsType<FullBooking> => [
  {
    title: i18next.t('Id'),
    key: 'number',
    render: (value: FullBooking) =>
      UICellLink({
        link: `/${LINK_BOOKING}/${value.id}`,
        value: value.id,
      }),
    width: COLUMN_WIDTH_ID,
  },

  ...renderColumn(role),
  {
    title: i18next.t('Date'),
    dataIndex: 'startDate',
    key: 'startDate',
    width: COLUMN_WIDTH_FULL_DATE,
    render: (value: string) =>
      DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
  },
  {
    title: i18next.t('Duration'),
    dataIndex: 'duration',
    key: 'duration',
    width: COLUMN_WIDTH_NUMBER,
  },
  {
    title: i18next.t('Status'),
    dataIndex: 'status',
    key: 'status',
    width: COLUMN_WIDTH_TAG,
    render: (value) =>
      UITag({
        value,
        values: processStatusOptions,
      }),
  },
  {
    title: i18next.t('Price'),
    dataIndex: 'price',
    key: 'price',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value),
  },
  {
    title: i18next.t('Discount'),
    dataIndex: 'discount',
    key: 'discount',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value),
  },
  {
    title: i18next.t('Final price'),
    dataIndex: 'finalPrice',
    key: 'finalPrice',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value),
  },
  ...renderActionColumn(dispatch),
];

const clientSort: SortOption[] = [
  {
    label: i18next.t('Client name (A-Z)'),
    name: 'user_full_name',
    direction: 'asc',
  },
  {
    label: i18next.t('Client name (Z-A)'),
    name: 'user_full_name',
    direction: 'desc',
  },
];

const angelSort: SortOption[] = [
  {
    label: i18next.t('Expert name (A-Z)'),
    name: 'angel_full_name',
    direction: 'asc',
  },
  {
    label: i18next.t('Expert name (Z-A)'),
    name: 'angel_full_name',
    direction: 'desc',
  },
];

const renderSort = (role?: Role) => {
  if (role === Role.ANGEL) {
    return clientSort;
  }

  if (role === Role.USER) {
    return angelSort;
  }

  return [...clientSort, ...angelSort];
};

export const sortOption = ({ role }: { role?: Role }): SortOption[] => [
  {
    label: i18next.t('Id (ascending)'),
    name: 'id',
    direction: 'asc',
  },
  {
    label: i18next.t('Id (descending)'),
    name: 'id',
    direction: 'desc',
  },
  ...renderSort(role),
  {
    label: i18next.t('Start date (oldest first)'),
    name: 'start_date',
    direction: 'asc',
  },
  {
    label: i18next.t('Start date (newest first)'),
    name: 'start_date',
    direction: 'desc',
  },
  {
    label: i18next.t('Duration (shortest first)'),
    name: 'duration',
    direction: 'asc',
  },
  {
    label: i18next.t('Duration (longer first)'),
    name: 'duration',
    direction: 'desc',
  },
  {
    label: i18next.t('Final price (lowest first)'),
    name: 'final_price',
    direction: 'asc',
  },
  {
    label: i18next.t('Final price (highest first)'),
    name: 'final_price',
    direction: 'desc',
  },
  {
    label: i18next.t('Price (lowest first)'),
    name: 'price',
    direction: 'asc',
  },
  {
    label: i18next.t('Price (highest first)'),
    name: 'price',
    direction: 'desc',
  },
  {
    label: i18next.t('Discount (lowest first)'),
    name: 'discount',
    direction: 'asc',
  },
  {
    label: i18next.t('Discount (highest first)'),
    name: 'discount',
    direction: 'desc',
  },
];

export const filterOptions: FilterOption[] = [
  {
    name: 'duration',
    label: i18next.t('Duration'),
    type: 'checkbox',
    options: [
      {
        label: '30m',
        value: '30',
      },
      {
        label: '60m',
        value: '60',
      },
      {
        label: '90m',
        value: '90',
      },
    ],
  },
  {
    name: 'status',
    label: i18next.t('Status'),
    type: 'checkbox',
    options: [
      {
        label: i18next.t(StringHelper.capitalize(ProcessStatus.SUCCESS)),
        value: ProcessStatus.SUCCESS,
      },
      {
        label: i18next.t(StringHelper.capitalize(ProcessStatus.PENDING)),
        value: ProcessStatus.PENDING,
      },
      {
        label: i18next.t(StringHelper.capitalize(ProcessStatus.HOLD)),
        value: ProcessStatus.HOLD,
      },
      {
        label: i18next.t(StringHelper.capitalize(ProcessStatus.FAILED)),
        value: ProcessStatus.FAILED,
      },
      {
        label: i18next.t(StringHelper.capitalize(ProcessStatus.REFUND)),
        value: ProcessStatus.REFUND,
      },
      {
        label: i18next.t(StringHelper.capitalize(ProcessStatus.CANCELED)),
        value: ProcessStatus.CANCELED,
      },
    ],
  },
  {
    name: 'price',
    label: i18next.t('Amount paid'),
    type: 'number-range',
    step: 1,
    min: 0,
  },
];
