import { FullMedia } from '@entities/Media';
import { FileName, UploadFile, http } from '@utils';

const basePath = '/api/media';

export const uploadFile = async (
  fileName: FileName,
  file: UploadFile['file'],
): Promise<FullMedia> => {
  const formData = new FormData();

  formData.append(fileName, file);

  const { data } = await http.post<DataResponse<FullMedia>>(
    basePath,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return data.data;
};
