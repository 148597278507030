import { useCallback, useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import { SelectValue } from '@entities/index';
// import { en } from '@i18n';
import { i18next } from '@i18n';
import { DateHelper, FORMAT_DATE, LINK_COUPON } from '@utils';

import validation from '../../../../i18n/validation';
import { INITIAL_VALUES } from './constant';
import CouponDetailCreation from './Creation';
import CouponDetailExpert from './Expert';
import { FormCoupon, Props } from './index.type';
import CouponDetailLimitAmount from './LimitAmount';
import CouponDetailLimitedCustomer from './LimitedCustomer';
import CouponDetailPeriod from './Period';
import CouponDetailSpecific from './Specific';
import CouponDetailUsageLimits from './UsageLimits';

const CouponDetailFrom = ({ coupon, onFormSubmit }: Props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormCoupon>();
  const [usersForm, setUsersForm] = useState<SelectValue[]>([]);

  const handlerFinish = useCallback(
    (values: FormCoupon) => {
      const { code, ...data } = values;
      const startDate = data.startDate
        ? DateHelper.formateDateToString(data.startDate, FORMAT_DATE)
        : null;

      const endDate = data.endDate
        ? DateHelper.formateDateToString(data.endDate, FORMAT_DATE)
        : null;

      onFormSubmit({
        ...data,
        ...(code && { code }),
        users: usersForm.map((item) => ({ id: item.value as number })),
        startDate,
        endDate,
      });
    },
    [usersForm, onFormSubmit],
  );

  useEffect(() => {
    if (coupon) {
      const { users, ...data } = coupon;

      form.setFieldsValue({
        ...data,
        ...(data?.startDate && {
          startDate: DateHelper.stringToDayjs(data.startDate),
        }),
        ...(data?.endDate && {
          endDate: DateHelper.stringToDayjs(data.endDate),
        }),
      });
    }
  }, [form, coupon]);

  useEffect(() => {
    if (coupon?.users) {
      setUsersForm(
        coupon.users.map((user) => ({
          label: user?.profile?.fullName,
          value: user.id,
          key: `search_user_${user.id}`,
        })),
      );
    }
  }, [coupon?.users]);

  const onCalncel = () => {
    navigate(`/${LINK_COUPON}`);
  };

  return (
    <Form
      form={form}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      className="modal-create discount-box"
      validateMessages={validation.messages}
    >
      <CouponDetailCreation />
      <CouponDetailSpecific form={form} />
      <CouponDetailUsageLimits form={form} />
      <CouponDetailPeriod form={form} />
      <CouponDetailLimitAmount form={form} />
      <CouponDetailLimitedCustomer
        form={form}
        users={usersForm}
        setUsers={setUsersForm}
      />
      <CouponDetailExpert />
      <Row gutter={[16, 16]}>
        <Col>
          <Button type="primary" size="large" htmlType="submit">
            {coupon?.code
              ? i18next.t('Save coupon')
              : i18next.t('Create coupon')}
          </Button>
        </Col>
        {!coupon?.code && (
          <Col>
            <Button size="large" onClick={onCalncel}>
              {i18next.t('Cancel')}
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default CouponDetailFrom;
