export const API_URL = process.env.REACT_APP_API_URL;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const REFUND_DAY = process.env.REACT_APP_REFUND_DAY || 2;
export const URL_COLLECTION = process.env.REACT_APP_URL_COLLECTION;

export const COLUMN_WIDTH_DATE = 120;
export const COLUMN_WIDTH_FULL_DATE = 180;
export const COLUMN_WIDTH_ID = 70;
export const COLUMN_WIDTH_LONG_TEXT = 400;
export const COLUMN_WIDTH_NAME = 200;
export const COLUMN_WIDTH_NUMBER = 140;
export const COLUMN_WIDTH_TAG = 120;
export const COLUMN_WIDTH_ACTIONS = 100;

export const COOKIE_ACCESS_TOKEN = 'accessToken';
export const COOKIE_REFRESH_TOKEN = 'refreshToken';

export const ENV_DEVELOPMENT = 'development';
export const ENV_PRODUCTION = 'production';
export const IS_DEV = process.env.NODE_ENV === ENV_DEVELOPMENT;

export const FORMAT_DATE = 'YYYY-MM-DD';
export const FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm';
export const FORMAT_DATE_TIME_WITH_DOT = 'DD.MM.YY, HH:mm';
export const FORMAT_DATE_WITH_MONTH = 'D MMM';
export const FORMAT_DAY = 'D';
export const FORMAT_DAY_OF_WEEK = 'ddd';
export const FORMAT_SHORT_DATE = 'DD.MM.YY';
export const FORMAT_TIME = 'HH:mm';
export const FORMAT_TIME_WITH_DAY_PHASE = 'HH:mm A';

export const HOURS_IN_DAY = 24;
export const LIMIT_DEFAULT = 10;

export const META_DEFAULT = {
  limit: LIMIT_DEFAULT,
  currentPage: 1,
  nextPage: 1,
  prevPage: 1,
  hasNextPage: false,
  hasPrevPage: false,
  totalPages: 1,
  totalItems: 0,
};

export const PAGE_DEFAULT = 1;
export const VALIDATE_MIN_BIRTHDAY = '1900-01-01';
export const VALIDATE_MAX_USERNAME = 20;
export const VALIDATE_MAX_PHONE = 15;
export const VALIDATE_MAX_SEO_TITLE = 70;
export const VALIDATE_MAX_SEO_DESCRIPTION = 340;

export const PIE_COLORS = ['#74BCFF', '#1890FF', '#0659A7'];
