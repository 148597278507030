import { useMemo } from 'react';

import { Empty, Select, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { SelectValue } from '@entities/index';
import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import {
  getSearchListUserRequest,
  getSearchListUserSuccess,
} from '@store/User';
import { QueryHelper, Role } from '@utils';

import { Props } from './index.type';

import './style.scss';

const { Option } = Select;

const UIUserSearch = ({
  placeholder = 'Select users',
  disabled,
  value,
  setValue,
}: Props) => {
  const dispatch = useAppDispatch();
  const { searchUserList, loadingSearch } = useSelector(
    (state: RootState) => state.user,
  );

  const handleSearch = (values: string | null) => {
    if (values && values.length > 2) {
      dispatch(
        getSearchListUserRequest(
          QueryHelper.stringify({
            filter: {
              search: values,
              role: Role.USER,
            },
          }),
        ),
      );
    }
    if (values && values.length === 0) {
      dispatch(getSearchListUserSuccess({ data: [] }));
    }
  };

  const handleChange = (item: SelectValue[]) => {
    setValue(item);
  };

  const renderOption = useMemo(
    () =>
      searchUserList.map((user) => (
        <Option key={`search_user_${user.id}`} value={user.id}>
          {user?.profile?.fullName || ''}
        </Option>
      )),
    [searchUserList],
  );

  return (
    <Select
      mode="multiple"
      showSearch
      labelInValue
      value={value}
      placeholder={i18next.t(placeholder)}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      disabled={disabled}
      loading={loadingSearch}
      notFoundContent={
        loadingSearch ? (
          <div className="select-loading">
            <Spin />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    >
      {renderOption}
    </Select>
  );
};

export default UIUserSearch;
