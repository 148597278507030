type Money = {
  number: number;
  value: number;
};

export const toCents = (val: number) => Math.round(val * 100);

export const toNum = (val: number | null) => (val ? Math.round(val) / 100 : 0);

export const toPercent = (val: number) =>
  Math.round(val >= 100 ? 100 : val) / 100;

export const toInt = (a: string | number) => {
  const x = Number(a);

  return x < 0 ? Math.ceil(x) : Math.floor(x);
};

// const isSafe = (n: number | null) =>
//   typeof n === 'number' &&
//   Math.round(n) === n &&
//   Number.MIN_SAFE_INTEGER <= n &&
//   n <= Number.MAX_SAFE_INTEGER;

const money = (number: number | null, value?: number): Money => {
  const res = number
    ? { number, value: toCents(number) }
    : { number: toNum(value || 0), value: value || 0 };

  // if (!isSafe(res.value)) throw new Error('Number exced integer SAFE range');

  return res;
};

export const add = (a: number, b: number) =>
  money(null, money(a).value + money(b).value).number;

export const sub = (a: number, b: number) =>
  money(null, money(a).value - money(b).value).number;

export const multiply = (a: number, b: number) =>
  money(null, money(a).value * money(b).value).number;

export const divide = (a: number, b: number) =>
  money(null, money(a).value / money(b).value).number;

export const percentSub = (a: number, prc: number) =>
  money(null, money(a).value * money(null, prc).number).number;
