import { parse } from 'cookie';

import {
  APP_DOMAIN,
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
  decode,
} from '@utils';

export default (() => {
  const parseToken = (cookie?: string): TokenPayload | null => {
    if (typeof window !== 'undefined' || cookie) {
      const cookies = parse(
        typeof window !== 'undefined' ? document?.cookie || '' : cookie || '',
      );

      const payload = decode<TokenPayload>(
        (cookies && cookies[COOKIE_ACCESS_TOKEN]) || '',
      );

      return payload;
    }

    return null;
  };

  function setCookie(
    name: string,
    value: string,
    options: { domain?: string; expires?: Date | string; path?: string } = {},
  ) {
    if (typeof document === 'undefined') return;

    let cookieString = `${name}=${value};`;

    if (options.expires) {
      const expires =
        typeof options.expires === 'string'
          ? options.expires
          : options.expires.toUTCString();

      cookieString += `expires=${expires};`;
    }

    if (options.path) {
      cookieString += `path=${options.path};`;
    }

    if (options.domain && document.domain !== 'localhost') {
      cookieString += `domain=${options.domain};`;
    }

    document.cookie = cookieString;
  }

  function setCookieLocaleKey(value: string) {
    const oneYearFromNow = new Date();

    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    setCookie('localeKey', value, {
      expires: oneYearFromNow,
      path: '/',
      domain: APP_DOMAIN,
    });
  }

  function getCookieLocaleKey() {
    if (typeof document === 'undefined') return null;

    const cookies = parse(document.cookie);

    return cookies.localeKey || null;
  }

  function deleteCookie(name: string) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  function deleteTokens() {
    deleteCookie(COOKIE_ACCESS_TOKEN);
    deleteCookie(COOKIE_REFRESH_TOKEN);
  }

  return { parseToken, deleteTokens, setCookieLocaleKey, getCookieLocaleKey };
})();
