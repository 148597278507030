export const isValidSize = (maxSize: number, length: number): boolean => {
  return maxSize >= length;
};

export const trimString = (maxSize: number, str: string): string => {
  const { length } = str;
  let trimStr = '';

  if (maxSize < length) {
    for (let i = 0; i <= maxSize; i++) {
      trimStr += str[i];
    }

    return trimStr;
  }

  return str;
};
