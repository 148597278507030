import { Tag } from 'antd';

import { Props } from './index.type';

const UICellStatus = (props: Props) => {
  const { value, text } = props;

  return (
    <Tag color={value ? 'green' : 'magenta'} key={text}>
      {text}
    </Tag>
  );
};

export default UICellStatus;
