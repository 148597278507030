import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LocalStorage } from '@services/storage';
import { alertError } from '@store/Alert';
import { View } from '@utils';

import validation from '../../i18n/validation';
import useQuery from '../useQuery';
import { Hook } from './index.type';

export const useView: Hook = ({ viewContainerName }) => {
  const dispatch = useDispatch();
  const { query } = useQuery();
  const navigate = useNavigate();
  const [views, setViews] = useState<View[]>([]);

  const onSetViews = useCallback(() => {
    if (viewContainerName) {
      setViews(LocalStorage.get<View[]>(viewContainerName) || []);
    }
  }, [viewContainerName]);

  useEffect(() => {
    onSetViews();
  }, [onSetViews]);

  if (!viewContainerName) {
    return null;
  }

  const onSaveView = (name: string) => {
    const element = views.find((el) => el.name === name);

    if (!name.trim()) {
      return;
    }

    if (element) {
      dispatch(alertError(validation.error.exist));

      return;
    }

    const newViews = [...views, { name, query }];

    LocalStorage.set<View[]>(viewContainerName, newViews);
    setViews(newViews);
  };

  const onRemoveView = (name: string) => {
    const newViews = views.filter((view) => view.name !== name);

    LocalStorage.set<View[]>(viewContainerName, newViews);
    setViews(newViews);
  };

  const onApplyView = (name: string) => {
    const currentView = views.find((view) => view.name === name);

    navigate({
      search: currentView?.query || '',
    });
  };

  return {
    views,
    onSaveView,
    onRemoveView,
    onApplyView,
  };
};
