import { ActionStatus, META_DEFAULT } from '@utils';

import {
  DELETE_CHAT_FAILURE,
  DELETE_CHAT_REQUEST,
  DELETE_CHAT_SUCCESS,
  GET_LIST_CHAT_FAILURE,
  GET_LIST_CHAT_REQUEST,
  GET_LIST_CHAT_SUCCESS,
  GET_ONE_CHAT_FAILURE,
  GET_ONE_CHAT_REQUEST,
  GET_ONE_CHAT_SUCCESS,
} from './Chat.constant';
import { ChatActionsTypes, IChatState } from './Chat.type';

const initialState: IChatState = {
  status: ActionStatus.NONE,
  chatList: [],
  messageList: [],
  chat: null,
  meta: META_DEFAULT,
  loaded: false,
  loading: false,
};

export default function chatReducer(
  state = initialState,
  action: ChatActionsTypes,
): IChatState {
  switch (action.type) {
    case DELETE_CHAT_REQUEST:
    case GET_LIST_CHAT_REQUEST:
    case GET_ONE_CHAT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_CHAT_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.LOADED,
        chatList: action.payload.data,
        meta: action.payload.meta,
        loaded: true,
        loading: false,
      };
    }
    case GET_ONE_CHAT_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.LOADED,
        messageList: action.payload.messages,
        chat: action.payload,
        loading: false,
      };
    }
    case DELETE_CHAT_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        messageList: [],
        chat: null,
        loading: false,
      };
    }
    case DELETE_CHAT_FAILURE:
    case GET_ONE_CHAT_FAILURE:
    case GET_LIST_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
