import { Tooltip } from 'antd';

import { Props } from './index.type';

const UICellTooltip = ({ text }: Props) => {
  return (
    <Tooltip placement="topLeft" title={text}>
      {text}
    </Tooltip>
  );
};

export default UICellTooltip;
