import { Row, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { i18next } from '@i18n';
import { DateHelper } from '@utils';

import { Props } from './index.type';

const UserDetailCalendarHeader = ({ value, onChange }: Props) => {
  const renderMonth = () => {
    const options = [];

    for (let i = 0; i < 12; i++) {
      options.push(
        <Select.Option key={i} value={i}>
          <Text strong>{DateHelper.getMonthFromNumber(i)}</Text>
        </Select.Option>,
      );
    }

    return options;
  };

  const onMonthChange = (month: number) => {
    onChange(DateHelper.setNumberOfMonth(value, month));
  };

  return (
    <Row
      gutter={8}
      justify="space-between"
      align="middle"
      className="availability-calendar__header"
    >
      <Title level={5}>{i18next.t('Availability')}</Title>
      <Select
        defaultValue={DateHelper.getNumberOfMonth(value)}
        bordered={false}
        onChange={onMonthChange}
      >
        {renderMonth()}
      </Select>
    </Row>
  );
};

export default UserDetailCalendarHeader;
