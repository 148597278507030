import { FullFilter } from '@entities/Filter';
import { FullSort } from '@entities/Sort';

export enum ActionStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  LOADED = 'LOADED',
  NONE = 'NONE',
  UPDATED = 'UPDATED',
}

export enum ActiveStatus {
  NO = 'no',
  YES = 'yes',
}

export enum ApproveStatus {
  NO = 'no',
  YES = 'yes',
}

export enum Button {
  ANT_BUTTON = 'ant_button',
  BUTTON = 'button',
  LINK = 'link',
  SUBMIT = 'submit',
}

export type ButtonProps = {
  name: string;
  onClick?: () => void;
  type?: 'primary' | 'link' | 'text' | 'ghost' | 'default' | 'dashed';
};

export type EventValue<DateType> = DateType | null;

export type FilterTab = {
  label: string;
  options: QueryOptions;
};

export type FullQuery = {
  filter?: FullFilter | null;
  order?: FullSort | null;
};

export enum Header {
  BUTTON = 'button',
  TITLE = 'title',
}

export enum HttpExceptionType {
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  REFRESH_TOKEN_VERIFY = 'REFRESH_TOKEN_VERIFY',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  TOKEN_MALFORMED = 'TOKEN_MALFORMED',
  TOKEN_NOT_PROVIDED = 'TOKEN_NOT_PROVIDED',
  TOKEN_VERIFY = 'TOKEN_VERIFY',
}

export enum HttpStatus {
  OK = 200,
  Created = 201,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export type OptionsToQuery = (
  { limit, page, filter, order, list }: QueryOptions,
  addQueryPrefix?: boolean,
) => string;

export enum ProcessStatus {
  CANCELED = 'canceled',
  FAILED = 'failed',
  HOLD = 'hold',
  PENDING = 'pending',
  REFUND = 'refund',
  SUCCESS = 'success',
}

export type QueryOptions = {
  filter?: FullFilter | null;
  limit?: number;
  list?: string;
  order?: FullSort | null;
  page?: number;
};

type QsParseOption = {
  ignoreQueryPrefix: boolean;
};

export type QueryToOptions = (
  query: string,
  opt?: Partial<QsParseOption>,
) => QueryOptions;

export type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

export enum Role {
  ADMIN = 'admin',
  ANGEL = 'angel',
  EDITOR = 'editor',
  USER = 'user',
}

export enum TransactionClass {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export enum TransactionOperation {
  EARNING = 'earning',
  PAYMENT = 'payment',
  REFUND = 'refund',
  SPENDING = 'spending',
  WITHDRAWAL = 'withdrawal',
}

export enum TransactionStatus {
  CANCELED = 'canceled',
  FAILED = 'failed',
  HOLD = 'hold',
  PENDING = 'pending',
  REFUND = 'refund',
  SUCCESS = 'success',
}

export enum TransactionType {
  INCOME = 'income',
  OUTCOME = 'outcome',
}

export type UploadFile = {
  file: Blob;
  image: 'avatar';
};

export type View = {
  name: string;
  query: string;
};

export type ViewProps = {
  onApplyView: (name: string) => void;
  onRemoveView: (name: string) => void;
  onSaveView: (name: string) => void;
  views: View[];
};

export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
}

export enum FileName {
  AUDIO = 'audio',
  DOCS = 'docs',
  IMAGE = 'image',
  VIDEO = 'video',
}
