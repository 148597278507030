import { FullCommission } from '../../Commission';

export enum TariffType {
  'minute' = 1 / 60,
  'hour' = 1,
}

export type Tariff = {
  commission: FullCommission;
  currency: string;
  id: number;
  rate: number;
  type: keyof typeof TariffType;
};
