import { createContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuth, usePageVisibility } from '@hooks';
import { alertError } from '@store/Alert';
import { getListCommissionRequest } from '@store/Commission';
import { useAppDispatch } from '@store/index';
import { getListLanguageRequest } from '@store/Language';
import { getProfileRequest } from '@store/Profile';
import { getListSpecialtyRequest } from '@store/Specialty';
import { getListTherapyRequest } from '@store/Therapy';
import { getListTimezoneRequest } from '@store/Timezone';
import { LINK_LOGIN } from '@utils';

import { Props } from './index.type';

export const AppContext = createContext<null>(null);

export const AppProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuth, user } = useAuth();

  usePageVisibility();

  useEffect(() => {
    if (isAuth) {
      dispatch(getProfileRequest());
      dispatch(getListLanguageRequest());
      dispatch(getListTimezoneRequest());
      dispatch(getListSpecialtyRequest());
      dispatch(getListTherapyRequest());
      dispatch(getListCommissionRequest());
    } else {
      if (user) {
        dispatch(alertError('Access is closed!'));
      }
      navigate(LINK_LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, dispatch, user]);

  return <AppContext.Provider value={null}>{children}</AppContext.Provider>;
};
