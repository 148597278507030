import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullComment } from '@entities/Comment';
import { CommentHttp } from '@services/http';
import { alertError } from '@store/Alert';

import {
  createCommentFailure,
  createCommentSuccess,
  getListCommentFailure,
  getListCommentRequest,
  getListCommentSuccess,
  removeCommentFailure,
  removeCommentSuccess,
  updateCommentFailure,
  updateCommentSuccess,
} from './Comment.action';
import {
  CREATE_COMMENT_REQUEST,
  GET_LIST_COMMENT_REQUEST,
  REMOVE_COMMENT_REQUEST,
  UPDATE_COMMENT_REQUEST,
} from './Comment.constant';
import {
  ICreateCommentRequest,
  IGetListCommentRequest,
  IRemoveCommentRequest,
  IUpdateCommentRequest,
} from './Comment.type';

function* workerGetListComment(action: IGetListCommentRequest) {
  try {
    const { query } = action.payload;
    const res: DataResponse<FullComment[]> = yield call(
      CommentHttp.getList,
      query,
    );

    yield put(getListCommentSuccess(res));
  } catch (error) {
    yield put(getListCommentFailure());
    yield put(alertError(error));
  }
}

function* watchGetListComment() {
  yield takeLatest(GET_LIST_COMMENT_REQUEST, workerGetListComment);
}

function* workerCreateOneComment(action: ICreateCommentRequest) {
  try {
    const { body, query } = action.payload;

    yield call(CommentHttp.create, body);

    yield put(getListCommentRequest(query));
    yield put(createCommentSuccess());
  } catch (error) {
    yield put(createCommentFailure());
    yield put(alertError(error));
  }
}

function* watchCreateOneComment() {
  yield takeLatest(CREATE_COMMENT_REQUEST, workerCreateOneComment);
}

function* workerUpdateOneComment(action: IUpdateCommentRequest) {
  try {
    const { query, body } = action.payload;

    yield call(CommentHttp.update, body);

    yield put(getListCommentRequest(query));
    yield put(updateCommentSuccess());
  } catch (error) {
    yield put(updateCommentFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateOneComment() {
  yield takeLatest(UPDATE_COMMENT_REQUEST, workerUpdateOneComment);
}

function* workerRemoveOneComment(action: IRemoveCommentRequest) {
  try {
    const { id, query } = action.payload;

    yield call(CommentHttp.remove, id);

    yield put(getListCommentRequest(query));
    yield put(removeCommentSuccess());
  } catch (error) {
    yield put(removeCommentFailure());
    yield put(alertError(error));
  }
}

function* watchRemoveOneComment() {
  yield takeLatest(REMOVE_COMMENT_REQUEST, workerRemoveOneComment);
}

export const commentWatchers: ForkEffect[] = [
  fork(watchGetListComment),
  fork(watchCreateOneComment),
  fork(watchUpdateOneComment),
  fork(watchRemoveOneComment),
];
