import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import type { FullMedia, FullUser } from '@entities/index';
import { MediaHttp, ProfileHttp } from '@services/http';
import { FileName } from '@utils';

import { alertError, alertSuccess } from '../Alert';
import {
  getProfileFailure,
  getProfileSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  uploadAvatarFailure,
  uploadAvatarSuccess,
} from './Profile.action';
import {
  GET_PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST,
  UPLOAD_AVATAR_REQUEST,
} from './Profile.constant';
import { IUpdateProfileRequest, IUploadAvatarRequest } from './Profile.type';

function* workerGetProfile() {
  try {
    const user: FullUser = yield call(ProfileHttp.getProfile);

    yield put(getProfileSuccess(user));
  } catch (error) {
    yield put(getProfileFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateProfile({ payload }: IUpdateProfileRequest) {
  try {
    const { body } = payload;
    const user: FullUser = yield call(ProfileHttp.updateProfile, body);

    yield put(updateProfileSuccess(user));
    yield put(alertSuccess('Profile updated successfully!'));
  } catch (error) {
    yield put(updateProfileFailure());
    yield put(alertError(error));
  }
}

function* workerUploadAvatar({ payload }: IUploadAvatarRequest) {
  try {
    const data: FullMedia = yield call(
      MediaHttp.uploadFile,
      FileName.IMAGE,
      payload.file,
    );

    yield put(uploadAvatarSuccess(data));
    yield put(alertSuccess('Avatar uploaded successfully!'));
  } catch (error) {
    yield put(uploadAvatarFailure());
    yield put(alertError(error));
  }
}

function* watchGetProfile() {
  yield takeLatest(GET_PROFILE_REQUEST, workerGetProfile);
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE_REQUEST, workerUpdateProfile);
}

function* watchUploadAvatar() {
  yield takeLatest(UPLOAD_AVATAR_REQUEST, workerUploadAvatar);
}

export const profileWatchers: ForkEffect[] = [
  fork(watchGetProfile),
  fork(watchUpdateProfile),
  fork(watchUploadAvatar),
];
