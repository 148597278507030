import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { AuthHttp } from '@services/http';

import { alertError } from '../Alert';
import { clearProfile } from '../Profile';
import {
  authLoginFailure,
  authLoginSuccess,
  authLogoutFailure,
  authLogoutSuccess,
} from './Auth.action';
import { AUTH_LOGIN_EMAIL_REQUEST, AUTH_LOGOUT_REQUEST } from './Auth.constant';
import { IAuthLoginEmailRequest } from './Auth.type';

function* workerAuthLoginEmail({ payload }: IAuthLoginEmailRequest) {
  try {
    yield call(AuthHttp.login, payload);
    yield put(authLoginSuccess());
  } catch (error) {
    yield put(authLoginFailure());
    yield put(alertError(error));
  }
}

function* watchAuthLoginEmail() {
  yield takeLatest(AUTH_LOGIN_EMAIL_REQUEST, workerAuthLoginEmail);
}

function* workerAuthLogout() {
  try {
    yield call(AuthHttp.logout);

    yield put(authLogoutSuccess());
    yield put(clearProfile());
  } catch (error) {
    yield put(authLogoutFailure());
    yield put(alertError(error));
  }
}

function* watchAuthLogout() {
  yield takeLatest(AUTH_LOGOUT_REQUEST, workerAuthLogout);
}

export const authWatchers: ForkEffect[] = [
  fork(watchAuthLoginEmail),
  fork(watchAuthLogout),
];
