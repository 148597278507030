import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullBooking } from '@entities/Booking';
import { BookingHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';

import {
  getListBookingFailure,
  getListBookingSuccess,
  getOneBookingFailure,
  getOneBookingSuccess,
  updateBookingFailure,
  updateBookingSuccess,
} from './Booking.action';
import {
  GET_LIST_BOOKING_REQUEST,
  GET_ONE_BOOKING_REQUEST,
  UPDATE_BOOKING_REQUEST,
} from './Booking.constant';
import {
  IGetListBookingRequest,
  IGetOneBookingRequest,
  IUpdateBookingRequest,
} from './Booking.type';

function* workerGetListBooking(action: IGetListBookingRequest) {
  try {
    const { query } = action.payload;

    const payload: DataResponse<FullBooking[]> = yield call(
      BookingHttp.getList,
      query,
    );

    yield put(getListBookingSuccess(payload));
  } catch (error) {
    yield put(getListBookingFailure());
    yield put(alertError(error));
  }
}

function* watchGetListBooking() {
  yield takeLatest(GET_LIST_BOOKING_REQUEST, workerGetListBooking);
}

function* workerGetOneBooking(action: IGetOneBookingRequest) {
  try {
    const { bookingId } = action.payload;

    const payload: DataResponse<FullBooking> = yield call(
      BookingHttp.getOne,
      bookingId,
    );

    yield put(getOneBookingSuccess(payload));
  } catch (error) {
    yield put(getOneBookingFailure());
    yield put(alertError(error));
  }
}

function* watchGetOneBooking() {
  yield takeLatest(GET_ONE_BOOKING_REQUEST, workerGetOneBooking);
}

function* workerUpdateBooking(action: IUpdateBookingRequest) {
  try {
    const { id, ...body } = action.payload;

    yield call(BookingHttp.update, id, body);

    yield put(updateBookingSuccess());
    yield put(alertSuccess('Booking successfully update!'));
  } catch (error) {
    yield put(updateBookingFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateBooking() {
  yield takeLatest(UPDATE_BOOKING_REQUEST, workerUpdateBooking);
}

export const bookingWatchers: ForkEffect[] = [
  fork(watchGetListBooking),
  fork(watchGetOneBooking),
  fork(watchUpdateBooking),
];
