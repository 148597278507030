import { StringHelper } from '@utils';

import { Props } from './index.type';

const UserUIExperience = ({ experience }: Props) => {
  return (
    <span>
      {experience}
      {StringHelper.getNameByCount(' year', experience)}
      in practicle
    </span>
  );
};

export default UserUIExperience;
