import { i18next } from '@i18n';
import { UIComment } from '@ui';

import { Props } from './index.type';

const UserDetailComment = ({ userId }: Props) => {
  return (
    <UIComment
      title={i18next.t('Private Comment')}
      messageAttribute={{ userId }}
      filter={{ userId }}
      limit={3}
      paginationSize="small"
    />
  );
};

export default UserDetailComment;
