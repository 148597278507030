import { useEffect } from 'react';

import { Form, Spin } from 'antd';
import './style.scss';
import { useSelector } from 'react-redux';

import { i18next } from '@i18n';
import {
  clearImageToCollection,
  createCollectionRequest,
  getOneCollectionRequest,
  updateCollectionRequest,
} from '@store/Collection';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { UIButton, UIModal } from '@ui';
import { Button, FileName, UploadFile } from '@utils';

import WidgetModalCollectionForm from './Form';
import { INITIAL_VALUES } from './Form/constant';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalCollection = (props: Props) => {
  const { collectionId } = props;

  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );
  const { collection, loading: loadingCollection } = useSelector(
    (state: RootState) => state.collection,
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (collectionId) {
      dispatch(getOneCollectionRequest(+collectionId));
    }
  }, [dispatch, collectionId]);

  const isCreate = props.type === 'create';

  const onFormSubmitHandler = (values: FormValues) => {
    if (isCreate) {
      dispatch(createCollectionRequest(values));
    } else {
      dispatch(updateCollectionRequest({ ...values, id: +collectionId }));
    }
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearImageToCollection());
    dispatch(clearStore());
  };

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => form.submit()}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {isCreate ? i18next.t('Add collection') : i18next.t('Save')}
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={
        isCreate ? i18next.t('Add collection') : i18next.t('Edit Collection')
      }
      footer={footer}
      visible
      children={
        <Spin spinning={loadingCollection || loadingImage}>
          <WidgetModalCollectionForm
            collection={collectionId ? collection : INITIAL_VALUES}
            image={
              collectionId ? fileStore || collection?.image || null : fileStore
            }
            form={form}
            onFormSubmit={onFormSubmitHandler}
            onUploadImage={onUploadImageHandler}
            onRemoveImage={onRemoveImageHandler}
          />
        </Spin>
      }
    />
  );
};

export default WidgetModalCollection;
