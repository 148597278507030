export const GET_LIST_COMMENT_REQUEST = '@comment/GET_LIST_COMMENT_REQUEST';
export const GET_LIST_COMMENT_SUCCESS = '@comment/GET_LIST_COMMENT_SUCCESS';
export const GET_LIST_COMMENT_FAILURE = '@comment/GET_LIST_COMMENT_FAILURE';

export const CREATE_COMMENT_REQUEST = '@comment/CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = '@comment/CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = '@comment/CREATE_COMMENT_FAILURE';

export const UPDATE_COMMENT_REQUEST = '@comment/UPDATE_COMMENT_REQUEST';
export const UPDATE_COMMENT_SUCCESS = '@comment/UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILURE = '@comment/UPDATE_COMMENT_FAILURE';

export const REMOVE_COMMENT_REQUEST = '@comment/REMOVE_COMMENT_REQUEST';
export const REMOVE_COMMENT_SUCCESS = '@comment/REMOVE_COMMENT_SUCCESS';
export const REMOVE_COMMENT_FAILURE = '@comment/REMOVE_COMMENT_FAILURE';
