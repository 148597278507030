import Tag from 'antd/lib/tag';

import { Props } from './index.type';

const UITag = (props: Props) => {
  const { values, value } = props;

  if (!values[`${value}`]) {
    return <Tag>Empty</Tag>;
  }

  const currentValue = values[`${value}`];

  return <Tag color={currentValue?.color}>{currentValue.title}</Tag>;
};

export default UITag;
