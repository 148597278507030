import { ActionStatus, META_DEFAULT } from '@utils';

import {
  GET_LIST_BOOKING_FAILURE,
  GET_LIST_BOOKING_REQUEST,
  GET_LIST_BOOKING_SUCCESS,
  GET_ONE_BOOKING_FAILURE,
  GET_ONE_BOOKING_REQUEST,
  GET_ONE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAILURE,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
} from './Booking.constant';
import { BookingActionsTypes, IBookingState } from './Booking.type';

const initialState: IBookingState = {
  bookingList: [],
  meta: META_DEFAULT,
  loading: false,
  loaded: false,
  status: ActionStatus.NONE,
  booking: null,
};

export default function bookingReducer(
  state = initialState,
  action: BookingActionsTypes,
): IBookingState {
  switch (action.type) {
    case GET_LIST_BOOKING_REQUEST:
    case GET_ONE_BOOKING_REQUEST:
    case UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        status: ActionStatus.NONE,
      };
    case GET_LIST_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingList: action.payload.data,
        meta: action.payload.meta,
        loaded: true,
        loading: false,
        status: ActionStatus.LOADED,
      };
    }
    case GET_ONE_BOOKING_SUCCESS: {
      const booking = action.payload.data;

      return {
        ...state,
        bookingList: state.bookingList.map((b) =>
          b.id === booking.id ? booking : b,
        ),
        booking,
        loading: false,
        status: ActionStatus.LOADED,
      };
    }
    case UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: true,
        status: ActionStatus.UPDATED,
      };
    case GET_LIST_BOOKING_FAILURE:
    case UPDATE_BOOKING_FAILURE:
    case GET_ONE_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
