import { META_DEFAULT } from '@utils';

import {
  CREATE_COMMENT_FAILURE,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  GET_LIST_COMMENT_FAILURE,
  GET_LIST_COMMENT_REQUEST,
  GET_LIST_COMMENT_SUCCESS,
  REMOVE_COMMENT_FAILURE,
  REMOVE_COMMENT_REQUEST,
  REMOVE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
} from './Comment.constant';
import { CommentActionsTypes, ICommentState } from './Comment.type';

const initialState: ICommentState = {
  commentList: [],
  meta: META_DEFAULT,
  loading: false,
};

export default function commentReducer(
  state = initialState,
  action: CommentActionsTypes,
): ICommentState {
  switch (action.type) {
    case GET_LIST_COMMENT_REQUEST:
    case CREATE_COMMENT_REQUEST:
    case UPDATE_COMMENT_REQUEST:
    case REMOVE_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_COMMENT_SUCCESS:
      return {
        ...state,
        commentList: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_COMMENT_FAILURE:
    case CREATE_COMMENT_FAILURE:
    case CREATE_COMMENT_SUCCESS:
    case UPDATE_COMMENT_SUCCESS:
    case REMOVE_COMMENT_SUCCESS:
    case UPDATE_COMMENT_FAILURE:
    case REMOVE_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
