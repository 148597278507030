import {
  ApproveStatus,
  TransactionClass,
  TransactionOperation,
  TransactionStatus,
  TransactionType,
} from '../utility-types';

export const processAppreove = {
  [ApproveStatus.YES]: {
    title: ApproveStatus.YES,
    color: 'green',
  },
  [ApproveStatus.NO]: {
    title: ApproveStatus.NO,
    color: 'red',
  },
};

export const processClassOptions = {
  [TransactionClass.EXTERNAL]: {
    title: TransactionClass.EXTERNAL,
    color: 'green',
  },
  [TransactionClass.INTERNAL]: {
    title: TransactionClass.INTERNAL,
    color: 'magenta',
  },
};

export const processOperationOptions = {
  [TransactionOperation.EARNING]: {
    title: TransactionOperation.EARNING,
    color: 'green',
  },
  [TransactionOperation.PAYMENT]: {
    title: TransactionOperation.PAYMENT,
    color: 'magenta',
  },
  [TransactionOperation.SPENDING]: {
    title: TransactionOperation.SPENDING,
    color: 'magenta',
  },
  [TransactionOperation.REFUND]: {
    title: TransactionOperation.REFUND,
    color: 'magenta',
  },
  [TransactionOperation.WITHDRAWAL]: {
    title: TransactionOperation.WITHDRAWAL,
    color: 'magenta',
  },
};

export const processStatusOptions = {
  [TransactionStatus.SUCCESS]: {
    title: TransactionStatus.SUCCESS,
    color: 'green',
  },
  [TransactionStatus.PENDING]: {
    title: TransactionStatus.PENDING,
    color: 'orange',
  },
  [TransactionStatus.FAILED]: {
    title: TransactionStatus.FAILED,
    color: 'red',
  },
  [TransactionStatus.HOLD]: {
    title: TransactionStatus.HOLD,
    color: 'geekblue',
  },
  [TransactionStatus.REFUND]: {
    title: TransactionStatus.REFUND,
    color: 'magenta',
  },
  [TransactionStatus.CANCELED]: {
    title: TransactionStatus.CANCELED,
    color: 'volcano',
  },
};

export const processTypeOptions = {
  [TransactionType.INCOME]: {
    title: TransactionType.INCOME,
    color: 'green',
  },
  [TransactionType.OUTCOME]: {
    title: TransactionType.OUTCOME,
    color: 'magenta',
  },
};
