/* eslint-disable no-template-curly-in-string */
export default {
  messages: {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
      min: '${label} must be greater than ${min}',
    },
    string: {
      min: '${label} must be of minimum ${min} characters length',
      max: '${label} must be at more 70 characters long',
    },
  },
  error: {
    exist: 'A filter with this name already exists',
    max: 'The field must be no more than 50 characters length',
  },
};
