import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import { i18next } from '@i18n';
import {
  ActiveStatus,
  DateHelper,
  FORMAT_DATE,
  FilterTab,
  StringHelper,
} from '@utils';

const tab: FilterTab[] = [
  { label: i18next.t('All'), options: { filter: null } },
  { label: i18next.t('Online'), options: { filter: { online: true } } },
  { label: i18next.t('Offline'), options: { filter: { online: false } } },
];

export const sortOption: SortOption[] = [
  {
    label: i18next.t('Id (ascending)'),
    name: 'id',
    direction: 'asc',
  },
  {
    label: i18next.t('Id (descending)'),
    name: 'id',
    direction: 'desc',
  },
  {
    label: i18next.t('Full Name (A-Z)'),
    name: 'full_name',
    direction: 'asc',
  },
  {
    label: i18next.t('Full Name (Z-A)'),
    name: 'full_name',
    direction: 'desc',
  },
  {
    label: i18next.t('Booking Count (ascending)'),
    name: 'booking_count',
    direction: 'asc',
  },
  {
    label: i18next.t('Booking Count (descending)'),
    name: 'booking_count',
    direction: 'desc',
  },
  {
    label: i18next.t('Money Earned (ascending)'),
    name: 'money_earned',
    direction: 'asc',
  },
  {
    label: i18next.t('Money Earned (descending)'),
    name: 'money_earned',
    direction: 'desc',
  },
  {
    label: i18next.t('Favorite Count (ascending)'),
    name: 'favorite_count',
    direction: 'asc',
  },
  {
    label: i18next.t('Favorite Count (descending)'),
    name: 'favorite_count',
    direction: 'desc',
  },
  {
    label: i18next.t('Visit Count (ascending)'),
    name: 'visit_count',
    direction: 'asc',
  },
  {
    label: i18next.t('Visit Count (descending)'),
    name: 'visit_count',
    direction: 'desc',
  },
  {
    label: i18next.t('Rating (ascending)'),
    name: 'rating',
    direction: 'asc',
  },
  {
    label: i18next.t('Rating (descending)'),
    name: 'rating',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions: FilterOption[] = [
  {
    name: 'created_at',
    label: i18next.t('Reg date'),
    type: 'radio-range',
    options: [
      {
        label: i18next.t('Today'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: i18next.t('Last 7 days'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: i18next.t('Last 30 days'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
  {
    label: i18next.t('Money Earned'),
    name: 'money_earned',
    type: 'number-range',
    step: 1,
    min: 0,
    max: 50,
  },
  {
    name: 'booking_count',
    label: i18next.t('Booking Count'),
    type: 'number-range',
    step: 1,
    min: 0,
    max: 50,
  },
  {
    name: 'visit_count',
    label: i18next.t('Visit count'),
    type: 'number-range',
    step: 1,
    min: 0,
    max: 50,
  },
  {
    name: 'favorite_count',
    label: i18next.t('Favorite count'),
    type: 'number-range',
    step: 1,
    min: 0,
    max: 50,
  },
  {
    name: 'is_active',
    label: i18next.t('Active'),
    type: 'radio',
    options: [
      {
        label: i18next.t(StringHelper.capitalize(ActiveStatus.YES)),
        value: 'true',
      },
      {
        label: i18next.t(StringHelper.capitalize(ActiveStatus.NO)),
        value: 'false',
      },
    ],
  },
  {
    name: 'rating',
    label: i18next.t('Rating'),
    type: 'number-range',
    step: 1,
    min: 0,
    max: 5,
  },
];

export default {
  title: i18next.t('Psychologists'),
  viewContainerName: 'angelViews',
  tab,
  sortOption,
  filterOptions,
};
