import { useEffect } from 'react';

import { Form, Input } from 'antd';

import { i18next } from '@i18n';
import { DateHelper, FORMAT_SHORT_DATE } from '@utils';

import { WidgetModalUIImage } from '../../ui';
import { INITIAL_VALUES } from './constant';
import { Props } from './index.type';

const WidgetModalProfileForm = ({
  user,
  onFormSubmit,
  form,
  onUploadImage,
  onRemoveImage,
}: Props) => {
  useEffect(() => {
    if (user) {
      const { email, avatar, profile } = user;

      form.setFieldsValue({ email, avatar, profile });
    }
  }, [user, form]);

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      labelAlign="right"
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={onFormSubmit}
      autoComplete="off"
    >
      <WidgetModalUIImage
        view="avatar"
        path={user.avatar?.path}
        onUploadImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      <Form.Item
        label={i18next.t('First Name')}
        name={['profile', 'firstName']}
        required
        rules={[
          { required: true, message: i18next.t('Please input First Name!') },
        ]}
      >
        <Input placeholder={i18next.t('First Name')} />
      </Form.Item>
      <Form.Item
        label={i18next.t('Last name')}
        name={['profile', 'lastName']}
        required
        rules={[
          { required: true, message: i18next.t('Please input Last Name!') },
        ]}
      >
        <Input placeholder={i18next.t('Last name')} />
      </Form.Item>
      <Form.Item
        label={i18next.t('Email')}
        name="email"
        required
        rules={[
          {
            required: true,
            type: 'email',
            message: i18next.t('The input is not valid E-mail!'),
          },
        ]}
      >
        <Input placeholder={i18next.t('Email')} autoComplete="off" />
      </Form.Item>
      <Form.Item label={i18next.t('Reg Date')}>
        <Input
          value={DateHelper.formateDateToString(
            user.createdAt,
            FORMAT_SHORT_DATE,
          )}
          disabled
        />
      </Form.Item>
    </Form>
  );
};

export default WidgetModalProfileForm;
