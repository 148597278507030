import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_LIST_USER,
  CREATE_REVIEW_FAILURE,
  CREATE_REVIEW_REQUEST,
  CREATE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAILURE,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  GET_LIST_REVIEW_FAILURE,
  GET_LIST_REVIEW_REQUEST,
  GET_LIST_REVIEW_SUCCESS,
  GET_LIST_USER_FAILURE,
  GET_LIST_USER_REQUEST,
  GET_LIST_USER_SUCCESS,
  GET_ONE_REVIEW_FAILURE,
  GET_ONE_REVIEW_REQUEST,
  GET_ONE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAILURE,
  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_SUCCESS,
} from './Review.constant';
import { IReviewState, ReviewActionsTypes } from './Review.type';

const initialState: IReviewState = {
  status: ActionStatus.NONE,
  reviewList: [],
  meta: META_DEFAULT,
  loading: false,
  loaded: false,
  userList: [],
  userLoading: false,
  review: null,
};

export default function reviewReducer(
  state = initialState,
  action: ReviewActionsTypes,
): IReviewState {
  switch (action.type) {
    case GET_ONE_REVIEW_REQUEST:
    case GET_LIST_REVIEW_REQUEST:
    case CREATE_REVIEW_REQUEST:
    case DELETE_REVIEW_REQUEST:
    case UPDATE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case GET_LIST_REVIEW_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.LOADED,
        reviewList: action.payload.data,
        meta: action.payload.meta,
        loaded: true,
        loading: false,
      };
    }

    case GET_ONE_REVIEW_SUCCESS:
      return {
        ...state,
        status: ActionStatus.LOADED,
        loading: false,
        review: action.payload.data,
      };
    case GET_LIST_USER_SUCCESS:
      return {
        ...state,
        status: ActionStatus.LOADED,
        userLoading: false,
        userList: action.payload.data,
      };
    case CLEAR_LIST_USER:
      return {
        ...state,
        userList: [],
      };
    case GET_LIST_USER_FAILURE:
      return {
        ...state,
        userLoading: false,
      };
    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
      };
    case UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
      };
    case CREATE_REVIEW_SUCCESS:
    case CREATE_REVIEW_FAILURE:
    case GET_ONE_REVIEW_FAILURE:
    case GET_LIST_REVIEW_FAILURE:
    case DELETE_REVIEW_FAILURE:
    case UPDATE_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
