import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

import { i18next } from '@i18n';
import { authLogoutRequest } from '@store/Auth';
import { useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';

const { Item } = Menu;

const WidgetHeaderMenu = () => {
  const dispatch = useAppDispatch();

  const editProfile = () => {
    dispatch(showModal({ modalType: ModalType.PROFILE }));
  };

  const logoutHandler = () => {
    dispatch(authLogoutRequest());
  };

  return (
    <Menu>
      <Item key="1" icon={<UserOutlined />} onClick={editProfile}>
        {i18next.t('Edit Profile')}
      </Item>
      <Item
        key="2"
        icon={<UploadOutlined rotate={90} />}
        onClick={logoutHandler}
      >
        {i18next.t('Log Out')}
      </Item>
    </Menu>
  );
};

export default WidgetHeaderMenu;
