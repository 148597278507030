import { Fragment, useCallback, useEffect, useState } from 'react';

import { Checkbox, Col, Divider, Row, Space, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Text from 'antd/lib/typography/Text';
import { useSelector } from 'react-redux';

import { FullUser } from '@entities/User';
import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import {
  getSearchListUserRequest,
  getSearchListUserSuccess,
} from '@store/User';
import { UIAvatar, UISearch } from '@ui';
import { QueryHelper } from '@utils';

import { Props } from './index.type';

import './style.scss';

const WidgetModalUIPeople = ({ defaultValues, onChange, params }: Props) => {
  const dispatch = useAppDispatch();
  const { searchUserList, loadingSearch } = useSelector(
    (state: RootState) => state.user,
  );
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<FullUser[]>([]);

  const onSearchChange = (values: string | null) => {
    if (values && values.length > 2) {
      dispatch(
        getSearchListUserRequest(
          QueryHelper.stringify({
            filter: {
              search: values,
              ...params,
            },
          }),
        ),
      );
    }
    if (values && values.length === 0) {
      dispatch(getSearchListUserSuccess({ data: [] }));
    }
  };

  const onChangeCheckbox = useCallback(
    ({ target: { value, checked } }: CheckboxChangeEvent) => {
      if (checked) {
        const newId: string[] = [...selectedId, value];
        const user = searchUserList.find((el) => el.id === +value);

        setSelectedId(newId);
        if (user) {
          setSelectedUsers([...selectedUsers, user]);
          onChange(newId);
        }
      } else {
        const newId = selectedId.filter((el) => el !== value);

        setSelectedId(newId);
        setSelectedUsers(selectedUsers.filter((el) => el.id !== value));
        onChange(newId);
      }
    },
    [onChange, searchUserList, selectedId, selectedUsers],
  );

  useEffect(() => {
    if (defaultValues) {
      setSelectedId(defaultValues);
    }
  }, [defaultValues]);

  const renderUsers = useCallback(
    (list: FullUser[]) =>
      list?.map((item, key) => (
        <Fragment key={`people_${key}`}>
          <Col span={4}>
            <UIAvatar path={item.avatar?.path} />
          </Col>
          <Col span={18}>
            <Text strong>{item.profile?.fullName || ''}</Text>
          </Col>
          <Col span={2}>
            <Checkbox
              value={`${item.id}`}
              onChange={onChangeCheckbox}
            ></Checkbox>
          </Col>
        </Fragment>
      )),
    [onChangeCheckbox],
  );

  return (
    <Space direction="vertical" size="large" className="people-search__wrap">
      <UISearch
        placeholder={i18next.t('Please input Name')}
        onSearchChange={onSearchChange}
      />
      <Spin spinning={loadingSearch}>
        <Checkbox.Group
          className="people-search__wrap"
          defaultValue={selectedId}
        >
          <Row justify="space-between" align="middle" gutter={[12, 12]}>
            {renderUsers(searchUserList)}
            {!!selectedUsers.length && (
              <>
                <Divider />
                {renderUsers(selectedUsers)}
              </>
            )}
          </Row>
        </Checkbox.Group>
      </Spin>
    </Space>
  );
};

export default WidgetModalUIPeople;
