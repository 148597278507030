import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { columns, orderOption } from '@modules/Collection/Detail/constants';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { clearUserListStore, getListUserRequest } from '@store/User';
import { UIHeader, UITable } from '@ui';
import { Role } from '@utils';

const CollectionDetailExpert = () => {
  const dispatch = useAppDispatch();
  const { collectionId } = useParams<'collectionId'>();

  const { query, updateQuery } = useQuery({
    filter: {
      role: Role.ANGEL,
      ...(collectionId && { specialties: collectionId }),
    },
  });

  const { userList, loading, meta } = useSelector(
    (state: RootState) => state.user,
  );

  const getRequest = useCallback(() => {
    dispatch(getListUserRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  useEffect(() => {
    return () => {
      dispatch(clearUserListStore());
    };
  }, [dispatch]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const onAddClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.ADD_USER_TO_COLLECTION,
        modalProps: {
          collectionId,
          query,
          params: { role: Role.ANGEL },
        },
      }),
    );
  };

  if (!collectionId) {
    return null;
  }

  return (
    <>
      <UIHeader
        isSearch
        sortOption={orderOption}
        searchTitle={i18next.t('Search')}
        onAddHandler={onAddClickHandler}
      />
      <UITable
        dataSource={userList}
        loading={loading}
        columns={columns({ collectionId: +collectionId, query, dispatch })}
        meta={meta}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};

export default CollectionDetailExpert;
