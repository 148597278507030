import { FullBooking } from '@entities/Booking';
import { ProcessStatus, http } from '@utils';

const basePath = '/api/bookings';

export const getList = async (
  query: string,
): Promise<DataResponse<FullBooking[]>> => {
  const { data } = await http.get<DataResponse<FullBooking[]>>(
    `${basePath}${query}`,
  );

  return data;
};

export const getOne = async (
  id: number,
): Promise<DataResponse<FullBooking>> => {
  const { data } = await http.get<DataResponse<FullBooking>>(
    `${basePath}/${id}`,
  );

  return data;
};

export const update = async (
  id: number,
  data: Partial<
    Pick<FullBooking, 'duration'> & { date: string; status: ProcessStatus }
  >,
): Promise<void> => {
  await http.put(`${basePath}/${id}`, data);
};
