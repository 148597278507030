/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Col, Form, Radio, RadioChangeEvent, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';

import { CouponPeriod } from '@entities/Coupon';
import { i18next } from '@i18n';
import { UIAntDatePicker } from '@ui';

import { CouponUIItemAdd } from '../../../ui';
import { Props } from './index.type';

import './style.scss';

const CouponDetailPeriod = ({ form }: Props) => {
  const onChange = (e: RadioChangeEvent) => {
    const value = e?.target?.value || CouponPeriod.UNLIMIT;

    if (value === CouponPeriod.UNLIMIT) {
      form.setFieldsValue({
        startDate: undefined,
        endDate: undefined,
      });
    }

    form.setFieldsValue({ period: value });
  };

  return (
    <CouponUIItemAdd title={i18next.t('Period of use')}>
      <div className="period-wrapper">
        <Row>
          <Form.Item name="period">
            <Radio.Group onChange={onChange}>
              <Space direction="vertical">
                <Radio value={CouponPeriod.UNLIMIT}>
                  {i18next.t('Unlimited time')}
                </Radio>
                <Radio value={CouponPeriod.SPECIFIC}>
                  {i18next.t('At the specified period')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="period-date">
              <Row className="period-date__title" align="middle">
                <Text type="secondary">{i18next.t('Start date')}</Text>
              </Row>
              <Row align="middle">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.period !== currentValues.period
                  }
                >
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="startDate"
                      {...(getFieldValue('usage') === CouponPeriod.SPECIFIC && {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      })}
                    >
                      <UIAntDatePicker
                        placeholder={i18next.t('Select date and time')}
                        {...(getFieldValue('period') !==
                          CouponPeriod.SPECIFIC && { disabled: true })}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="period-date">
              <Row className="period-date__title">
                <Text type="secondary">{i18next.t('End date')}</Text>
              </Row>
              <Row align="middle">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.period !== currentValues.period
                  }
                >
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="endDate"
                      {...(getFieldValue('usage') === CouponPeriod.SPECIFIC && {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      })}
                    >
                      <UIAntDatePicker
                        placeholder={i18next.t('Select date and time')}
                        {...(getFieldValue('period') !==
                          CouponPeriod.SPECIFIC && { disabled: true })}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </CouponUIItemAdd>
  );
};

export default CouponDetailPeriod;
