import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullSchedule } from '@entities/Schedule';
import { ScheduleHttp } from '@services/http';
import { alertError } from '@store/Alert';

import {
  getListScheduleFailure,
  getListScheduleSuccess,
} from './Schedule.action';
import { GET_LIST_SCHEDULE_REQUEST } from './Schedule.constant';
import { IGetListScheduleRequest } from './Schedule.type';

function* workerGetListSchedule(action: IGetListScheduleRequest) {
  try {
    const { query } = action.payload;

    const payload: FullSchedule[] = yield call(ScheduleHttp.getList, query);

    yield put(getListScheduleSuccess(payload));
  } catch (error) {
    yield put(getListScheduleFailure());
    yield put(alertError(error));
  }
}

function* watchGetListSchedule() {
  yield takeLatest(GET_LIST_SCHEDULE_REQUEST, workerGetListSchedule);
}

export const scheduleWatchers: ForkEffect[] = [fork(watchGetListSchedule)];
