import { Space } from 'antd';
import { NavLink } from 'react-router-dom';

import UIAvatar from '../../Avatar';
import { Props } from './index.type';
import './style.scss';

const UICellFullName = ({ firstName, lastName, link, src }: Props) => {
  return (
    <Space size="middle" className="avatar">
      <NavLink to={link}>
        <UIAvatar path={src} />
        <span className="avatar-name">
          {firstName} {lastName}
        </span>
      </NavLink>
    </Space>
  );
};

export default UICellFullName;
