import { FullCoupon, RequestCoupon } from '@entities/Coupon';

import {
  CREATE_COUPON_FAILURE,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  GET_LIST_COUPON_FAILURE,
  GET_LIST_COUPON_REQUEST,
  GET_LIST_COUPON_SUCCESS,
  GET_ONE_COUPON_FAILURE,
  GET_ONE_COUPON_REQUEST,
  GET_ONE_COUPON_SUCCESS,
  REMOVE_COUPON_FAILURE,
  REMOVE_COUPON_REQUEST,
  REMOVE_COUPON_SUCCESS,
  RESET_COUPON_LIST,
  RESET_COUPON_ONE,
  UPDATE_COUPON_FAILURE,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
} from './Coupon.constant';
import { CouponActionsTypes } from './Coupon.type';

export const getListCouponRequest = (query?: string): CouponActionsTypes => ({
  type: GET_LIST_COUPON_REQUEST,
  payload: { query },
});

export const getListCouponSuccess = (
  payload: DataResponse<FullCoupon[]>,
): CouponActionsTypes => ({
  type: GET_LIST_COUPON_SUCCESS,
  payload,
});

export const getListCouponFailure = (): CouponActionsTypes => ({
  type: GET_LIST_COUPON_FAILURE,
});

export const getOneCouponRequest = (id: number): CouponActionsTypes => ({
  type: GET_ONE_COUPON_REQUEST,
  payload: { id },
});

export const getOneCouponSuccess = (
  payload: DataResponse<FullCoupon>,
): CouponActionsTypes => ({
  type: GET_ONE_COUPON_SUCCESS,
  payload,
});

export const getOneCouponFailure = (): CouponActionsTypes => ({
  type: GET_ONE_COUPON_FAILURE,
});

export const createCouponRequest = (
  body: Omit<RequestCoupon, 'isActive'>,
  query?: string,
): CouponActionsTypes => ({
  type: CREATE_COUPON_REQUEST,
  payload: { body, query },
});

export const createCouponSuccess = (
  payload: DataResponse<FullCoupon>,
): CouponActionsTypes => ({
  type: CREATE_COUPON_SUCCESS,
  payload,
});

export const createCouponFailure = (): CouponActionsTypes => ({
  type: CREATE_COUPON_FAILURE,
});

export const updateCouponRequest = (
  id: number,
  body: Partial<RequestCoupon>,
  query?: string,
): CouponActionsTypes => ({
  type: UPDATE_COUPON_REQUEST,
  payload: { id, body, query },
});

export const updateCouponSuccess = (
  payload: DataResponse<FullCoupon>,
): CouponActionsTypes => ({
  type: UPDATE_COUPON_SUCCESS,
  payload,
});

export const updateCouponFailure = (): CouponActionsTypes => ({
  type: UPDATE_COUPON_FAILURE,
});

export const removeCouponRequest = (
  id: number,
  query?: string,
): CouponActionsTypes => ({
  type: REMOVE_COUPON_REQUEST,
  payload: { id, query },
});

export const removeCouponSuccess = (): CouponActionsTypes => ({
  type: REMOVE_COUPON_SUCCESS,
});

export const removeCouponFailure = (): CouponActionsTypes => ({
  type: REMOVE_COUPON_FAILURE,
});

export const resetCouponList = (): CouponActionsTypes => ({
  type: RESET_COUPON_LIST,
});

export const resetCouponOne = (): CouponActionsTypes => ({
  type: RESET_COUPON_ONE,
});
