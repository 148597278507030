export const GET_LIST_COUPON_REQUEST = '@coupon/GET_LIST_COUPON_REQUEST';
export const GET_LIST_COUPON_SUCCESS = '@coupon/GET_LIST_COUPON_SUCCESS';
export const GET_LIST_COUPON_FAILURE = '@coupon/GET_LIST_COUPON_FAILURE';

export const GET_ONE_COUPON_REQUEST = '@coupon/GET_ONE_COUPON_REQUEST';
export const GET_ONE_COUPON_SUCCESS = '@coupon/GET_ONE_COUPON_SUCCESS';
export const GET_ONE_COUPON_FAILURE = '@coupon/GET_ONE_COUPON_FAILURE';

export const CREATE_COUPON_REQUEST = '@coupon/CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = '@coupon/CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILURE = '@coupon/CREATE_COUPON_FAILURE';

export const UPDATE_COUPON_REQUEST = '@coupon/UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = '@coupon/UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILURE = '@coupon/UPDATE_COUPON_FAILURE';

export const REMOVE_COUPON_REQUEST = '@coupon/REMOVE_COUPON_REQUEST';
export const REMOVE_COUPON_SUCCESS = '@coupon/REMOVE_COUPON_SUCCESS';
export const REMOVE_COUPON_FAILURE = '@coupon/REMOVE_COUPON_FAILURE';

export const RESET_COUPON_LIST = '@coupon/RESET_COUPON_LIST';
export const RESET_COUPON_ONE = '@coupon/RESET_COUPON_ONE';
