import { Login } from '@entities/Auth';
import { http } from '@utils';

const basePath = '/api/auth';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const login = async (body: Login): Promise<DataResponse<Token>> => {
  const { data } = await http.post<DataResponse<Token>>(
    `${basePath}/login`,
    body,
  );

  return data;
};

export const logout = async (): Promise<void> => {
  await http.post(`${basePath}/logout`);
};
