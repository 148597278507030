import { ALERT_CLEAR, ALERT_ERROR, ALERT_SUCCESS } from './Alert.constant';
import { AlertActionsTypes, IAlertState } from './Alert.type';

const initialState: IAlertState = {
  message: '',
  description: '',
  type: null,
};

export default function alertReducer(
  state = initialState,
  action: AlertActionsTypes,
): IAlertState {
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        type: 'success',
        message: action.payload.message,
        description: action.payload.description,
      };
    case ALERT_ERROR:
      return {
        type: 'error',
        message: action.payload.message,
        description: action.payload.description,
      };
    case ALERT_CLEAR:
      return initialState;
    default:
      return state;
  }
}
