import { DeepPartial } from 'redux';

import { FullUser } from '@entities/User';
import { http } from '@utils';

const basePath = '/api/users/current';

export const getProfile = async (): Promise<FullUser> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullUser>>(basePath);

  return data;
};

export const updateProfile = async (
  body: DeepPartial<FullUser>,
): Promise<FullUser> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullUser>>(basePath, body);

  return data;
};
