import { Button, Menu } from 'antd';

import { i18next } from '@i18n';
import { Role } from '@utils';

import { Props } from './index.type';

const UserUIMenu = ({ onDeactivate, user, role }: Props) => {
  const label = role === Role.USER ? 'client' : 'psychologist';

  const action = user.isActive ? 'Deactivate' : 'Activate';
  const buttonText = `${action} the ${label}`;

  return (
    <Menu>
      <Menu.Item
        key={`${user.isActive ? 'Deactivate' : 'Activate'} the ${label}`}
        onClick={onDeactivate}
      >
        <Button type="text" danger={user.isActive}>
          {i18next.t(buttonText)}
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default UserUIMenu;
