import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import { FullSpecialty } from '@entities/Specialty';
import { i18next } from '@i18n';
import type { AppDispatch } from '@store/index';
import { UICellLink, UICellStatus, UICellTooltip } from '@ui';
import {
  ActiveStatus,
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_LONG_TEXT,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_TAG,
  StringHelper,
} from '@utils';

import { CollectionUIActionList } from '../ui';

export const columns = ({
  dispatch,
  path,
}: {
  dispatch: AppDispatch;
  path: string;
}): ColumnsType<FullSpecialty> => [
  {
    title: i18next.t('Id'),
    dataIndex: 'id',
    key: 'id',
    render: (value, record) =>
      UICellLink({ value, link: `${path}/${record.id}` }),
    width: COLUMN_WIDTH_ID,
  },
  {
    title: i18next.t('Collection name'),
    dataIndex: 'name',
    key: 'name',
    render: (value, record) =>
      UICellLink({
        value,
        link: `${path}/${record.id}`,
      }),
    width: COLUMN_WIDTH_NAME,
    ellipsis: true,
  },
  {
    title: i18next.t('Active'),
    dataIndex: 'isActive',
    key: 'isActive',
    width: COLUMN_WIDTH_TAG,
    render: (value) =>
      UICellStatus({
        value,
        text: value ? i18next.t('Active') : i18next.t('Inactive'),
      }),
  },
  {
    title: i18next.t('Expert Count'),
    dataIndex: 'numberOfExpert',
    key: 'numberOfExpert',
    width: COLUMN_WIDTH_NUMBER,
  },
  {
    title: i18next.t('Visit count'),
    dataIndex: 'numberOfView',
    key: ' numberOfView',
    width: COLUMN_WIDTH_NUMBER,
  },
  {
    title: i18next.t('Description'),
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    width: COLUMN_WIDTH_LONG_TEXT,
    render: (text) => UICellTooltip({ text }),
  },
  {
    title: i18next.t('Actions'),
    dataIndex: 'id',
    key: 'id',
    width: COLUMN_WIDTH_ACTIONS,
    render: (id) => CollectionUIActionList({ id, dispatch }),
    align: 'right',
  },
];

export const orderOption: SortOption[] = [
  {
    label: i18next.t('Id (ascending)'),
    name: 'id',
    direction: 'asc',
  },
  {
    label: i18next.t('Id (descending)'),
    name: 'id',
    direction: 'desc',
  },
  {
    label: i18next.t('Name (A-Z)'),
    name: 'name',
    direction: 'asc',
  },
  {
    label: i18next.t('Name (Z-A)'),
    name: 'name',
    direction: 'desc',
  },
  {
    label: i18next.t('Expert Count (ascending)'),
    name: 'numberOfExpert',
    direction: 'asc',
  },
  {
    label: i18next.t('Expert Count (descending)'),
    name: 'numberOfExpert',
    direction: 'desc',
  },
  {
    label: i18next.t('Visit count (ascending)'),
    name: 'numberOfView',
    direction: 'asc',
  },
  {
    label: i18next.t('Visit count (descending)'),
    name: 'numberOfView',
    direction: 'desc',
  },
];

export const filterOptions: FilterOption[] = [
  {
    name: 'is_active',
    label: i18next.t('Active'),
    type: 'radio',
    options: [
      {
        label: i18next.t(StringHelper.capitalize(ActiveStatus.YES)),
        value: 'true',
      },
      {
        label: i18next.t(StringHelper.capitalize(ActiveStatus.NO)),
        value: 'false',
      },
    ],
  },
  {
    name: 'number_of_expert',
    label: i18next.t('Expert count'),
    type: 'number-range',
    step: 1,
    min: 0,
  },
  {
    name: 'number_of_view',
    label: i18next.t('Visit count'),
    type: 'number-range',
    step: 1,
    min: 0,
  },
];
