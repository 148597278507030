import { useEffect, useRef } from 'react';

import { ChatUiSeparator } from '../../../ui';
import ChatDetailMessageItem from '../Item';
import { Props } from './index.type';

const ChatDetailMessageList = ({ messages }: Props) => {
  const messagesRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (messagesRef?.current) {
      messagesRef.current.scrollIntoView(false);
    }
  }, [messages.length]);

  const renderList = () =>
    messages?.map((message, key) => (
      <div key={`message_${key}`}>
        <ChatUiSeparator
          step={key}
          currentDate={message.createdAt}
          prevDate={messages[key - 1]?.createdAt || message.createdAt}
        />
        <ChatDetailMessageItem message={message} />
      </div>
    ));

  return (
    <div className="message-list chat-detail">
      {renderList()}
      <div ref={messagesRef} />
    </div>
  );
};

export default ChatDetailMessageList;
