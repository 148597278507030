import { useCallback, useEffect } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import { getListReviewRequest } from '@store/Review';
import { UIHeader, UITable } from '@ui';
import { ActionStatus } from '@utils';

import { columns, filterOptions, sortOption } from '../constant';

const ReviewList = () => {
  const dispatch = useAppDispatch();
  const { query, updateQuery } = useQuery();
  const { reviewList, meta, loading, status } = useSelector(
    (state: RootState) => state.review,
  );

  useEffect(() => {
    dispatch(getListReviewRequest(query));
  }, [query, dispatch]);

  const onPaginationChange = useCallback(
    (page: number, limit = meta.limit) => {
      updateQuery({ page, limit });
    },
    [meta.limit, updateQuery],
  );

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      onPaginationChange(1, meta.limit);
    }

    if (status === ActionStatus.UPDATED) {
      onPaginationChange(meta.currentPage, meta.limit);
    }
  }, [meta.currentPage, meta.limit, onPaginationChange, status]);

  return (
    <>
      <UIHeader
        title={i18next.t('Reviews')}
        viewContainerName={i18next.t('Reviews')}
        isSearch
        searchTitle={i18next.t('Filter reviews')}
        sortOption={sortOption({})}
        filterOptions={filterOptions}
      />
      <Row>
        <UITable
          dataSource={reviewList}
          loading={loading}
          columns={columns({ dispatch })}
          meta={meta}
          onPaginationChange={onPaginationChange}
          isScrollDefault
        />
      </Row>
    </>
  );
};

export default ReviewList;
