import { Button, Col, Form, Input, InputNumber, Row } from 'antd';

import { i18next } from '@i18n';

import { Props } from './index.type';

const CommissionUIForm = ({ name = '', amount = 10, onSave }: Props) => {
  return (
    <Col xs={24} sm={24} md={18}>
      <Row>
        <Col span={24}>
          <Form
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            layout="horizontal"
            style={{ minWidth: 300 }}
            onFinish={onSave}
            initialValues={{
              name,
              amount,
            }}
          >
            <Form.Item
              style={{ display: 'block' }}
              label={i18next.t('Name')}
              labelCol={{ style: { width: '200px' } }}
              name="name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{ span: 14 }}
              label={`${i18next.t('Platform commission')} ( % )`}
              labelCol={{ style: { width: '200px' } }}
              name="amount"
            >
              <InputNumber />
            </Form.Item>

            <Form.Item style={{ marginLeft: '200px' }}>
              <Button type="primary" htmlType="submit">
                {i18next.t('Save')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Col>
  );
};

export default CommissionUIForm;
