import { HIDE_MODAL, SHOW_MODAL } from './Modal.constant';

export enum ModalType {
  ADD_USER_TO_COLLECTION = 'addUserToCollection',
  BOOKING_EDIT = 'bookingEdit',
  BOOKING_REFUND = 'bookingRefund',
  COMMISSION = 'commission',
  CONFIRM = 'confirm',
  CREATE_COLLECTION = 'createCollection',
  CREATE_RESERVATION = 'createReservation',
  CREATE_REVIEW = 'createReview',
  CREATE_SPECIALTY = 'createSpecialty',
  CREATE_TRANSACTION = 'createTransaction',
  EDIT_REVIEW = 'editReview',
  FILTER = 'filter',
  PROFILE = 'profile',
  SEARCH_ENGINE = 'searchEngine',
  USER = 'user',
}
export interface IModalState {
  active: boolean;
  modalProps?: any;
  modalType: ModalType | null;
}

export type IShowModal = {
  payload: Pick<IModalState, 'modalType' | 'modalProps'>;
  type: typeof SHOW_MODAL;
};

export type IHideModal = {
  type: typeof HIDE_MODAL;
};

export type ModalActionsTypes = IShowModal | IHideModal;
