import { FullUser } from '@entities/User';

export type ReviewUser = Pick<FullUser, 'id' | 'avatar' | 'profile'>;

export type FullReview = Id & {
  angel: ReviewUser;
  createdAt: string;
  message: string;
  rating: number;
  user: ReviewUser;
};

export type CreateReviewRequest = Pick<FullReview, 'rating' | 'message'> & {
  angelId: UserId;
  userId: UserId;
};

export enum ReviewStatus {
  DELETED = 'DELETED',
  LOADED = 'LOADED',
  NONE = 'none',
  UPDATED = 'UPDATED',
}
