import './style.scss';
import { Col, Divider } from 'antd';
import Title from 'antd/lib/typography/Title';

import {
  CouponAmount,
  CouponCodeType,
  CouponCustomer,
  CouponPeriod,
  CouponProduct,
  CouponUsage,
} from '@entities/Coupon';
import { i18next } from '@i18n';
import { UICellStatus, UIDetailRow } from '@ui';
import { DateHelper, FORMAT_DATE_WITH_MONTH } from '@utils';

import { Props } from './index.type';

const CouponDetailSummary = ({ coupon }: Props) => {
  if (!coupon) {
    return null;
  }

  const renderCodeType = () => {
    const product =
      coupon.product === CouponProduct.ALL
        ? i18next.t('all products')
        : i18next.t('specific products');

    switch (coupon.codeType) {
      case CouponCodeType.FIX_AMOUNT:
        return `€${coupon.value} off ${product}`;
      default:
        return `${coupon.value}% off ${product}`;
    }
  };

  const renderUsageLimit = () => {
    switch (coupon.usage) {
      case CouponUsage.UNLIMIT:
        return i18next.t('Unlimited time');
      case CouponUsage.LIMIT:
        return i18next.t('Limited use :count times', coupon.usage || 0);
      default:
        return i18next.t('One time');
    }
  };

  const renderSpecificProduct = () => {
    return i18next.t(
      ':consultation min consultation',
      coupon.consultation?.join('min ,') ?? '',
    );
  };

  const renderPeriod = () => {
    switch (coupon.period) {
      case CouponPeriod.SPECIFIC:
        return `From ${
          coupon.startDate
            ? DateHelper.getDateFormat(coupon.startDate, FORMAT_DATE_WITH_MONTH)
            : '-'
        } to ${
          coupon.endDate
            ? DateHelper.getDateFormat(coupon.endDate, FORMAT_DATE_WITH_MONTH)
            : '-'
        }`;
      default:
        return i18next.t('Unlimited time');
    }
  };

  const renderAmountLimit = () => {
    switch (coupon.amount) {
      case CouponAmount.RANGE:
        return `Min ${coupon.minAmount || '-'}, Max ${coupon.maxAmount || ''}`;
      default:
        return i18next.t('For any order amount');
    }
  };

  const renderCustomerLimit = () => {
    switch (coupon.customer) {
      case CouponCustomer.SPECIFIC:
        return i18next.t('Specific customers');
      default:
        return i18next.t('Anyone can use');
    }
  };

  return (
    <div className="discount-summary">
      <Col span={12}>
        <Title level={5}>{i18next.t('Summary')}</Title>
      </Col>
      <Divider />
      <UIDetailRow
        isTitleBold
        title={coupon?.code || ''}
        children={
          <UICellStatus
            text={
              coupon.isActive ? i18next.t('Enabled') : i18next.t('Disabled')
            }
            value={coupon?.isActive || false}
          />
        }
        className="discount-container"
      />
      <UIDetailRow
        title={i18next.t('Types')}
        children={renderCodeType()}
        className="discount-container"
      />
      {coupon.product === CouponProduct.SPECIFIC && (
        <UIDetailRow
          title={i18next.t('Specific products')}
          children={renderSpecificProduct()}
          className="discount-container"
        />
      )}
      <UIDetailRow
        title={i18next.t('Usage limits')}
        children={renderUsageLimit()}
        className="discount-container"
      />
      <UIDetailRow
        title={i18next.t('Period of use')}
        children={renderPeriod()}
        className="discount-container"
      />
      <UIDetailRow
        title={i18next.t('Limit on the amount')}
        children={renderAmountLimit()}
        className="discount-container"
      />
      <UIDetailRow
        title={i18next.t('Limited of customers')}
        children={renderCustomerLimit()}
        className="discount-container"
      />
    </div>
  );
};

export default CouponDetailSummary;
