import { FullBooking } from '@entities/Booking';

import {
  GET_LIST_BOOKING_FAILURE,
  GET_LIST_BOOKING_REQUEST,
  GET_LIST_BOOKING_SUCCESS,
  GET_ONE_BOOKING_FAILURE,
  GET_ONE_BOOKING_REQUEST,
  GET_ONE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAILURE,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
} from './Booking.constant';
import { BookingActionsTypes } from './Booking.type';

export function getListBookingRequest(query = ''): BookingActionsTypes {
  return {
    type: GET_LIST_BOOKING_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListBookingSuccess(
  payload: DataResponse<FullBooking[]>,
): BookingActionsTypes {
  return {
    type: GET_LIST_BOOKING_SUCCESS,
    payload,
  };
}

export function getListBookingFailure(): BookingActionsTypes {
  return {
    type: GET_LIST_BOOKING_FAILURE,
  };
}

export function getOneBookingRequest(bookingId: number): BookingActionsTypes {
  return {
    type: GET_ONE_BOOKING_REQUEST,
    payload: {
      bookingId,
    },
  };
}

export function getOneBookingSuccess(
  payload: DataResponse<FullBooking>,
): BookingActionsTypes {
  return {
    type: GET_ONE_BOOKING_SUCCESS,
    payload,
  };
}

export function getOneBookingFailure(): BookingActionsTypes {
  return {
    type: GET_ONE_BOOKING_FAILURE,
  };
}

export function updateBookingRequest(
  payload: Pick<FullBooking, 'id'> &
    Partial<
      Pick<FullBooking, 'duration' | 'status'> & {
        date: string;
      }
    >,
): BookingActionsTypes {
  return {
    type: UPDATE_BOOKING_REQUEST,
    payload,
  };
}

export function updateBookingSuccess(): BookingActionsTypes {
  return {
    type: UPDATE_BOOKING_SUCCESS,
  };
}

export function updateBookingFailure(): BookingActionsTypes {
  return {
    type: UPDATE_BOOKING_FAILURE,
  };
}
