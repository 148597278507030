import { useEffect } from 'react';

import { Form, Input, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { i18next } from '@i18n';
import { VALIDATE_MAX_SEO_DESCRIPTION, VALIDATE_MAX_SEO_TITLE } from '@utils';

import validation from '../../../../i18n/validation';
import { WidgetModalUIImage } from '../../ui';
import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const WidgetModalCollectionForm = ({
  onFormSubmit,
  form,
  collection,
  onUploadImage,
  image,
  onRemoveImage,
}: Props) => {
  useEffect(() => {
    if (collection) {
      form.setFieldsValue(collection);
    }
  }, [collection, form]);

  const handlerFinish = (values: FormValues) => {
    onFormSubmit({
      ...values,
      ...(values.seo && {
        seo: {
          ...collection?.seo,
          ...values.seo,
        },
      }),
      ...(image && { image }),
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={validation.messages}
    >
      <WidgetModalUIImage
        name="image"
        view="image"
        path={image?.path || 'error'}
        onUploadImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      <Tabs className="lang-tab" defaultActiveKey="name-lang-en">
        <Tabs.TabPane tab={'En'} key="name-lang-en">
          <Form.Item
            name="name"
            label={i18next.t('Name of the collection')}
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <Input placeholder={i18next.t('Enter the collection')} />
          </Form.Item>
          <Form.Item
            label={i18next.t('Description')}
            name="description"
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <TextArea
              showCount
              rows={4}
              placeholder={i18next.t('Enter the description')}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Ру'} key="name-lang-ru">
          <Form.Item
            name="name_ru"
            label={i18next.t('Name of the collection')}
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <Input placeholder={i18next.t('Enter the collection')} />
          </Form.Item>
          <Form.Item
            label={i18next.t('Description')}
            name="description_ru"
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <TextArea
              showCount
              rows={4}
              placeholder={i18next.t('Enter the description')}
            />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
      <Form.Item
        label="SEO title"
        name={['seo', 'title']}
        required
        rules={[
          {
            required: true,
            whitespace: true,
          },
          { max: VALIDATE_MAX_SEO_TITLE, type: 'string' },
        ]}
      >
        <Input
          showCount
          placeholder={i18next.t('Enter the seo title')}
          maxLength={VALIDATE_MAX_SEO_TITLE}
        />
      </Form.Item>
      <Form.Item
        label={i18next.t('SEO description')}
        name={['seo', 'description']}
        required
        rules={[
          {
            required: true,
            whitespace: true,
          },
          {
            max: VALIDATE_MAX_SEO_DESCRIPTION,
            type: 'string',
          },
        ]}
      >
        <TextArea
          showCount
          rows={4}
          placeholder={i18next.t('Enter the seo description')}
          maxLength={VALIDATE_MAX_SEO_DESCRIPTION}
        />
      </Form.Item>
      <Form.Item
        label={i18next.t('SEO keywords')}
        name={['seo', 'keywords']}
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <TextArea rows={4} placeholder={i18next.t('Enter the seo keywords')} />
      </Form.Item>
      <Form.Item
        label="URL"
        name={['seo', 'slug']}
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input placeholder={i18next.t('Enter the url')} />
      </Form.Item>
    </Form>
  );
};

export default WidgetModalCollectionForm;
