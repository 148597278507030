import { Dayjs } from 'dayjs';

import { FullSchedule } from '@entities/Schedule';

import {
  GET_LIST_SCHEDULE_FAILURE,
  GET_LIST_SCHEDULE_REQUEST,
  GET_LIST_SCHEDULE_SUCCESS,
  SET_DATE_OF_VIEW,
} from './Schedule.constant';
import { ScheduleActionsTypes } from './Schedule.type';

export function getListScheduleRequest(query = ''): ScheduleActionsTypes {
  return {
    type: GET_LIST_SCHEDULE_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListScheduleSuccess(
  payload: FullSchedule[],
): ScheduleActionsTypes {
  return {
    type: GET_LIST_SCHEDULE_SUCCESS,
    payload,
  };
}

export function getListScheduleFailure(): ScheduleActionsTypes {
  return {
    type: GET_LIST_SCHEDULE_FAILURE,
  };
}

export const setDateOfView = (date: Dayjs): ScheduleActionsTypes => ({
  type: SET_DATE_OF_VIEW,
  payload: { date },
});
