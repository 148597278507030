import { Button, Col, Row } from 'antd';

import { i18next } from '@i18n';
import { DateHelper, FORMAT_DATE_TIME } from '@utils';

import { Props } from './index.type';

const DeleteCommissionUIActions = ({
  createdAt,
  updatedAt,
  onDelete,
}: Props) => {
  return (
    <Col xs={24} sm={24} md={6}>
      <Row>
        <Col offset={1} style={{ marginBottom: '24px' }}>
          <div>{i18next.t('Date issued')}</div>
          <div>
            {DateHelper.formateDateToString(createdAt, FORMAT_DATE_TIME)}
          </div>
        </Col>

        <Col offset={1} style={{ marginBottom: '24px' }}>
          <div>{i18next.t('Date update')}</div>
          <div>
            {DateHelper.formateDateToString(updatedAt, FORMAT_DATE_TIME)}
          </div>
        </Col>
        <Col offset={1}>
          <Button htmlType="button" danger={true} onClick={onDelete}>
            {i18next.t('Delete')}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default DeleteCommissionUIActions;
