import { SessionStatistic } from '@entities/Statistic';
import { http } from '@utils';

const basePath = '/api/statistics';

export const getSession = async (query = ''): Promise<SessionStatistic[]> => {
  const {
    data: { data },
  } = await http.get<DataResponse<SessionStatistic[]>>(
    `${basePath}/sessions${query}`,
  );

  return data;
};
