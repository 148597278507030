import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullUserAction } from '@entities/UserAction';
import { UserActionHttp } from '@services/http';
import { alertError } from '@store/Alert';

import { getListFailure, getListSuccess } from './UserAction.action';
import { GET_LIST_REQUEST } from './UserAction.constant';
import { IGetListRequest } from './UserAction.type';

function* workerGetList({ payload }: IGetListRequest) {
  try {
    const { query } = payload;

    const data: DataResponse<FullUserAction[]> = yield call(
      UserActionHttp.getList,
      query,
    );

    yield put(getListSuccess(data));
  } catch (error) {
    yield put(getListFailure());
    yield put(alertError(error));
  }
}

function* watchGetList() {
  yield takeLatest(GET_LIST_REQUEST, workerGetList);
}

export const userActionWatchers: ForkEffect[] = [fork(watchGetList)];
