import { ColumnsType } from 'antd/lib/table';

import { FullCommission } from '@entities/Commission';
import { i18next } from '@i18n';
import type { AppDispatch } from '@store/index';
import { UICellLink } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  DateHelper,
  FORMAT_DATE_TIME_WITH_DOT,
} from '@utils';

import { CommissionUIActions } from '../ui/Actions';

export const columns = ({
  dispatch,
  path,
}: {
  dispatch: AppDispatch;
  path: string;
}): ColumnsType<FullCommission> => [
  {
    title: i18next.t('Id'),
    dataIndex: 'id',
    key: 'id',
    render: (value, record) =>
      UICellLink({ value, link: `${path}/${record.id}` }),
    width: COLUMN_WIDTH_ID,
  },
  {
    title: i18next.t('Name'),
    dataIndex: 'name',
    key: 'name',
    width: COLUMN_WIDTH_NAME,
  },
  {
    title: i18next.t('Date issued'),
    dataIndex: 'createdAt',
    key: ' createdAt',
    width: COLUMN_WIDTH_DATE,
    render: (value) =>
      DateHelper.formateDateToString(
        value as string,
        FORMAT_DATE_TIME_WITH_DOT,
      ),
  },
  {
    title: i18next.t('Date update'),
    dataIndex: 'updatedAt',
    key: ' updatedAt',
    width: COLUMN_WIDTH_DATE,
    render: (value) =>
      DateHelper.formateDateToString(
        value as string,
        FORMAT_DATE_TIME_WITH_DOT,
      ),
  },
  {
    title: i18next.t('Actions'),
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    width: COLUMN_WIDTH_ACTIONS,
    render: (id, record) =>
      CommissionUIActions({
        id,
        link: `${path}/${record.id}`,
        dispatch,
      }),
  },
];
