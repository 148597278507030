import { FullChat, FullChatOne } from '@entities/Chat';
import { http } from '@utils';

const basePath = '/api/chats';

export const getList = async (
  query?: string,
): Promise<DataResponse<FullChat[]>> => {
  const { data } = await http.get<DataResponse<FullChat[]>>(
    `${basePath}${query || ''}`,
  );

  return data;
};

export const getOne = async (id: FullChat['id']): Promise<FullChatOne> => {
  const { data } = await http.get<DataResponse<FullChatOne>>(
    `${basePath}/${id}`,
  );

  return data.data;
};

export const remove = async (id: FullChat['id']): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};
