export const GET_ONE_REVIEW_REQUEST = '@review/GET_ONE_REVIEW_REQUEST';
export const GET_ONE_REVIEW_SUCCESS = '@review/GET_ONE_REVIEW_SUCCESS';
export const GET_ONE_REVIEW_FAILURE = '@review/GET_ONE_REVIEW_FAILURE';

export const GET_LIST_REVIEW_REQUEST = '@review/GET_LIST_REVIEW_REQUEST';
export const GET_LIST_REVIEW_SUCCESS = '@review/GET_LIST_REVIEW_SUCCESS';
export const GET_LIST_REVIEW_FAILURE = '@review/GET_LIST_REVIEW_FAILURE';

export const GET_LIST_USER_REQUEST = '@review/GET_LIST_USER_REQUEST';
export const GET_LIST_USER_SUCCESS = '@review/GET_LIST_USER_SUCCESS';
export const GET_LIST_USER_FAILURE = '@review/GET_LIST_USER_FAILURE';

export const CLEAR_LIST_USER = '@review/CLEAR_LIST_USER';

export const CREATE_REVIEW_REQUEST = '@review/CREATE_REVIEW_REQUEST';
export const CREATE_REVIEW_SUCCESS = '@review/CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAILURE = '@review/CREATE_REVIEW_FAILURE';

export const DELETE_REVIEW_REQUEST = '@review/DELETE_REVIEW_REQUEST';
export const DELETE_REVIEW_SUCCESS = '@review/DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = '@review/DELETE_REVIEW_FAILURE';

export const UPDATE_REVIEW_REQUEST = '@review/UPDATE_REVIEW_REQUEST';
export const UPDATE_REVIEW_SUCCESS = '@review/UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAILURE = '@review/UPDATE_REVIEW_FAILURE';
