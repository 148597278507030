import { useEffect } from 'react';

import { Button, Form, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore } from '@store/Media';
import { hideModal } from '@store/Modal';
import {
  clearAvatarStore,
  createUserRequest,
  getOneUserRequest,
  removeAvatar,
  updateUserRequest,
  uploadAvatarRequest,
} from '@store/User';
import { UploadFile } from '@utils';

import WidgetModalUserForm from './Form';
import { FormAngel, FormUser } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalUser = ({ role, userId, query = '' }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormAngel | FormUser>();

  const { user, loadingUser, loadingAvatar, avatar } = useSelector(
    (state: RootState) => state.user,
  );
  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );

  useEffect(() => {
    if (userId) {
      dispatch(getOneUserRequest(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    return () => {
      dispatch(clearAvatarStore());
      dispatch(clearStore());
    };
  }, [dispatch]);

  const closeModal = () => {
    form.resetFields();
    dispatch(hideModal());
  };

  const saveProfile = () => {
    form.submit();
  };

  const onFormSubmitHandler = (values: FormAngel | FormUser) => {
    if (userId) {
      dispatch(
        updateUserRequest({
          id: userId,
          body: values,
          query,
        }),
      );
    } else {
      dispatch(createUserRequest({ body: values, query }));
    }
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadAvatarRequest(file));
  };

  const onRemoveImageHandler = () => {
    dispatch(removeAvatar());
  };

  return (
    <Modal
      visible
      title={i18next.t('Edit profile')}
      onOk={saveProfile}
      onCancel={closeModal}
      footer={[
        <Button
          key="save_user"
          type="primary"
          onClick={saveProfile}
          size="large"
          className="edit-form__save-button"
        >
          {!userId ? i18next.t('Create') : i18next.t('Save changes')}
        </Button>,
      ]}
      className="edit-form"
    >
      <Spin spinning={loadingUser || loadingAvatar || loadingImage}>
        <WidgetModalUserForm
          key="WidgetModalUserForm"
          userId={userId}
          user={userId ? user : null}
          avatar={userId ? avatar || user?.avatar || null : avatar}
          greeting={userId ? fileStore || user?.greeting || null : fileStore}
          form={form}
          role={role}
          onFormSubmit={onFormSubmitHandler}
          onUploadImage={onUploadImageHandler}
          onRemoveImage={onRemoveImageHandler}
        />
      </Spin>
    </Modal>
  );
};

export default WidgetModalUser;
