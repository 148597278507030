import {
  GET_LIST_SPECIALTY_FAILURE,
  GET_LIST_SPECIALTY_REQUEST,
  GET_LIST_SPECIALTY_SUCCESS,
} from './Specialty.constant';
import { ISpecialtyState, SpecialtyActionsTypes } from './Specialty.type';

const initialState: ISpecialtyState = {
  specialtyList: [],
  loading: false,
};

export default function specialtyReducer(
  state = initialState,
  action: SpecialtyActionsTypes,
): ISpecialtyState {
  switch (action.type) {
    case GET_LIST_SPECIALTY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_SPECIALTY_SUCCESS: {
      return {
        ...state,
        specialtyList: action.payload.data,
      };
    }
    case GET_LIST_SPECIALTY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
