import { FormValues } from './index.type';

export const INITIAL_VALUES: FormValues = {
  name: '',
  description: '',
  seo: {
    slug: '',
    title: '',
    description: '',
    keywords: '',
  },
};
