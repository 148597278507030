import {
  AddUpdateCommission,
  DetailCommission,
  FullCommission,
} from '@entities/index';

import {
  ADD_UPDATE_COMMISSION_FAILURE,
  ADD_UPDATE_COMMISSION_REQUEST,
  ADD_UPDATE_COMMISSION_SUCCESS,
  DELETE_COMMISSION_FAILURE,
  DELETE_COMMISSION_REQUEST,
  DELETE_COMMISSION_SUCCESS,
  GET_COMMISSION_FAILURE,
  GET_COMMISSION_REQUEST,
  GET_COMMISSION_SUCCESS,
  GET_LIST_COMMISSION_FAILURE,
  GET_LIST_COMMISSION_REQUEST,
  GET_LIST_COMMISSION_SUCCESS,
  RESET_COMMISSION,
} from './Commission.constant';
import { CommissionActionsTypes } from './Commission.type';

export const getListCommissionRequest = (): CommissionActionsTypes => ({
  type: GET_LIST_COMMISSION_REQUEST,
});

export const getListCommissionSuccess = (
  data: DataResponse<FullCommission[]>,
): CommissionActionsTypes => ({
  type: GET_LIST_COMMISSION_SUCCESS,
  payload: data,
});

export const getListCommissionFailure = (): CommissionActionsTypes => ({
  type: GET_LIST_COMMISSION_FAILURE,
});

export const getCommissionRequest = (
  id: number,
  query: Query['query'],
): CommissionActionsTypes => ({
  type: GET_COMMISSION_REQUEST,
  payload: { id, query },
});

export const getCommissionSuccess = (
  data: DataResponse<DetailCommission>,
): CommissionActionsTypes => ({
  type: GET_COMMISSION_SUCCESS,
  payload: data,
});

export const getCommissionFailure = (): CommissionActionsTypes => ({
  type: GET_COMMISSION_FAILURE,
});

export const addUpdateCommissionRequest = (
  body: AddUpdateCommission,
): CommissionActionsTypes => ({
  type: ADD_UPDATE_COMMISSION_REQUEST,
  payload: body,
});
export const addUpdateCommissionSuccess = (
  data: DataResponse<FullCommission>,
): CommissionActionsTypes => ({
  type: ADD_UPDATE_COMMISSION_SUCCESS,
  payload: data,
});
export const addUpdateCommissionFailure = (): CommissionActionsTypes => ({
  type: ADD_UPDATE_COMMISSION_FAILURE,
});

export const deleteCommissionRequest = (body: {
  id: number;
}): CommissionActionsTypes => ({
  type: DELETE_COMMISSION_REQUEST,
  payload: body,
});
export const deleteCommissionSuccess = (): CommissionActionsTypes => ({
  type: DELETE_COMMISSION_SUCCESS,
});
export const deleteCommissionFailure = (): CommissionActionsTypes => ({
  type: DELETE_COMMISSION_FAILURE,
});

export const resetCommission = (): CommissionActionsTypes => ({
  type: RESET_COMMISSION,
});
