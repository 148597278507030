import { useEffect } from 'react';

import { List, Row } from 'antd';
import { useSelector } from 'react-redux';

import { i18next } from '@i18n';
import { LayoutContainerWhite } from '@layouts';
import { RootState, useAppDispatch } from '@store/index';
import { getSessionRequest } from '@store/Statistic';
import { UIButton } from '@ui';
import { Button, LINK_EXPERT, QueryHelper } from '@utils';

import { Props } from './index.type';

import './style.scss';

const UserDetailFavorite = ({ userId }: Props) => {
  const dispatch = useAppDispatch();
  const { session, loading } = useSelector(
    (state: RootState) => state.statistic,
  );

  useEffect(() => {
    if (userId) {
      dispatch(
        getSessionRequest(
          QueryHelper.stringify({ limit: 5, filter: { user_id: userId } }),
        ),
      );
    }
  }, [dispatch, userId]);

  return (
    <Row>
      <LayoutContainerWhite
        span={24}
        title={i18next.t('List of fave experts')}
        className="expert-container"
      >
        <List
          className="expert-list"
          itemLayout="vertical"
          loading={loading}
          split={false}
          dataSource={session}
          renderItem={(item) => (
            <div key={item.id} className="expert-item">
              <UIButton type={Button.LINK} link={`/${LINK_EXPERT}/${item.id}`}>
                {item.firstName} {item.lastName}
              </UIButton>
              <span className="expert-call">{item.count} calls</span>
            </div>
          )}
        />
      </LayoutContainerWhite>
    </Row>
  );
};

export default UserDetailFavorite;
