import { DeepPartial } from 'redux';

import { FullUser } from '@entities/User';
import { http } from '@utils';

const basePath = '/api/users';

export const getListUser = async (
  query: Query['query'],
): Promise<{
  data: FullUser[];
  meta: MetaType;
}> => {
  const { data } = await http.get<DataResponse<FullUser[]>>(
    `${basePath}${query}`,
  );

  return data;
};

export const getOneUser = async (id: number): Promise<FullUser> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullUser>>(`${basePath}/${id}`);

  return data;
};

export const updateUser = async ({
  id,
  body,
}: {
  body: DeepPartial<FullUser>;
  id: number;
}): Promise<FullUser> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullUser>>(`${basePath}/${id}`, body);

  return data;
};

export const deleteUser = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const createUser = async (
  body: DeepPartial<FullUser>,
): Promise<void> => {
  await http.post(`${basePath}`, body);
};

export const resetUserPassword = async (id: number): Promise<void> => {
  await http.get(`${basePath}/${id}/reset-password`);
};
