import { useEffect } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@hooks';
import { RootState, useAppDispatch } from '@store/index';
import { clearUserListStore, getListUserRequest } from '@store/User';
import { UIHeader, UITable } from '@ui';

import options from './constants';
import { Props } from './index.type';

const User = ({ role }: Props) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { query, updateQuery } = useQuery({ filter: { role } });
  const { userList, meta, loading } = useSelector(
    (state: RootState) => state.user,
  );

  const {
    tab,
    title,
    sortOption,
    viewContainerName,
    headerButtons,
    columns,
    filterOptions,
  } = options({
    dispatch,
    role,
    pathname,
    query,
  });

  useEffect(() => {
    dispatch(getListUserRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearUserListStore());
    };
  }, [dispatch]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <>
      <UIHeader
        title={title}
        isSearch
        buttons={headerButtons}
        sortOption={sortOption}
        filterOptions={filterOptions}
        viewContainerName={viewContainerName}
        tab={tab}
      />
      <Row>
        <UITable
          dataSource={userList}
          columns={columns}
          loading={loading}
          meta={meta}
          onPaginationChange={onPaginationChange}
          isScrollDefault
        />
      </Row>
    </>
  );
};

export default User;
