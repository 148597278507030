import { CreateReviewRequest, FullReview } from '@entities/Review';
import { http } from '@utils';

const basePath = '/api/reviews';

export const getOne = async (
  reviewId: number,
): Promise<DataResponse<FullReview>> => {
  const { data } = await http.get<DataResponse<FullReview>>(
    `${basePath}/${reviewId}`,
  );

  return data;
};

export const getList = async (
  query: string,
): Promise<DataResponse<FullReview[]>> => {
  const { data } = await http.get<DataResponse<FullReview[]>>(
    `${basePath}${query}`,
  );

  return data;
};

export const create = async (data: CreateReviewRequest): Promise<void> => {
  await http.post(basePath, data);
};

export const deleteReview = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const update = async (
  id: number,
  data: Pick<CreateReviewRequest, 'rating' | 'message'>,
): Promise<void> => {
  await http.put(`${basePath}/${id}`, data);
};
