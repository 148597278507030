import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { DetailCommission, FullCommission } from '@entities/index';
import { CommissionHttp } from '@services/http';
import { alertError } from '@store/Alert';
import {
  IAddUpdateCommissionRequest,
  IDeleteCommissionRequest,
  IGetCommissionRequest,
} from '@store/Commission/Commission.type';

import {
  addUpdateCommissionFailure,
  addUpdateCommissionSuccess,
  deleteCommissionFailure,
  deleteCommissionSuccess,
  getCommissionFailure,
  getCommissionSuccess,
  getListCommissionFailure,
  getListCommissionRequest,
  getListCommissionSuccess,
} from './Commission.action';
import {
  ADD_UPDATE_COMMISSION_REQUEST,
  DELETE_COMMISSION_REQUEST,
  GET_COMMISSION_REQUEST,
  GET_LIST_COMMISSION_REQUEST,
} from './Commission.constant';

function* workerGetListCommission() {
  try {
    const res: DataResponse<FullCommission[]> = yield call(
      CommissionHttp.getList,
    );

    yield put(getListCommissionSuccess(res));
  } catch (error) {
    yield put(getListCommissionFailure());
    yield put(alertError(error));
  }
}

function* workerGetCommission({ payload }: IGetCommissionRequest) {
  try {
    const { id, query } = payload;
    const res: DataResponse<DetailCommission> = yield call(
      CommissionHttp.getOneAndUsers,
      id,
      query,
    );

    yield put(getCommissionSuccess(res));
  } catch (error) {
    yield put(getCommissionFailure());
    yield put(alertError(error));
  }
}

function* workerAddUpdateCommission({ payload }: IAddUpdateCommissionRequest) {
  try {
    const res: DataResponse<FullCommission> = yield call(
      CommissionHttp.addUpdate,
      payload,
    );

    yield put(addUpdateCommissionSuccess(res));
  } catch (error) {
    yield put(addUpdateCommissionFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteCommission({ payload }: IDeleteCommissionRequest) {
  try {
    const { id } = payload;

    yield call(CommissionHttp.deleteCommission, id);

    yield put(getListCommissionRequest());
    yield put(deleteCommissionSuccess());
  } catch (error) {
    yield put(deleteCommissionFailure());
    yield put(alertError(error));
  }
}

function* watchGetListCommission() {
  yield takeLatest(GET_LIST_COMMISSION_REQUEST, workerGetListCommission);
}

function* watchGetCommission() {
  yield takeLatest(GET_COMMISSION_REQUEST, workerGetCommission);
}

function* watchAddUpdateCommission() {
  yield takeLatest(ADD_UPDATE_COMMISSION_REQUEST, workerAddUpdateCommission);
}

function* watchDeleteCommission() {
  yield takeLatest(DELETE_COMMISSION_REQUEST, workerDeleteCommission);
}

export const commissionWatchers: ForkEffect[] = [
  fork(watchGetListCommission),
  fork(watchGetCommission),
  fork(watchAddUpdateCommission),
  fork(watchDeleteCommission),
];
