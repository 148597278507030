export const healsRoleList = [
  {
    id: 'Community Psychiatric Nurses',
    name: 'Community Psychiatric Nurses',
  },
  { id: 'CAMHS Practitioners', name: 'CAMHS Practitioners' },
  {
    id: 'Clinical, Counselling or Forensic Psychologists',
    name: 'Clinical, Counselling or Forensic Psychologists',
  },
  {
    id: 'Assistant Psychologists',
    name: 'Assistant Psychologists',
  },
  {
    id: 'Psychotherapists',
    name: 'Psychotherapists',
  },
  {
    id: 'Psychological Wellbeing Practitioners',
    name: 'Psychological Wellbeing Practitioners',
  },
  {
    id: 'Counsellors',
    name: 'Counsellors',
  },
  {
    id: 'Mental Health Social Workers',
    name: 'Mental Health Social Workers',
  },
  {
    id: 'CBT Practitioners',
    name: 'CBT Practitioners',
  },
  {
    id: 'Community Learning Disability Nurses',
    name: 'Community Learning Disability Nurses',
  },
  {
    id: 'Substance Misuse staff (all levels)',
    name: 'Substance Misuse staff (all levels)',
  },
];

export const academicInitialList = [
  {
    id: 'noAcademicInitials',
    name: 'Without initials',
  },
  {
    id: 'JD',
    name: 'JD',
  },
  {
    id: 'DPhil',
    name: 'DPhil',
  },
  {
    id: 'PhD',
    name: 'PhD',
  },
  {
    id: 'LLD',
    name: 'LLD',
  },
  {
    id: 'DCL',
    name: 'DCL',
  },
  {
    id: 'JD',
    name: 'JD',
  },
];
