import { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { UploadFile } from 'antd/lib/upload/interface';
import { useDispatch } from 'react-redux';

import { i18next } from '@i18n';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { UIButton } from '@ui';
import { Button, FileName } from '@utils';

import { Props } from './index.type';

const WidgetModalUIUploadFile = ({
  defaultFile,
  listType,
  formName,
  label,
}: Props) => {
  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultFile) {
      setDefaultFileList([
        {
          uid: `${defaultFile.id}`,
          status: 'done',
          name: defaultFile.name,
          url: defaultFile.path,
        },
      ]);
    } else {
      setDefaultFileList([]);
    }
  }, [defaultFile]);

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setDefaultFileList(fileList);

    if (fileList.length === 0) {
      dispatch(clearStore());
    }
  };

  return (
    <FormItem
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 18 }}
      label={label}
      name={formName}
    >
      <Upload
        maxCount={1}
        listType={listType}
        accept="audio/*, video/*"
        fileList={defaultFileList}
        onChange={handleChange}
        beforeUpload={(file) => {
          dispatch(
            uploadMediaRequest({
              file,
              fileName: /^(video)\//.test(file.type)
                ? FileName.VIDEO
                : FileName.AUDIO,
            }),
          );

          return false;
        }}
      >
        <UIButton
          type={Button.ANT_BUTTON}
          icon={<UploadOutlined />}
          children={i18next.t('Upload')}
        />
      </Upload>
    </FormItem>
  );
};

export default WidgetModalUIUploadFile;
