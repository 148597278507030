import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { CookieStorage } from '@services/storage';
import { IS_DEV } from '@utils';

import { en, ru } from './messages';

export enum LocaleKeys {
  'en' = 'en',
  'ru' = 'ru',
}

export const LOCALE_DEFAULT = LocaleKeys.en;

type ResourceLanguage = Record<string, Record<string, string>>;

const resources: Record<LocaleKeys, ResourceLanguage> = {
  [LocaleKeys.en]: { translation: en },
  [LocaleKeys.ru]: { translation: ru },
};

const initI18n = async () => {
  const languageFromCookie = CookieStorage.getCookieLocaleKey();
  const language = languageFromCookie || LOCALE_DEFAULT;

  await i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: language,
      debug: IS_DEV,
      fallbackLng: LOCALE_DEFAULT,
      saveMissing: true,
      interpolation: {
        escapeValue: false,
      },
    })
    .finally(() => {
      CookieStorage.setCookieLocaleKey(language);
    });
};

initI18n();

export const changeLanguage = async (language: LocaleKeys) => {
  CookieStorage.setCookieLocaleKey(language);
  await i18n.changeLanguage(language);
};

export const getLanguage = () => {
  return i18n.language || LOCALE_DEFAULT;
};

export default i18n;
