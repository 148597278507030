import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import type { FullMedia, FullUser } from '@entities/index';
import { MediaHttp, UserHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';
import { FileName } from '@utils';

import {
  createUserFailure,
  createUserSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  getListUserFailure,
  getListUserRequest,
  getListUserSuccess,
  getOneUserFailure,
  getOneUserSuccess,
  getSearchListUserFailure,
  getSearchListUserSuccess,
  resetUserPasswordFailure,
  resetUserPasswordSuccess,
  updateUserFailure,
  updateUserSuccess,
  uploadAvatarFailure,
  uploadAvatarSuccess,
} from './User.action';
import {
  CREATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  GET_LIST_USER_REQUEST,
  GET_ONE_USER_REQUEST,
  GET_SEARCH_LIST_USER_REQUEST,
  RESET_USER_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
  UPLOAD_AVATAR_REQUEST,
} from './User.constant';
import {
  ICreateUserRequest,
  IDeleteUserRequest,
  IGetListUserRequest,
  IGetOneUserRequest,
  IGetSearchListUserRequest,
  IResetUserPasswordRequest,
  IUpdateUserRequest,
  IUploadAvatarRequest,
} from './User.type';

function* workerGetListUser(action: IGetListUserRequest) {
  try {
    const { query } = action.payload;
    const { data, meta }: { data: FullUser[]; meta: MetaType } = yield call(
      UserHttp.getListUser,
      query,
    );

    yield put(getListUserSuccess({ data, meta }));
  } catch (error) {
    yield put(getListUserFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneUser(action: IGetOneUserRequest) {
  try {
    const { id } = action.payload;
    const user: FullUser = yield call(UserHttp.getOneUser, id);

    yield put(getOneUserSuccess(user));
  } catch (error) {
    yield put(getOneUserFailure());
    yield put(alertError(error));
  }
}

function* workerUploadAvatar({ payload }: IUploadAvatarRequest) {
  try {
    const data: FullMedia = yield call(
      MediaHttp.uploadFile,
      FileName.IMAGE,
      payload.file,
    );

    yield put(uploadAvatarSuccess(data));
    yield put(alertSuccess('Avatar uploaded successfully!'));
  } catch (error) {
    yield put(uploadAvatarFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateUser(action: IUpdateUserRequest) {
  try {
    const { query, ...data } = action.payload;
    const user: FullUser = yield call(UserHttp.updateUser, data);

    yield put(updateUserSuccess(user));
    yield put(alertSuccess('User uploaded successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateUserFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteUser(action: IDeleteUserRequest) {
  try {
    const { id, query } = action.payload;

    yield call(UserHttp.deleteUser, id);

    yield put(deleteUserSuccess());
    yield put(getListUserRequest(query));
    yield put(alertSuccess('User deleted successfully!'));
  } catch (error) {
    yield put(deleteUserFailure());
    yield put(alertError(error));
  }
}

function* workerCreateUser(action: ICreateUserRequest) {
  try {
    const { query, body } = action.payload;

    yield call(UserHttp.createUser, body);

    yield put(createUserSuccess());
    yield put(getListUserRequest(query));
    yield put(alertSuccess('User created successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createUserFailure());
    yield put(alertError(error));
  }
}

function* workerGetSearchListUser(action: IGetSearchListUserRequest) {
  try {
    const { query } = action.payload;
    const data: { data: FullUser[] } = yield call(UserHttp.getListUser, query);

    yield put(getSearchListUserSuccess(data));
  } catch (error) {
    yield put(getSearchListUserFailure());
    yield put(alertError(error));
  }
}

function* workerResetUserPassword(action: IResetUserPasswordRequest) {
  try {
    const { id } = action.payload;

    yield call(UserHttp.resetUserPassword, id);

    yield put(resetUserPasswordSuccess());
    yield put(alertSuccess('A message was sent to e-mail'));
  } catch (error) {
    yield put(resetUserPasswordFailure());
    yield put(alertError(error));
  }
}

function* watchGetListUser() {
  yield takeLatest(GET_LIST_USER_REQUEST, workerGetListUser);
}

function* watchGetOneUser() {
  yield takeLatest(GET_ONE_USER_REQUEST, workerGetOneUser);
}

function* watchUploadAvatar() {
  yield takeLatest(UPLOAD_AVATAR_REQUEST, workerUploadAvatar);
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, workerUpdateUser);
}

function* watchDeleteUser() {
  yield takeLatest(DELETE_USER_REQUEST, workerDeleteUser);
}

function* watchCreateUser() {
  yield takeLatest(CREATE_USER_REQUEST, workerCreateUser);
}

function* watchGetSearchListUser() {
  yield takeLatest(GET_SEARCH_LIST_USER_REQUEST, workerGetSearchListUser);
}

function* watchResetUserPassword() {
  yield takeLatest(RESET_USER_PASSWORD_REQUEST, workerResetUserPassword);
}

export const userWatchers: ForkEffect[] = [
  fork(watchGetListUser),
  fork(watchGetOneUser),
  fork(watchUploadAvatar),
  fork(watchUpdateUser),
  fork(watchDeleteUser),
  fork(watchCreateUser),
  fork(watchGetSearchListUser),
  fork(watchResetUserPassword),
];
