import { useEffect } from 'react';

import { Row, Space, Switch } from 'antd';
import Text from 'antd/lib/typography/Text';
import './style.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { i18next } from '@i18n';
import { LayoutContent } from '@layouts';
import {
  clearCollection,
  deleteCollectionRequest,
  getOneCollectionRequest,
  updateCollectionRequest,
} from '@store/Collection';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIButton, UIHeader } from '@ui';
import { ActionStatus, Button as ButtonType } from '@utils';

import CollectionDetailDiscription from './Discription';
import CollectionDetailExpert from './Expert';
import CollectionDetailSEO from './SEO';

const CollectionDetail = () => {
  const dispatch = useAppDispatch();
  const { status, collection, loading } = useSelector(
    (state: RootState) => state.collection,
  );
  const { collectionId } = useParams<'collectionId'>();

  useEffect(() => {
    if (collectionId) {
      dispatch(getOneCollectionRequest(+collectionId));
    }
  }, [collectionId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearCollection());
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
  }, [status]);

  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CREATE_COLLECTION,
        modalProps: {
          type: 'edit',
          collectionId,
        },
      }),
    );
  };

  const onDeleteHandler = () => {
    if (collectionId) {
      dispatch(deleteCollectionRequest(+collectionId));
    }
  };

  const onChangeVisible = () => {
    if (collectionId) {
      dispatch(
        updateCollectionRequest({
          id: +collectionId,
          isActive: !collection?.isActive,
        }),
      );
    }
  };

  const content = [
    <CollectionDetailExpert />,
    <CollectionDetailSEO
      collection={collection}
      loading={loading}
      onEditClickHandler={onEditClickHandler}
    />,
  ];

  const aside = [
    <Row justify="space-between" align="middle" className="visible-wrapper">
      <Text strong>{i18next.t('Visible')}</Text>
      <Switch checked={collection?.isActive} onChange={onChangeVisible} />
    </Row>,
    <CollectionDetailDiscription
      collection={collection}
      loading={loading}
      onEditClickHandler={onEditClickHandler}
    />,
    <UIButton
      className="delete-collection-button"
      children={i18next.t('Delete Collection')}
      size="middle"
      type={ButtonType.ANT_BUTTON}
      prop={{ danger: true }}
      handler={onDeleteHandler}
    />,
  ];

  return (
    <Space direction="vertical">
      <UIHeader isBack title={collection?.name} />
      <LayoutContent content={content} aside={aside} />
    </Space>
  );
};

export default CollectionDetail;
