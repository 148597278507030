import {
  CouponAmount,
  CouponCodeType,
  CouponCustomer,
  CouponPeriod,
  CouponProduct,
  CouponUsage,
} from '@entities/Coupon';

import { FormCoupon } from './index.type';

export const INITIAL_VALUES: FormCoupon = {
  amount: CouponAmount.ANY,
  code: '',
  codeType: CouponCodeType.PERCENTAGE,
  consultation: null,
  customer: CouponCustomer.ANY,
  maxAmount: null,
  minAmount: null,
  isExpertComission: false,
  period: CouponPeriod.UNLIMIT,
  product: CouponProduct.ALL,
  quantity: null,
  usage: CouponUsage.LIMIT,
  value: 0,
};
