import { Col, Row, Table } from 'antd';
import Title from 'antd/lib/typography/Title';

import { i18next } from '@i18n';

import { columns } from './constant';

import './style.scss';

const UserDetailSupport = () => {
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={5}>{i18next.t('Customer Support Cases')}</Title>
        </Col>
      </Row>
      <Table className="support-wrapper" columns={columns()} dataSource={[]} />
    </div>
  );
};

export default UserDetailSupport;
