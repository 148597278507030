import { Button } from 'antd';

import { i18next } from '@i18n';
import { useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';

import { Props } from './index.type';

const UIFilter = (props: Props) => {
  const dispatch = useAppDispatch();

  const onClickButton = () => {
    dispatch(showModal({ modalType: ModalType.FILTER, modalProps: props }));
  };

  return (
    <Button type="primary" size="large" onClick={onClickButton}>
      {i18next.t('More Filters')}
    </Button>
  );
};

export default UIFilter;
