import { Col, Row } from 'antd';

import { DateHelper, FORMAT_DATE_TIME_WITH_DOT } from '@utils';

import UIUserCard from '../UserCard';
import { Props } from './index.type';
import './style.scss';
import UIOverviewInfo from './Info';

const UIOverview = (props: Props) => {
  const { date, users, infoList, button } = props;

  const renderListHTML = () =>
    users?.map((user, i) => (
      <Col key={`user_${i}`} xxl={12} xl={24} lg={24}>
        <UIUserCard {...user} className="card" />
      </Col>
    ));

  const renderInfoListHTML = () =>
    infoList?.map((item, i) => (
      <UIOverviewInfo {...item} key={`user_info${i}`} />
    ));

  return (
    <div className="overview">
      <Row className="overview-title" justify="space-between" align="middle">
        <div className="overview-text">Overview</div>
        {button}
      </Row>
      <Row className="overview-box" gutter={16} justify="space-between">
        {renderListHTML()}
      </Row>
      {date && (
        <UIOverviewInfo
          name="Date"
          value={DateHelper.formateDateToString(
            date,
            FORMAT_DATE_TIME_WITH_DOT,
          )}
        />
      )}
      {renderInfoListHTML()}
    </div>
  );
};

export default UIOverview;
