import { UIAntDatePicker } from '../Ant';
import { Props } from './index.type';

const { RangePicker } = UIAntDatePicker;

const UICalendar = (props: Props) => {
  const { onChange, placeholder } = props;

  return (
    <RangePicker
      className="ui-calendar"
      placeholder={placeholder}
      onChange={onChange}
      size="large"
    />
  );
};

export default UICalendar;
