/* eslint-disable indent */
import { PlusOutlined } from '@ant-design/icons';
import { Col, Divider, PageHeader, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { useQuery, useView } from '@hooks';
import { i18next } from '@i18n';
import { Button, DateHelper, RangeValue } from '@utils';

import UIButton from '../Button';
import UICalendar from '../Calendar';
import UIFilter from '../Filter';
import UISearch from '../Search';
import UISort from '../Sort';
import UITabFilter from '../Tab/Filter';
import { UIViewButton, UIViewTag } from '../View';
import { Props } from './index.type';

const UIHeader = ({
  title,
  isSearch,
  sortOption,
  filterOptions,
  onAddHandler,
  searchDisabled = false,
  isBack,
  searchTitle,
  buttons,
  viewContainerName,
  isCalendar,
  calendarField,
  calendarPlaceholder,
  tab,
  backLink,
}: Props) => {
  const {
    query,
    updateQuery,
    queryOptions: { filter },
  } = useQuery({}, { encode: true });
  const views = useView({
    viewContainerName,
  });
  const navigate = useNavigate();

  const onChangeHandler = (values: RangeValue<Dayjs>) => {
    if (calendarField) {
      if (!values) {
        updateQuery({
          filter: {
            [calendarField]: null,
          },
        });

        return;
      }

      updateQuery({
        filter: {
          [calendarField]: {
            min: DateHelper.startOfDayToISOString(values[0]),
            max: DateHelper.endOfDayToISOString(values[1]),
          },
        },
      });
    }
  };

  const onSearchChange = (searchStr: string | null) => {
    updateQuery({ filter: { search: searchStr } });
  };

  return (
    <>
      {title && (
        <>
          <PageHeader
            className="page-header"
            title={
              <Title className="page-header__title" level={3}>
                {title}
              </Title>
            }
            {...(isBack && {
              onBack: () => {
                if (backLink) {
                  navigate(backLink);
                } else {
                  window.history.back();
                }
              },
            })}
            extra={buttons}
          />
          {(isSearch || filterOptions || sortOption || viewContainerName) &&
            (tab || views ? (
              <UITabFilter
                tab={tab}
                views={views?.views}
                onApplyView={views?.onApplyView}
                onRemoveView={views?.onRemoveView}
              />
            ) : (
              <Divider />
            ))}
        </>
      )}
      <Row
        className="page-header__buttons"
        justify="space-between"
        gutter={[16, 4]}
      >
        {isSearch && (
          <Col flex="auto">
            <UISearch
              placeholder={searchTitle}
              filter={filter}
              onSearchChange={onSearchChange}
              searchDisabled={searchDisabled}
            />
          </Col>
        )}

        {(filterOptions || sortOption || isCalendar || viewContainerName) && (
          <>
            {isCalendar && (
              <Col>
                <UICalendar
                  placeholder={calendarPlaceholder}
                  onChange={onChangeHandler}
                />
              </Col>
            )}
            {sortOption && (
              <Col>
                <UISort sortOption={sortOption} />
              </Col>
            )}
            {viewContainerName &&
              query &&
              views &&
              (sortOption || filterOptions) && (
                <Col>
                  <UIViewButton
                    onSaveView={views.onSaveView}
                    sortOption={sortOption}
                    filterOptions={filterOptions}
                  />
                </Col>
              )}
            {filterOptions && (
              <Col>
                <UIFilter filterOptions={filterOptions} />
              </Col>
            )}
            {onAddHandler && (
              <UIButton
                icon={<PlusOutlined />}
                children={i18next.t('Add')}
                type={Button.ANT_BUTTON}
                handler={onAddHandler}
              />
            )}
          </>
        )}
        {viewContainerName && (
          <Col span={24}>
            <UIViewTag sortOption={sortOption} filterOptions={filterOptions} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default UIHeader;
