export const CLEAR_FILTER = '@user/CLEAR_FILTER';
export const CLEAR_SORT = '@user/CLEAR_SORT';
export const CREATE_USER_FAILURE = '@user/CREATE_USER_FAILURE';
export const CREATE_USER_REQUEST = '@user/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = '@user/CREATE_USER_SUCCESS';
export const DELETE_USER_FAILURE = '@user/DELETE_USER_FAILURE';
export const DELETE_USER_REQUEST = '@user/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = '@user/DELETE_USER_SUCCESS';
export const GET_LIST_USER_FAILURE = '@user/GET_LIST_USER_FAILURE';
export const GET_LIST_USER_REQUEST = '@user/GET_LIST_USER_REQUEST';
export const GET_LIST_USER_SUCCESS = '@user/GET_LIST_USER_SUCCESS';
export const GET_ONE_USER_FAILURE = '@user/GET_ONE_USER_FAILURE';
export const GET_ONE_USER_REQUEST = '@user/GET_ONE_USER_REQUEST';
export const GET_ONE_USER_SUCCESS = '@user/GET_ONE_USER_SUCCESS';
export const REMOVE_AVATAR = '@user/REMOVE_AVATAR';
export const SET_FILTER = '@user/SET_FILTER';
export const SET_SORT = '@user/SET_SORT';
export const UPDATE_USER_FAILURE = '@user/UPDATE_USER_FAILURE';
export const UPDATE_USER_REQUEST = '@user/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = '@user/UPDATE_USER_SUCCESS';
export const UPLOAD_AVATAR_FAILURE = '@user/UPLOAD_AVATAR_FAILURE';
export const UPLOAD_AVATAR_REQUEST = '@user/UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_SUCCESS = '@user/UPLOAD_AVATAR_SUCCESS';

export const GET_SEARCH_LIST_USER_REQUEST =
  '@user/GET_SEARCH_LIST_USER_REQUEST';
export const GET_SEARCH_LIST_USER_SUCCESS =
  '@user/GET_SEARCH_LIST_USER_SUCCESS';
export const GET_SEARCH_LIST_USER_FAILURE =
  '@user/GET_SEARCH_LIST_USER_FAILURE';

export const RESET_USER_PASSWORD_REQUEST = '@user/RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = '@user/RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = '@user/RESET_USER_PASSWORD_FAILURE';

export const CLEAR_USER_LIST_STORE = '@user/CLEAR_USER_LIST_STORE';
export const CLEAR_USER_ONE_STORE = '@user/CLEAR_USER_ONE_STORE';
export const CLEAR_AVATAR_STORE = '@user/CLEAR_AVATAR_STORE';
export const CLEAR_SEARCH_USER_LIST = '@user/CLEAR_SEARCH_USER_LIST';
