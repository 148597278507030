import { useCallback, useEffect } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType } from '@store/Modal';
import {
  clearListTransaction,
  getListTransactionRequest,
} from '@store/Transaction';
import { UIHeader, UITable } from '@ui';

import Button from '../../../ui/View/Header/Button';
import { columns, filterOptions, sortOption } from '../constant';

const TransactionList = () => {
  const dispatch = useAppDispatch();
  const { query, updateQuery } = useQuery();
  const { transactionList, meta, loading } = useSelector(
    (state: RootState) => state.transaction,
  );

  const getRequest = useCallback(() => {
    dispatch(getListTransactionRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  useEffect(() => {
    return () => {
      dispatch(clearListTransaction());
    };
  }, [dispatch]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <>
      <UIHeader
        title={i18next.t('Transactions from clients')}
        isSearch
        searchTitle={i18next.t('Filter transactions')}
        buttons={
          <div>
            <Button modalType={ModalType.CREATE_TRANSACTION}>
              {i18next.t('Create transaction')}
            </Button>
          </div>
        }
        sortOption={sortOption({ isUser: true })}
        filterOptions={filterOptions}
        viewContainerName={i18next.t('Transactions')}
      />
      <Row>
        <UITable
          dataSource={transactionList}
          loading={loading}
          columns={columns({ isUser: true })}
          meta={meta}
          onPaginationChange={onPaginationChange}
          isScrollDefault
        />
      </Row>
    </>
  );
};

export default TransactionList;
