import { FullSpecialty } from '@entities/Specialty';

import {
  ADD_USER_TO_COLLECTION_FAILURE,
  ADD_USER_TO_COLLECTION_REQUEST,
  ADD_USER_TO_COLLECTION_SUCCESS,
  CLEAR_COLLECTION,
  CLEAR_IMAGE_TO_COLLECTION,
  CREATE_COLLECTION_FAILURE,
  CREATE_COLLECTION_REQUEST,
  CREATE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAILURE,
  DELETE_COLLECTION_REQUEST,
  DELETE_COLLECTION_SUCCESS,
  DELETE_USER_FROM_COLLECTION_FAILURE,
  DELETE_USER_FROM_COLLECTION_REQUEST,
  DELETE_USER_FROM_COLLECTION_SUCCESS,
  GET_LIST_COLLECTION_FAILURE,
  GET_LIST_COLLECTION_REQUEST,
  GET_LIST_COLLECTION_SUCCESS,
  GET_ONE_COLLECTION_FAILURE,
  GET_ONE_COLLECTION_REQUEST,
  GET_ONE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAILURE,
  UPDATE_COLLECTION_REQUEST,
  UPDATE_COLLECTION_SUCCESS,
} from './Collection.constant';
import { CollectionActionsTypes } from './Collection.type';

export function getListCollectionRequest(
  query?: Query['query'],
): CollectionActionsTypes {
  return {
    type: GET_LIST_COLLECTION_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListCollectionSuccess(
  payload: DataResponse<FullSpecialty[]>,
): CollectionActionsTypes {
  return {
    type: GET_LIST_COLLECTION_SUCCESS,
    payload,
  };
}

export function getListCollectionFailure(): CollectionActionsTypes {
  return {
    type: GET_LIST_COLLECTION_FAILURE,
  };
}

export function createCollectionRequest(
  payload: Omit<
    FullSpecialty,
    'numberOfView' | 'numberOfExpert' | 'id' | 'isActive'
  >,
): CollectionActionsTypes {
  return {
    type: CREATE_COLLECTION_REQUEST,
    payload,
  };
}

export function createCollectionSuccess(): CollectionActionsTypes {
  return {
    type: CREATE_COLLECTION_SUCCESS,
  };
}

export function createCollectionFailure(): CollectionActionsTypes {
  return {
    type: CREATE_COLLECTION_FAILURE,
  };
}

export function deleteCollectionRequest(
  id: FullSpecialty['id'],
): CollectionActionsTypes {
  return {
    type: DELETE_COLLECTION_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteCollectionSuccess(): CollectionActionsTypes {
  return {
    type: DELETE_COLLECTION_SUCCESS,
  };
}

export function deleteCollectionFailure(): CollectionActionsTypes {
  return {
    type: DELETE_COLLECTION_FAILURE,
  };
}

export function getOneCollectionRequest(
  id: FullSpecialty['id'],
): CollectionActionsTypes {
  return {
    type: GET_ONE_COLLECTION_REQUEST,
    payload: {
      id,
    },
  };
}

export function getOneCollectionSuccess(
  payload: FullSpecialty,
): CollectionActionsTypes {
  return {
    type: GET_ONE_COLLECTION_SUCCESS,
    payload,
  };
}

export function getOneCollectionFailure(): CollectionActionsTypes {
  return {
    type: GET_ONE_COLLECTION_FAILURE,
  };
}

export function updateCollectionRequest(
  payload: Pick<FullSpecialty, 'id'> &
    Partial<Omit<FullSpecialty, 'numberOfView' | 'numberOfExpert'>>,
): CollectionActionsTypes {
  return {
    type: UPDATE_COLLECTION_REQUEST,
    payload,
  };
}

export function updateCollectionSuccess(): CollectionActionsTypes {
  return {
    type: UPDATE_COLLECTION_SUCCESS,
  };
}

export function updateCollectionFailure(): CollectionActionsTypes {
  return {
    type: UPDATE_COLLECTION_FAILURE,
  };
}

export function addUserToCollectionRequest(
  payload: Id & { query: Query['query']; userId: number[] },
): CollectionActionsTypes {
  return {
    type: ADD_USER_TO_COLLECTION_REQUEST,
    payload,
  };
}

export function addUserToCollectionSuccess(): CollectionActionsTypes {
  return {
    type: ADD_USER_TO_COLLECTION_SUCCESS,
  };
}

export function addUserToCollectionFailure(): CollectionActionsTypes {
  return {
    type: ADD_USER_TO_COLLECTION_FAILURE,
  };
}

export function deleteUserFromCollectionRequest(
  payload: Id & { query: Query['query']; userId: number },
): CollectionActionsTypes {
  return {
    type: DELETE_USER_FROM_COLLECTION_REQUEST,
    payload,
  };
}

export function deleteUserFromCollectionSuccess(): CollectionActionsTypes {
  return {
    type: DELETE_USER_FROM_COLLECTION_SUCCESS,
  };
}

export function deleteUserFromCollectionFailure(): CollectionActionsTypes {
  return {
    type: DELETE_USER_FROM_COLLECTION_FAILURE,
  };
}

export function clearCollection(): CollectionActionsTypes {
  return {
    type: CLEAR_COLLECTION,
  };
}

export function clearImageToCollection(): CollectionActionsTypes {
  return {
    type: CLEAR_IMAGE_TO_COLLECTION,
  };
}
