import { FullTransaction } from '@entities/Transaction';
import { http } from '@utils';

const basePath = '/api/transactions';

export const getList = async (
  query: string,
): Promise<DataResponse<FullTransaction[]>> => {
  const { data } = await http.get<DataResponse<FullTransaction[]>>(
    `${basePath}${query}`,
  );

  return data;
};

export const getOne = async (
  transactionId: number,
): Promise<DataResponse<FullTransaction>> => {
  const { data } = await http.get<DataResponse<FullTransaction>>(
    `${basePath}/${transactionId}`,
  );

  return data;
};

export const create = async (
  payload: any,
): Promise<DataResponse<FullTransaction>> => {
  const { data } = await http.post<DataResponse<FullTransaction>>(
    `${basePath}`,
    payload,
  );

  return data;
};
