import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullReview } from '@entities/Review';
import { FullUser } from '@entities/User';
import { ReviewHttp, UserHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createReviewFailure,
  createReviewSuccess,
  deleteReviewFailure,
  deleteReviewSuccess,
  getListReviewFailure,
  getListReviewRequest,
  getListReviewSuccess,
  getListUserFailure,
  getListUserSuccess,
  getOneReviewFailure,
  getOneReviewSuccess,
  updateReviewFailure,
  updateReviewSuccess,
} from './Review.action';
import {
  CREATE_REVIEW_REQUEST,
  DELETE_REVIEW_REQUEST,
  GET_LIST_REVIEW_REQUEST,
  GET_LIST_USER_REQUEST,
  GET_ONE_REVIEW_REQUEST,
  UPDATE_REVIEW_REQUEST,
} from './Review.constant';
import {
  ICreateReviewRequest,
  IDeleteReviewRequest,
  IGetListReviewRequest,
  IGetListUserRequest,
  IGetOneReviewRequest,
  IUpdateReviewRequest,
} from './Review.type';

function* workerGetOneReview(action: IGetOneReviewRequest) {
  try {
    const { query, reviewId } = action.payload;

    const payload: DataResponse<FullReview> = yield call(
      ReviewHttp.getOne,
      reviewId,
    );

    yield put(getOneReviewSuccess(payload));
    if (query) {
      yield put(getListReviewRequest(query));
    }
  } catch (error) {
    yield put(getOneReviewFailure());
    yield put(alertError(error));
  }
}

function* watchGetOneReview() {
  yield takeLatest(GET_ONE_REVIEW_REQUEST, workerGetOneReview);
}

function* workerGetListReview(action: IGetListReviewRequest) {
  try {
    const { query } = action.payload;

    const payload: DataResponse<FullReview[]> = yield call(
      ReviewHttp.getList,
      query,
    );

    yield put(getListReviewSuccess(payload));
  } catch (error) {
    yield put(getListReviewFailure());
    yield put(alertError(error));
  }
}

function* watchGetListReview() {
  yield takeLatest(GET_LIST_REVIEW_REQUEST, workerGetListReview);
}

function* workerGetListUser(action: IGetListUserRequest) {
  try {
    const { query } = action.payload;
    const payload: DataResponse<FullUser[]> = yield call(
      UserHttp.getListUser,
      query,
    );

    yield put(getListUserSuccess(payload));
  } catch (error) {
    yield put(getListUserFailure());
    yield put(alertError(error));
  }
}

function* watchGetListUser() {
  yield takeLatest(GET_LIST_USER_REQUEST, workerGetListUser);
}

function* workerCreateReview(action: ICreateReviewRequest) {
  try {
    const data = action.payload;

    yield call(ReviewHttp.create, data);

    yield put(createReviewSuccess());
    yield put(getListReviewRequest());
    yield put(hideModal());
  } catch (error) {
    yield put(createReviewFailure());
    yield put(alertError(error));
  }
}

function* watchCreateReview() {
  yield takeLatest(CREATE_REVIEW_REQUEST, workerCreateReview);
}

function* workerDeleteReview(action: IDeleteReviewRequest) {
  try {
    const { id } = action.payload;

    yield call(ReviewHttp.deleteReview, id);

    yield put(deleteReviewSuccess());
  } catch (error) {
    yield put(deleteReviewFailure());
    yield put(alertError(error));
  }
}

function* watchDeleteReview() {
  yield takeLatest(DELETE_REVIEW_REQUEST, workerDeleteReview);
}

function* workerUpdateReview(action: IUpdateReviewRequest) {
  try {
    const { id, ...body } = action.payload;

    yield call(ReviewHttp.update, id, body);

    yield put(updateReviewSuccess());
    yield put(alertSuccess('Review successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateReviewFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateReview() {
  yield takeLatest(UPDATE_REVIEW_REQUEST, workerUpdateReview);
}

export const reviewWatchers: ForkEffect[] = [
  fork(watchGetOneReview),
  fork(watchGetListReview),
  fork(watchGetListUser),
  fork(watchCreateReview),
  fork(watchDeleteReview),
  fork(watchUpdateReview),
];
