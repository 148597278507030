export const GET_LIST_TRANSACTION_REQUEST =
  '@transaction/GET_LIST_TRANSACTION_REQUEST';
export const GET_LIST_TRANSACTION_SUCCESS =
  '@transaction/GET_LIST_TRANSACTION_SUCCESS';
export const GET_LIST_TRANSACTION_FAILURE =
  '@transaction/GET_LIST_TRANSACTION_FAILURE';

export const ADD_TRANSACTION_REQUEST = '@transaction/ADD_TRANSACTION_REQUEST';
export const ADD_TRANSACTION_SUCCESS = '@transaction/ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_FAILURE = '@transaction/ADD_TRANSACTION_FAILURE';

export const CLEAR_LIST_TRANSACTION = '@transaction/CLEAR_LIST_TRANSACTION';
