import './style.scss';

import { Col, Divider } from 'antd';
import Title from 'antd/lib/typography/Title';

import { Props } from './index.type';

const CouponUIItemAdd = ({ title, children }: Props) => {
  return (
    <div className="item-add__wrapper">
      <Col>
        <Title level={5}>{title}</Title>
      </Col>
      <Divider />
      <div className="item-add__content">{children}</div>
    </div>
  );
};

export default CouponUIItemAdd;
