export const GET_PROFILE_REQUEST = '@profile/GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = '@profile/GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = '@profile/GET_PROFILE_FAILURE';

export const CLEAR_PROFILE = '@profile/CLEAR_PROFILE';

export const UPDATE_PROFILE_REQUEST = '@profile/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = '@profile/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = '@profile/UPDATE_PROFILE_FAILURE';

export const UPLOAD_AVATAR_REQUEST = '@profile/UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_SUCCESS = '@profile/UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILURE = '@profile/UPLOAD_AVATAR_FAILURE';

export const REMOVE_AVATAR = '@profile/REMOVE_AVATAR';
