import { CaretDownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Layout, Row, Select, Space } from 'antd';
import { useSelector } from 'react-redux';

import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { LocaleKeys, changeLanguage, getLanguage } from '@i18n';
import { RootState } from '@store/index';
import { UIAvatar } from '@ui';

import { Props } from './index.type';
import WidgetHeaderMenu from './Menu';

import './style.scss';

const WidgetHeader = ({ showProfile }: Props) => {
  const { profile } = useSelector((state: RootState) => state.profile);
  const handleChange = async (lang: string) => {
    await changeLanguage(lang as LocaleKeys);
    window.location.reload();
  };
  const defaultValue = getLanguage();

  return (
    <Layout.Header className="site-header">
      <Row align="middle" justify="space-between">
        <Col className="logo" span={4}>
          <Logo />
          <span className="logo__name">Logotype</span>
          <div className="lang__container">
            <Select
              defaultValue={defaultValue}
              className="lang__select"
              onChange={handleChange}
            >
              <Select.Option key="ru">Рус</Select.Option>
              <Select.Option key="en">Eng</Select.Option>
            </Select>
          </div>
        </Col>
        {showProfile && profile && (
          <>
            <Col className="user">
              <Dropdown overlay={<WidgetHeaderMenu />}>
                <Space>
                  <UIAvatar path={profile.avatar?.path} />
                  <>
                    {profile?.profile?.fullName || ''}
                    <CaretDownOutlined />
                  </>
                </Space>
              </Dropdown>
            </Col>
          </>
        )}
      </Row>
    </Layout.Header>
  );
};

export default WidgetHeader;
