import { useCallback, useEffect, useMemo } from 'react';

import { Spin } from 'antd';
import cn from 'classnames';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getAvailableDays } from '@entities/Schedule';
import { RootState, useAppDispatch } from '@store/index';
import { getListScheduleRequest } from '@store/Schedule';
import { UIAntCalendar } from '@ui';
import { DateHelper, FORMAT_DATE, QueryHelper } from '@utils';

import UserDetailCalendarHeader from './Header';

import './style.scss';

const UserDetailCalendar = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams<'userId'>();
  const { scheduleList, loading } = useSelector(
    (state: RootState) => state.schedule,
  );

  const createRequest = useCallback(
    (date: Date) => {
      if (userId) {
        dispatch(
          getListScheduleRequest(
            QueryHelper.stringify({
              filter: {
                user_id: userId,
                start_date: DateHelper.getStartOfMonthISO(date),
                end_date: DateHelper.getEndOfMonthISO(date),
              },
            }),
          ),
        );
      }
    },
    [dispatch, userId],
  );

  useEffect(() => {
    createRequest(new Date());
  }, [createRequest, dispatch, userId]);

  const availableDays = useMemo(
    () => getAvailableDays(scheduleList),
    [scheduleList],
  );

  const dateFullCellRender = (date: Dayjs) => {
    const isAvailable = Object.prototype.hasOwnProperty.call(
      availableDays,
      DateHelper.formateDateToString(date, FORMAT_DATE),
    );

    return (
      <div
        className={cn('availability-calendar__day', {
          'availability-calendar__day--available': isAvailable,
        })}
      >
        {DateHelper.formateDateToString(date, 'D')}
      </div>
    );
  };

  const onPanelChange = (date: any) => {
    createRequest(date as Date);
  };

  return (
    <div className="psychologist-section-card-calendar-wrapper">
      <Spin spinning={loading}>
        <UIAntCalendar
          className="availability-calendar"
          fullscreen={false}
          dateFullCellRender={dateFullCellRender}
          headerRender={UserDetailCalendarHeader}
          onPanelChange={onPanelChange}
        />
      </Spin>
    </div>
  );
};

export default UserDetailCalendar;
