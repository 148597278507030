import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';

import { useQuery } from '@hooks';
import { LocalStorage } from '@services/storage';
import { Button, StringHelper } from '@utils';

import UIButton from '../../Button';
import { Props } from './index.type';

import './style.scss';

const UITabFilter = ({
  defaultTitle,
  tab,
  views,
  onApplyView,
  onRemoveView,
}: Props) => {
  const { query, queryOptions, setQuery } = useQuery();
  const ref: { current: string | null } = useRef(null);
  const [activeItem, setActiveItem] = useState(defaultTitle || 'tab-All');

  const renderTabs = useMemo(
    () =>
      tab &&
      tab.map(({ label }) => <Tabs.TabPane tab={label} key={`tab-${label}`} />),
    [tab],
  );

  const renderViews = useMemo(
    () =>
      views &&
      onRemoveView &&
      views.map(({ name }) => {
        const currentTab = (
          <span className="header-tab__tab">
            {name}
            <UIButton
              type={Button.ANT_BUTTON}
              typeAntBtn="text"
              size="small"
              className="header-tab__button"
              handler={(e) => {
                ref.current = name;
                e?.preventDefault();
                onRemoveView(name);
              }}
              icon={<DeleteOutlined />}
            />
          </span>
        );

        return <Tabs.TabPane tab={currentTab} key={`view-${name}`} />;
      }),
    [onRemoveView, views],
  );

  useLayoutEffect(() => {
    const lastTab = LocalStorage.get('tab');
    const lastView = LocalStorage.get('view');

    const activeTabs = tab?.filter(({ options }) => {
      const newOptions = StringHelper.parseValuesToString(
        options as { [key: string]: string },
      );

      return JSON.stringify(newOptions) === JSON.stringify(queryOptions || {});
    });

    const currentTab =
      activeTabs?.find(({ label }) => lastTab === label) ||
      (activeTabs && activeTabs[0]);

    if (currentTab) {
      setActiveItem(`tab-${currentTab.label}`);
    } else {
      const activeViews = views?.filter((item) => item.query === query);

      const currentView =
        activeViews?.find(({ name }) => name === lastView) ||
        (activeViews && activeViews[0]);

      if (currentView) {
        setActiveItem(`view-${currentView?.name}`);
      }
    }
  }, [query, views, tab, queryOptions]);

  const onChangeTabHandler = (key: string) => {
    const keyEl = key.split('-');

    if (keyEl[0] === 'tab' && tab) {
      LocalStorage.set('tab', keyEl[1]);
      setActiveItem(`tab-${keyEl[1]}`);
      const item = tab.find(({ label }) => label === keyEl[1]);

      if (item) {
        setQuery(item.options);
      }
    }

    if (keyEl[0] === 'view' && views) {
      LocalStorage.set('view', keyEl[1]);
      setActiveItem(`view-${keyEl[1]}`);
      const item = views.find(({ name }) => name === keyEl[1]);

      if (item && onApplyView && ref.current !== item.name) {
        onApplyView(item.name);
      } else if (onApplyView) {
        const value = views.find(({ name }) => name !== ref.current);

        onApplyView(value?.name || '');
      }
    }
  };

  return (
    <Tabs
      activeKey={activeItem}
      onTabClick={onChangeTabHandler}
      className="header-tab"
    >
      {renderTabs}
      {renderViews}
    </Tabs>
  );
};

export default UITabFilter;
