export const capitalize = (s: string): string => {
  if (typeof s !== 'string') {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getNameByCount = (name: string, count: number) => {
  return count > 1 ? `${name}s ` : `${name} `;
};

export const getValueFromObjectByKey = (
  object: { [key: string]: any },
  path: string,
): any => {
  const fields = path.split('.');

  return fields.reduce((acc, cur) => {
    const val = acc[cur];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return val;
  }, object);
};

export const toMoney = (str?: string | number) => `€${str || 0}`;
export const toPercent = (str?: string | number) => `${str || 0}%`;

export const parseValuesToString = (object: {
  [key: string]: string;
}): { [key: string]: string } | string => {
  const current = object;

  for (const value in current) {
    if (typeof current[value] === 'object' && current[value] !== null) {
      current[value] = parseValuesToString(
        current[value] as unknown as {
          [key: string]: string;
        },
      ) as string;
    } else if (current[value] === undefined || current[value] === null) {
      delete current[value];
    } else {
      current[value] = current[value].toString();
    }
  }

  return object;
};
