import { Form, Radio, Row, Space } from 'antd';

import { i18next } from '@i18n';

import { CouponUIItemAdd } from '../../../ui';

const CouponExpert = () => {
  return (
    <CouponUIItemAdd title={i18next.t('How coupon affects Angel’s comission')}>
      <div className="period-wrapper">
        <Row>
          <Form.Item name="isExpertComission">
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={false}>
                  {i18next.t('Angel gets full payment')}
                </Radio>
                <Radio value={true}>
                  {i18next.t('Angel get’s discounted payment')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Row>
      </div>
    </CouponUIItemAdd>
  );
};

export default CouponExpert;
