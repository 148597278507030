import { META_DEFAULT } from '@utils';

import {
  CLEAR_AVATAR_STORE,
  CLEAR_FILTER,
  CLEAR_SEARCH_USER_LIST,
  CLEAR_SORT,
  CLEAR_USER_LIST_STORE,
  CLEAR_USER_ONE_STORE,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_LIST_USER_FAILURE,
  GET_LIST_USER_REQUEST,
  GET_LIST_USER_SUCCESS,
  GET_ONE_USER_FAILURE,
  GET_ONE_USER_REQUEST,
  GET_ONE_USER_SUCCESS,
  GET_SEARCH_LIST_USER_FAILURE,
  GET_SEARCH_LIST_USER_REQUEST,
  GET_SEARCH_LIST_USER_SUCCESS,
  REMOVE_AVATAR,
  RESET_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  SET_FILTER,
  SET_SORT,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
} from './User.constant';
import { IUserState, UserActionsTypes } from './User.type';

const initialState: IUserState = {
  userList: [],
  meta: META_DEFAULT,
  order: null,
  filter: null,
  user: null,
  avatar: null,
  loadingUser: false,
  loadingAvatar: false,
  loading: false,
  searchUserList: [],
  loadingSearch: false,
};

export default function userReducer(
  state = initialState,
  action: UserActionsTypes,
): IUserState {
  switch (action.type) {
    case GET_LIST_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_USER_SUCCESS: {
      return {
        ...state,
        userList: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      };
    }
    case GET_LIST_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_SORT:
      return {
        ...state,
        order: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case GET_ONE_USER_REQUEST:
    case UPDATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
    case CREATE_USER_REQUEST:
    case RESET_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loadingUser: true,
      };
    case GET_ONE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loadingUser: false,
      };
    case UPDATE_USER_SUCCESS: {
      const { user } = action.payload;

      return {
        ...state,
        user,
        userList: state.userList.map((item) =>
          item.id === user.id ? user : item,
        ),
        loadingUser: false,
      };
    }
    case DELETE_USER_SUCCESS:
    case GET_ONE_USER_FAILURE:
    case UPDATE_USER_FAILURE:
    case DELETE_USER_FAILURE:
    case CREATE_USER_SUCCESS:
    case CREATE_USER_FAILURE:
    case RESET_USER_PASSWORD_SUCCESS:
    case RESET_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loadingUser: false,
      };
    case UPLOAD_AVATAR_REQUEST:
      return {
        ...state,
        loadingAvatar: true,
      };
    case UPLOAD_AVATAR_SUCCESS: {
      if (!state.user) {
        return {
          ...state,
          avatar: action.payload.file,
          loadingAvatar: false,
        };
      }

      return {
        ...state,
        user: { ...state.user, avatar: action.payload.file },
        avatar: action.payload.file,
        loadingAvatar: false,
      };
    }
    case REMOVE_AVATAR: {
      if (!state.user) {
        return { ...state, avatar: null };
      }

      return {
        ...state,
        user: { ...state.user, avatar: null },
        avatar: null,
        loadingAvatar: false,
      };
    }
    case UPLOAD_AVATAR_FAILURE:
      return { ...state, loadingAvatar: false };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: null,
      };
    case CLEAR_SORT:
      return {
        ...state,
        order: null,
      };
    case GET_SEARCH_LIST_USER_REQUEST:
      return { ...state, loadingSearch: true };
    case GET_SEARCH_LIST_USER_SUCCESS:
      return {
        ...state,
        searchUserList: action.payload.data,
        loadingSearch: false,
      };
    case CLEAR_SEARCH_USER_LIST:
      return {
        ...state,
        searchUserList: [],
        loadingSearch: false,
      };
    case GET_SEARCH_LIST_USER_FAILURE:
      return { ...state, loadingSearch: false };
    case CLEAR_USER_LIST_STORE:
      return {
        ...state,
        userList: [],
        meta: META_DEFAULT,
      };
    case CLEAR_USER_ONE_STORE:
      return {
        ...state,
        user: null,
      };
    case CLEAR_AVATAR_STORE:
      return {
        ...state,
        avatar: null,
      };
    default:
      return state;
  }
}
