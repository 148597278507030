export const GET_LIST_COLLECTION_REQUEST =
  '@specialty/GET_LIST_COLLECTION_REQUEST';
export const GET_LIST_COLLECTION_SUCCESS =
  '@specialty/GET_LIST_COLLECTION_SUCCESS';
export const GET_LIST_COLLECTION_FAILURE =
  '@specialty/GET_LIST_COLLECTION_FAILURE';

export const CREATE_COLLECTION_REQUEST = '@specialty/CREATE_COLLECTION_REQUEST';
export const CREATE_COLLECTION_SUCCESS = '@specialty/CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_FAILURE = '@specialty/CREATE_COLLECTION_FAILURE';

export const GET_ONE_COLLECTION_REQUEST =
  '@specialty/GET_ONE_COLLECTION_REQUEST';
export const GET_ONE_COLLECTION_SUCCESS =
  '@specialty/GET_ONE_COLLECTION_SUCCESS';
export const GET_ONE_COLLECTION_FAILURE =
  '@specialty/GET_ONE_COLLECTION_FAILURE';

export const DELETE_COLLECTION_REQUEST = '@specialty/DELETE_COLLECTION_REQUEST';
export const DELETE_COLLECTION_SUCCESS = '@specialty/DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_FAILURE = '@specialty/DELETE_COLLECTION_FAILURE';

export const UPDATE_COLLECTION_REQUEST = '@specialty/UPDATE_COLLECTION_REQUEST';
export const UPDATE_COLLECTION_SUCCESS = '@specialty/UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_FAILURE = '@specialty/UPDATE_COLLECTION_FAILURE';

export const ADD_USER_TO_COLLECTION_REQUEST =
  '@specialty/ADD_USER_TO_COLLECTION_REQUEST';
export const ADD_USER_TO_COLLECTION_SUCCESS =
  '@specialty/ADD_USER_TO_COLLECTION_SUCCESS';
export const ADD_USER_TO_COLLECTION_FAILURE =
  '@specialty/ADD_USER_TO_COLLECTION_FAILURE';

export const DELETE_USER_FROM_COLLECTION_REQUEST =
  '@specialty/DELETE_USER_FROM_COLLECTION_REQUEST';
export const DELETE_USER_FROM_COLLECTION_SUCCESS =
  '@specialty/DELETE_USER_FROM_COLLECTION_SUCCESS';
export const DELETE_USER_FROM_COLLECTION_FAILURE =
  '@specialty/DELETE_USER_FROM_COLLECTION_FAILURE';

export const CLEAR_COLLECTION = '@specialty/CLEAR_COLLECTION';
export const CLEAR_IMAGE_TO_COLLECTION = '@specialty/CLEAR_IMAGE_TO_COLLECTION';
