import { useEffect, useMemo } from 'react';

import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserProps } from '@entities/User';
import { i18next } from '@i18n';
import { LayoutContent } from '@layouts';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { deleteReviewRequest, getOneReviewRequest } from '@store/Review';
import { UIButton, UIHeader, UIOverview } from '@ui';
import { ActionStatus, Button, Role } from '@utils';

import ReviewDetailItem from './Item';

import './style.scss';

const ReviewDetail = () => {
  const { reviewId } = useParams<'reviewId'>();
  const dispatch = useAppDispatch();

  const { review, status } = useSelector((state: RootState) => state.review);

  useEffect(() => {
    if (reviewId) {
      dispatch(
        getOneReviewRequest({
          reviewId: +reviewId,
        }),
      );
    }
  }, [dispatch, reviewId]);

  const users: UserProps[] = useMemo(() => {
    if (!review) {
      return [];
    }

    const { angel, user } = review;

    return [
      {
        id: angel.id,
        role: Role.ANGEL,
        firstName: angel.profile.firstName,
        lastName: angel.profile.lastName,
        avatar: angel.avatar?.path,
      },
      {
        id: user.id,
        role: Role.USER,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        avatar: user.avatar?.path,
      },
    ];
  }, [review]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
    if (reviewId && status === ActionStatus.UPDATED) {
      dispatch(
        getOneReviewRequest({
          reviewId: +reviewId,
        }),
      );
    }
  }, [dispatch, reviewId, status]);

  const onDeleteReviewHandler = () => {
    if (reviewId) {
      dispatch(
        showModal({
          modalType: ModalType.CONFIRM,
          modalProps: {
            content: (
              <p>{i18next.t('Are you sure you want to delete the review?')}</p>
            ),
            action: () => {
              dispatch(
                deleteReviewRequest({
                  id: +reviewId,
                }),
              );
            },
          },
        }),
      );
    }
  };

  const aside = (
    <>
      <UIOverview users={users} date={review?.createdAt} />
      <Row>
        <Col span={24}>
          <UIButton
            children={i18next.t('Delete review')}
            type={Button.ANT_BUTTON}
            prop={{ danger: true, block: true }}
            handler={onDeleteReviewHandler}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <UIHeader title={i18next.t('Review')} isBack />
      <LayoutContent
        content={<ReviewDetailItem className="review-detail" />}
        aside={aside}
      />
    </>
  );
};

export default ReviewDetail;
