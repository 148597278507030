import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import cn from 'classnames';

import { Props } from './index.type';

import './style.scss';

const UIDetailRow = (props: Props) => {
  const { title, children, className, isTitleBold } = props;

  return (
    <Row
      className={`${className} user-row`}
      justify="space-between"
      align="middle"
    >
      <Col>
        <Text
          type="secondary"
          className={cn({ 'user-row__title': isTitleBold })}
        >
          {title}
        </Text>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

export default UIDetailRow;
