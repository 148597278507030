/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { EuroCircleOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from 'antd';
import Text from 'antd/lib/typography/Text';

import { CouponAmount } from '@entities/Coupon';
import { i18next } from '@i18n';

import { CouponUIItemAdd } from '../../../ui';
import { Props } from './index.type';

import './style.scss';

const CouponDetailLimitAmount = ({ form }: Props) => {
  const onChange = (e: RadioChangeEvent) => {
    const value = e?.target?.value || CouponAmount.ANY;

    if (value === CouponAmount.ANY) {
      form.setFieldsValue({ minAmount: null, maxAmount: null });
    }

    form.setFieldsValue({ amount: value });
  };

  return (
    <CouponUIItemAdd title={i18next.t('Limit on the amount')}>
      <div className="limit-amount-wrapper">
        <Row>
          <Form.Item name="amount">
            <Radio.Group onChange={onChange}>
              <Space direction="vertical">
                <Radio value={CouponAmount.ANY}>
                  {i18next.t('For any order amount')}
                </Radio>
                <Radio value={CouponAmount.RANGE}>
                  {i18next.t('Cost range')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="limit-amount-price">
              <Row className="limit-amount__title" align="middle">
                <Text type="secondary">{i18next.t('Minimum amount')}</Text>
              </Row>
              <Row align="middle">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.amount !== currentValues.amount ||
                    prevValues.maxAmount !== currentValues.maxAmount
                  }
                >
                  {({ getFieldValue: getValue }) => {
                    const max = getValue('maxAmount');

                    return (
                      <Form.Item
                        name="minAmount"
                        {...(getValue('amount') === CouponAmount.RANGE && {
                          rules: [
                            {
                              required: true,
                            },
                            {
                              min: 1,
                              type: 'number',
                            },
                          ],
                        })}
                      >
                        <InputNumber
                          addonAfter={<EuroCircleOutlined />}
                          {...(getValue('amount') !== CouponAmount.RANGE && {
                            disabled: true,
                          })}
                          min={1}
                          {...(max !== null && { max })}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="limit-amount-price">
              <Row className="limit-amount__title">
                <Text type="secondary">{i18next.t('Maximum amount')}</Text>
              </Row>
              <Row align="middle">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.amount !== currentValues.amount ||
                    prevValues.minAmount !== currentValues.minAmount
                  }
                >
                  {({ getFieldValue: getValue }) => {
                    const min = getValue('minAmount');

                    return (
                      <Form.Item
                        name="maxAmount"
                        {...(getValue('amount') === CouponAmount.RANGE && {
                          rules: [
                            {
                              required: true,
                            },
                            {
                              min: min || 1,
                              type: 'number',
                            },
                          ],
                        })}
                      >
                        <InputNumber
                          addonAfter={<EuroCircleOutlined />}
                          {...(getValue('amount') !== CouponAmount.RANGE && {
                            disabled: true,
                          })}
                          min={min || 1}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </CouponUIItemAdd>
  );
};

export default CouponDetailLimitAmount;
