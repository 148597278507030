import { Role } from '@utils';

import { FormAngel, FormUser } from './index.type';

export const INITIAL_VALUES_ANGEL: FormAngel = {
  avatar: null,
  email: '',
  gender: null,
  username: '',
  role: Role.ANGEL,
  isNotifyEmail: true,
  isNotifyChat: true,
  profile: {
    firstName: '',
    lastName: '',
    healthRole: null,
    academicInitials: null,
  },
  timezone: null,
  language: null,
  languages: [],
  lgbt: false,
  therapies: [],
  qualifications: [],
  specialties: [],
  tariff: null,
  greeting: null,
};

export const INITIAL_VALUES_USER: FormUser = {
  avatar: null,
  email: '',
  role: Role.USER,
  gender: null,
  isNotifyEmail: true,
  isNotifyChat: true,
  profile: {
    firstName: '',
    lastName: '',
  },
  timezone: null,
  language: null,
};
