import { FullUser } from '@entities/User';

export enum CouponCodeType {
  FIX_AMOUNT = 'fixAmount',
  PERCENTAGE = 'percentage',
}

export enum CouponProduct {
  ALL = 'all',
  SPECIFIC = 'specific',
}

export enum CouponUsage {
  LIMIT = 'limit',
  ONE = 'one',
  UNLIMIT = 'unlimit',
}

export enum CouponPeriod {
  SPECIFIC = 'specific',
  UNLIMIT = 'unlimit',
}

export enum CouponAmount {
  ANY = 'any',
  RANGE = 'range',
}

export enum CouponCustomer {
  ANY = 'any',
  GROUP = 'group',
  SPECIFIC = 'specific',
}

export type Coupon = {
  amount: CouponAmount;
  code: string;
  codeType: CouponCodeType;
  consultation?: number[] | null;
  customer: CouponCustomer;
  endDate?: string | null;
  isActive?: boolean;
  isExpertComission: boolean;
  maxAmount?: number | null;
  minAmount?: number | null;
  period: CouponPeriod;
  product: CouponProduct;
  quantity?: number | null;
  startDate?: string | null;
  usage: CouponUsage;
  users?: FullUser[];
  value: number;
};

export type RequestCoupon = Omit<Coupon, 'code' | 'users'> & {
  users?: Id[];
};

export type FullCoupon = Id & Coupon;
