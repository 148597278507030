import { Comment, FullComment } from '@entities/Comment';

import {
  CREATE_COMMENT_FAILURE,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  GET_LIST_COMMENT_FAILURE,
  GET_LIST_COMMENT_REQUEST,
  GET_LIST_COMMENT_SUCCESS,
  REMOVE_COMMENT_FAILURE,
  REMOVE_COMMENT_REQUEST,
  REMOVE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
} from './Comment.constant';
import { CommentActionsTypes } from './Comment.type';

export const getListCommentRequest = (query?: string): CommentActionsTypes => ({
  type: GET_LIST_COMMENT_REQUEST,
  payload: { query },
});

export const getListCommentSuccess = (
  data: DataResponse<FullComment[]>,
): CommentActionsTypes => ({
  type: GET_LIST_COMMENT_SUCCESS,
  payload: data,
});

export const getListCommentFailure = (): CommentActionsTypes => ({
  type: GET_LIST_COMMENT_FAILURE,
});

export const createCommentRequest = (
  body: Comment,
  query?: string,
): CommentActionsTypes => ({
  type: CREATE_COMMENT_REQUEST,
  payload: { body, query },
});

export const createCommentSuccess = (): CommentActionsTypes => ({
  type: CREATE_COMMENT_SUCCESS,
});

export const createCommentFailure = (): CommentActionsTypes => ({
  type: CREATE_COMMENT_FAILURE,
});

export const updateCommentRequest = (
  body: Pick<FullComment, 'id' | 'message'>,
  query?: string,
): CommentActionsTypes => ({
  type: UPDATE_COMMENT_REQUEST,
  payload: { body, query },
});

export const updateCommentSuccess = (): CommentActionsTypes => ({
  type: UPDATE_COMMENT_SUCCESS,
});

export const updateCommentFailure = (): CommentActionsTypes => ({
  type: UPDATE_COMMENT_FAILURE,
});

export const removeCommentRequest = (
  id: FullComment['id'],
  query?: string,
): CommentActionsTypes => ({
  type: REMOVE_COMMENT_REQUEST,
  payload: { id, query },
});

export const removeCommentSuccess = (): CommentActionsTypes => ({
  type: REMOVE_COMMENT_SUCCESS,
});

export const removeCommentFailure = (): CommentActionsTypes => ({
  type: REMOVE_COMMENT_FAILURE,
});
