import { ColumnsType } from 'antd/lib/table';

import { UserCommission } from '@entities/Commission';
import { i18next } from '@i18n';
import { UICellFullName, UICellLink, UICellStatus } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_TAG,
} from '@utils';

import { EditCommissionUIActions } from '../ui/Actions';

const path = 'psychologists';

export const columns = (): ColumnsType<UserCommission> => [
  {
    title: i18next.t('Id'),
    dataIndex: 'id',
    key: 'id',
    render: (value, record) =>
      UICellLink({ value, link: `/${path}/${record.id}` }),
    width: COLUMN_WIDTH_ID,
    fixed: 'left',
  },
  {
    title: i18next.t('Full Name'),
    dataIndex: ['avatar', 'fullName'],
    key: 'fullName',
    width: COLUMN_WIDTH_NAME,
    render: (value, { lastName, firstName, id, avatar }) =>
      UICellFullName({
        lastName,
        firstName,
        src: avatar?.path,
        link: `/${path}/${id}`,
      }),
    fixed: 'left',
  },
  {
    title: i18next.t('Active'),
    dataIndex: 'isActive',
    key: 'isActive',
    width: COLUMN_WIDTH_TAG,
    render: (value) =>
      UICellStatus({
        value,
        text: value ? i18next.t('Active') : i18next.t('Inactive'),
      }),
  },
  {
    title: i18next.t('Actions'),
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    width: COLUMN_WIDTH_ACTIONS,
    render: (id, user) =>
      EditCommissionUIActions({
        link: `/${path}/${user.id}`,
      }),
  },
];
