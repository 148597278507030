import {
  GET_LIST_THERAPY_FAILURE,
  GET_LIST_THERAPY_REQUEST,
  GET_LIST_THERAPY_SUCCESS,
} from './Therapy.constant';
import { ITherapyState, TherapyActionsTypes } from './Therapy.type';

const initialState: ITherapyState = {
  therapyList: [],
  loading: false,
};

export default function therapyReducer(
  state = initialState,
  action: TherapyActionsTypes,
): ITherapyState {
  switch (action.type) {
    case GET_LIST_THERAPY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_THERAPY_SUCCESS: {
      return {
        ...state,
        therapyList: action.payload.data,
      };
    }
    case GET_LIST_THERAPY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
