import { Menu } from 'antd';

import { UIButton } from '@ui';
import { Button } from '@utils';

import { Props } from './index.type';

const LayoutMenu = ({ buttons }: Props) => {
  const renderButton = () =>
    buttons?.map((item, index) => (
      <UIButton
        key={`button_${index}`}
        handler={item.onClick}
        typeAntBtn="link"
        prop={{ danger: true }}
        type={Button.ANT_BUTTON}
      >
        {item.title}
      </UIButton>
    ));

  return (
    <Menu>
      <Menu.Item>{renderButton()}</Menu.Item>
    </Menu>
  );
};

export default LayoutMenu;
