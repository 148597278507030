import { i18next } from '@i18n';
import { LayoutMenu } from '@layouts';
import { deleteCollectionRequest } from '@store/Collection';
import { ModalType, showModal } from '@store/Modal';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const CollectionUIActionList = ({ id, dispatch }: Props) => {
  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CREATE_COLLECTION,
        modalProps: { collectionId: id },
      }),
    );
  };

  const onDeleteCollectionHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: <p>Are you sure you want to delete the Collection</p>,
          action: () => dispatch(deleteCollectionRequest(id)),
        },
      }),
    );
  };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [
        { title: i18next.t('Delete'), onClick: onDeleteCollectionHandler },
      ],
    }),
    buttons: [{ title: i18next.t('Edit'), onClick: onEditClickHandler }],
  });
};

export default CollectionUIActionList;
