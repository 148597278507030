import { Route, Routes } from 'react-router-dom';

import { CouponDetail, CouponList } from '@modules/Coupon';
import { LINK_COUPON_ADD } from '@utils';

const ViewCoupon = () => {
  return (
    <Routes>
      <Route path=":couponId" element={<CouponDetail />} />
      <Route path="/" element={<CouponList />} />
      <Route path={LINK_COUPON_ADD} element={<CouponDetail />} />
    </Routes>
  );
};

export default ViewCoupon;
