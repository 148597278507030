import { FullSchedule } from '@entities/Schedule';
import { http } from '@utils';

const basePath = '/api/schedule';

export const getList = async (query = ''): Promise<FullSchedule[]> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullSchedule[]>>(`${basePath}${query}`);

  return data;
};
