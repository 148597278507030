export const GET_LIST_COMMISSION_REQUEST =
  '@comment/GET_LIST_COMMISSION_REQUEST';
export const GET_LIST_COMMISSION_SUCCESS =
  '@comment/GET_LIST_COMMISSION_SUCCESS';
export const GET_LIST_COMMISSION_FAILURE =
  '@comment/GET_LIST_COMMISSION_FAILURE';

export const GET_COMMISSION_REQUEST = '@comment/GET_COMMISSION_REQUEST';
export const GET_COMMISSION_SUCCESS = '@comment/GET_COMMISSION_SUCCESS';
export const GET_COMMISSION_FAILURE = '@comment/GET_COMMISSION_FAILURE';

export const ADD_UPDATE_COMMISSION_REQUEST =
  '@comment/ADD_UPDATE_COMMISSION_REQUEST';
export const ADD_UPDATE_COMMISSION_SUCCESS =
  '@comment/ADD_UPDATE_COMMISSION_SUCCESS';
export const ADD_UPDATE_COMMISSION_FAILURE =
  '@comment/ADD_UPDATE_COMMISSION_FAILURE';

export const DELETE_COMMISSION_REQUEST = '@comment/DELETE_COMMISSION_REQUEST';
export const DELETE_COMMISSION_SUCCESS = '@comment/DELETE_COMMISSION_SUCCESS';
export const DELETE_COMMISSION_FAILURE = '@comment/DELETE_COMMISSION_FAILURE';

export const RESET_COMMISSION = '@comment/RESET_COMMISSION';
