import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import cn from 'classnames';

import { Props } from './index.type';

import './style.scss';

const LayoutContainerWhite = (props: Props) => {
  const {
    title,
    span,
    flex,
    offset,
    titleSize = 'normal',
    children,
    className,
    value,
  } = props;

  return (
    <Col
      span={span}
      flex={flex}
      className="layout-container__white"
      offset={offset}
    >
      <div className={cn('white__wrap', className)}>
        <Row justify="space-between" align="middle">
          <Col>
            <Text strong className={`white__title--${titleSize}`}>
              {title}
            </Text>
          </Col>
          {value && (
            <Col>
              <Text strong className="white__value">
                {value}
              </Text>
            </Col>
          )}
        </Row>
        {children}
      </div>
    </Col>
  );
};

export default LayoutContainerWhite;
