import { combineReducers } from 'redux';

import { AlertReducer } from './Alert';
import { AuthReducer } from './Auth';
import { BookingReducer } from './Booking';
import { ChatReducer } from './Chat';
import { CollectionReducer } from './Collection';
import { CommentReducer } from './Comment';
import { CommissionReducer } from './Commission';
import { CouponReducer } from './Coupon';
import LanguageReducer from './Language/Language.reducer';
import { MediaReducer } from './Media';
import { ModalReducer } from './Modal';
import PaymentReducer from './Payment/Payment.reducer';
import { ProfileReducer } from './Profile';
import { ReviewReducer } from './Review';
import { ScheduleReducer } from './Schedule';
import SpecialtyReducer from './Specialty/Specialty.reducer';
import { StatisticReducer } from './Statistic';
import TherapyReducer from './Therapy/Therapy.reducer';
import { TimezoneReducer } from './Timezone';
import { TransactionReducer } from './Transaction';
import { UserReducer } from './User';
import { UserActionReducer } from './UserAction';

export const rootReducer = combineReducers({
  alert: AlertReducer,
  chat: ChatReducer,
  auth: AuthReducer,
  profile: ProfileReducer,
  user: UserReducer,
  modal: ModalReducer,
  collection: CollectionReducer,
  coupon: CouponReducer,
  timezone: TimezoneReducer,
  review: ReviewReducer,
  booking: BookingReducer,
  schedule: ScheduleReducer,
  specialty: SpecialtyReducer,
  statistic: StatisticReducer,
  transaction: TransactionReducer,
  therapy: TherapyReducer,
  comment: CommentReducer,
  userAction: UserActionReducer,
  language: LanguageReducer,
  media: MediaReducer,
  payment: PaymentReducer,
  commission: CommissionReducer,
});
