import { i18next } from '@i18n';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const EditCommissionUIActions = ({ link }: Props) => {
  return UICellAction({
    buttons: [{ title: i18next.t('Edit'), href: link }],
  });
};

export default EditCommissionUIActions;
