import type { FullMedia } from '@entities/index';
import { FileName, UploadFile } from '@utils';

import {
  CLEAR_STOR,
  UPLOAD_MEDIA_FAILURE,
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_SUCCESS,
} from './Media.constant';
import { MediaActionsTypes } from './Media.type';

export function uploadMediaRequest(payload: {
  file: UploadFile['file'];
  fileName: FileName;
}): MediaActionsTypes {
  return {
    type: UPLOAD_MEDIA_REQUEST,
    payload,
  };
}

export function uploadMediaSuccess(media: FullMedia): MediaActionsTypes {
  return {
    type: UPLOAD_MEDIA_SUCCESS,
    payload: { file: media },
  };
}

export function uploadMediaFailure(): MediaActionsTypes {
  return {
    type: UPLOAD_MEDIA_FAILURE,
  };
}

export function clearStore(): MediaActionsTypes {
  return {
    type: CLEAR_STOR,
  };
}
