import { ColumnsType } from 'antd/lib/table';

import { FullUser } from '@entities/User';
import { i18next } from '@i18n';
import type { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink, UICellRate, UICellStatus } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_TAG,
  DateHelper,
  FORMAT_SHORT_DATE,
  Role,
} from '@utils';

import { UserUIActions, UserUIButton } from '../ui';
import angelConstants from './angel.constants';
import userConstants from './user.constants';

const columns = ({
  pathname,
  role,
  query,
  dispatch,
}: {
  dispatch: AppDispatch;
  pathname: string;
  query: string;
  role: Role.USER | Role.ANGEL;
}): ColumnsType<FullUser> => {
  const userFields: ColumnsType<FullUser> = [
    {
      title: i18next.t('Money Spent'),
      dataIndex: 'moneySpent',
      key: 'moneySpent',
      width: COLUMN_WIDTH_NUMBER,
      render: (value) => `€${value || 0}`,
    },
    {
      title: i18next.t('Visit Count'),
      dataIndex: 'visitCount',
      key: 'visitCount',
      width: COLUMN_WIDTH_NUMBER,
    },
  ];

  const angelFields: ColumnsType<FullUser> = [
    {
      title: i18next.t('Platform commission'),
      dataIndex: 'tariff',
      key: 'tariff',
      render: (value: { commission: { amount: number } }) =>
        `${value?.commission?.amount}%`,
      width: COLUMN_WIDTH_NUMBER,
    },
    {
      title: i18next.t('Money Earned'),
      dataIndex: 'moneyEarned',
      key: 'moneyEarned',
      render: (value) => `€${value || 0}`,
      width: COLUMN_WIDTH_NUMBER,
    },
    {
      title: i18next.t('Favorite Count'),
      dataIndex: 'favoriteCount',
      key: 'favoriteCount',
      width: COLUMN_WIDTH_NUMBER,
    },
    {
      title: i18next.t('Visit Count'),
      dataIndex: 'visitCount',
      key: 'visitCount',
      width: COLUMN_WIDTH_NUMBER,
    },
    {
      title: i18next.t('Rating'),
      dataIndex: 'rating',
      key: 'rating',
      width: COLUMN_WIDTH_NUMBER,
      render: (value, { rating, votes }) =>
        UICellRate({ allowHalf: false, rating, votes, disabled: true }),
    },
  ];

  return [
    {
      title: i18next.t('Id'),
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        UICellLink({ value, link: `${pathname}/${record.id}` }),
      width: COLUMN_WIDTH_ID,
      fixed: 'left',
    },
    {
      title: i18next.t('Full Name'),
      dataIndex: ['profile', 'fullName'],
      key: 'fullName',
      width: COLUMN_WIDTH_NAME,
      render: (value, { profile, id, avatar }) =>
        UICellFullName({
          lastName: profile?.lastName,
          firstName: profile?.firstName,
          src: avatar?.path,
          link: `${pathname}/${id}`,
        }),
      fixed: 'left',
    },
    {
      title: i18next.t('Active'),
      dataIndex: 'isActive',
      key: 'isActive',
      width: COLUMN_WIDTH_TAG,
      render: (value) =>
        UICellStatus({
          value,
          text: value ? i18next.t('Active') : i18next.t('Inactive'),
        }),
    },
    {
      title: i18next.t('Reg Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_SHORT_DATE),
    },
    {
      title: i18next.t('Booking Count'),
      dataIndex: 'bookingCount',
      key: 'bookingCount',
      width: COLUMN_WIDTH_NUMBER,
    },
    ...(role === Role.USER ? userFields : angelFields),
    {
      title: i18next.t('Actions'),
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      width: COLUMN_WIDTH_ACTIONS,
      render: (id, user) =>
        UserUIActions({
          id,
          user,
          query,
          role,
          dispatch,
        }),
    },
  ];
};

const options = ({
  role,
  pathname,
  query,
  dispatch,
}: {
  dispatch: AppDispatch;
  pathname: string;
  query: string;
  role: Role.ANGEL | Role.USER;
}) => ({
  ...(role === Role.USER ? userConstants : angelConstants),
  headerButtons: UserUIButton({ role }),
  columns: columns({ dispatch, role, pathname, query }),
});

export default options;
