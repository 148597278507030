import { Button, Space, Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import { i18next } from '@i18n';
import { UIAvatar, UIImage } from '@ui';

import { Props } from './index.type';

const WidgetModalUIImage = ({
  name = 'avatar',
  view,
  path,
  onUploadImage,
  onRemoveImage,
}: Props) => {
  return (
    <FormItem name={name}>
      <Space direction="horizontal" wrap={false}>
        {view === 'avatar' ? (
          <UIAvatar path={path} className="user__avatar" size="large" />
        ) : (
          <UIImage path={path} />
        )}
        <Upload
          accept=".jpg, .jpeg, .png"
          showUploadList={false}
          beforeUpload={(file) => {
            onUploadImage(file);

            return false;
          }}
        >
          <Button
            key="Save changes"
            type="primary"
            size="large"
            className="edit-form__save-photo"
          >
            {i18next.t('Upload image')}
          </Button>
        </Upload>
        {path && path !== 'error' && (
          <Button
            key="Save changes"
            type="link"
            danger
            onClick={onRemoveImage}
            size="large"
          >
            {i18next.t('Remove image')}
          </Button>
        )}
      </Space>
    </FormItem>
  );
};

export default WidgetModalUIImage;
