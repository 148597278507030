import { DateHelper, FORMAT_DATE } from '@utils';

import { FullSchedule } from '../model';

export const getAvailableDays = (arr: FullSchedule[]) => {
  const days: { [key: string]: number } = arr.reduce(
    (acc: { [key: string]: number }, item) => {
      const day = DateHelper.formateDateToString(item.startDate, FORMAT_DATE);

      if (item.isAvailable) {
        if (Object.prototype.hasOwnProperty.call(acc, day)) {
          acc[day] += 1;
        } else {
          acc[day] = 1;
        }
      }

      return acc;
    },
    {},
  );

  return days;
};
