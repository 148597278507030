import { useEffect, useState } from 'react';

import { i18next } from '@i18n';
import { addUserToCollectionRequest } from '@store/Collection';
import { useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';
import { getSearchListUserSuccess } from '@store/User';
import { UIButton, UIModal } from '@ui';
import { Button } from '@utils';

import WidgetModalUIPeople from '../ui/People';
import { Props } from './index.type';

import './style.scss';

const WidgetModalAddUserToCollection = ({
  collectionId,
  query,
  params,
}: Props) => {
  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState<string[]>([]);

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const addPsychologist = () => {
    dispatch(
      addUserToCollectionRequest({
        id: +collectionId,
        userId: selectedId.map((i) => +i),
        query,
      }),
    );
    handleCancel();
  };

  useEffect(() => {
    return () => {
      dispatch(getSearchListUserSuccess({ data: [] }));
    };
  }, [dispatch]);

  const footer = [
    <UIButton
      key="Save changes"
      handler={addPsychologist}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {i18next.t('Add Psychologist')}
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      footer={footer}
      title={i18next.t('Add psychologist to the collection')}
      visible
    >
      <WidgetModalUIPeople onChange={setSelectedId} params={params} />
    </UIModal>
  );
};

export default WidgetModalAddUserToCollection;
