import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Collapse, Modal } from 'antd';

import {
  FilterNumberRange,
  FilterOption,
  FilterRadioOption,
  FilterRange,
  FullFilter,
} from '@entities/Filter';
import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';

import WidgetModalFilterCheckbox from './Checkbox';
import { Props } from './index.type';
import WidgetModalFilterNumber from './Number';
import WidgetModalFilterNumberRange from './NumberRange';
import WidgetModalFilterPeople from './People';
import WidgetModalFilterRadio from './Radio';
import WidgetModalFilterRadioRange from './RadioRange';

import './style.scss';

const { Panel } = Collapse;

const WidgetModalFilter = ({ filterOptions }: Props) => {
  const dispatch = useAppDispatch();
  const {
    queryOptions: { filter },
    updateQuery,
  } = useQuery();

  const [filtersState, setFiltersState] = useState<FullFilter | null>(null);

  useEffect(() => {
    if (filter) {
      setFiltersState(filter);
    }
  }, [filter]);

  const closeModal = () => {
    dispatch(hideModal());
  };

  const submitFilter = () => {
    updateQuery({ filter: filtersState });
    closeModal();
  };

  const resetFilter = () => {
    updateQuery({ filter: null });
    closeModal();
  };

  const onFilterUpdateHandler = (currentFilter: FullFilter) => {
    setFiltersState((prevValue) => ({ ...prevValue, ...currentFilter }));
  };

  const value = useCallback(
    (name: string) => {
      if (
        filtersState &&
        Object.prototype.hasOwnProperty.call(filtersState, name)
      ) {
        return filtersState[name];
      }

      return null;
    },
    [filtersState],
  );

  const returnElement = useCallback(
    (currentFilter: FilterOption) => {
      switch (currentFilter.type) {
        case 'number':
          return (
            <WidgetModalFilterNumber
              {...currentFilter}
              filterValue={value(currentFilter.name) as FilterNumberRange}
              onFilterUpdate={(newValue) =>
                onFilterUpdateHandler({ [currentFilter.name]: newValue })
              }
            />
          );
        case 'number-range':
          return (
            <WidgetModalFilterNumberRange
              {...currentFilter}
              filterValue={value(currentFilter.name) as FilterNumberRange}
              onFilterUpdate={(newValue) =>
                onFilterUpdateHandler({ [currentFilter.name]: newValue })
              }
            />
          );
        case 'radio':
          return (
            <WidgetModalFilterRadio
              {...currentFilter}
              filterValue={value(currentFilter.name) as FilterRadioOption}
              onFilterUpdate={(newValue) =>
                onFilterUpdateHandler({ [currentFilter.name]: newValue })
              }
            />
          );
        case 'radio-range':
          return (
            <WidgetModalFilterRadioRange
              {...currentFilter}
              filterValue={value(currentFilter.name) as FilterRange}
              onFilterUpdate={(newValue) =>
                onFilterUpdateHandler({ [currentFilter.name]: newValue })
              }
            />
          );
        case 'people':
          return (
            <WidgetModalFilterPeople
              {...currentFilter}
              filterValue={value(currentFilter.name) as string[]}
              onFilterUpdate={(newValue) =>
                onFilterUpdateHandler({ [currentFilter.name]: newValue })
              }
            />
          );
        case 'checkbox':
          return (
            <WidgetModalFilterCheckbox
              {...currentFilter}
              filterValue={value(currentFilter.name) as string[]}
              onFilterUpdate={(newValue) =>
                onFilterUpdateHandler({ [currentFilter.name]: newValue })
              }
            />
          );

        default:
          return null;
      }
    },
    [value],
  );

  const renderPanel = useMemo(
    () =>
      filterOptions.map((currentFilter, id) => (
        <Panel header={currentFilter.label} key={`panel_${id}`}>
          {returnElement(currentFilter)}
        </Panel>
      )),
    [filterOptions, returnElement],
  );

  const footer = [
    <Button key="clearAll" onClick={resetFilter}>
      {i18next.t('Clear all filters')}
    </Button>,
    <Button key="done" type="primary" onClick={submitFilter}>
      {i18next.t('Done')}
    </Button>,
  ];

  return (
    <Modal
      visible
      title={i18next.t('More Filters')}
      onOk={submitFilter}
      onCancel={closeModal}
      footer={footer}
    >
      <Collapse
        defaultActiveKey={['0']}
        bordered={false}
        ghost
        accordion={true}
      >
        {renderPanel}
      </Collapse>
    </Modal>
  );
};

export default WidgetModalFilter;
