import {
  CLEAR_PROFILE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  REMOVE_AVATAR,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
} from './Profile.constant';
import { IProfileState, ProfileActionsTypes } from './Profile.type';

const initialState: IProfileState = {
  profile: null,
  loading: false,
};

export default function profileReducer(
  state = initialState,
  action: ProfileActionsTypes,
): IProfileState {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case UPLOAD_AVATAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.user,
      };

    case GET_PROFILE_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case UPLOAD_AVATAR_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
      };
    case UPLOAD_AVATAR_SUCCESS: {
      if (state.profile) {
        return {
          ...state,
          profile: { ...state.profile, avatar: action.payload.file },
          loading: false,
        };
      }

      return state;
    }
    case REMOVE_AVATAR: {
      if (state.profile) {
        return {
          ...state,
          profile: { ...state.profile, avatar: null },
        };
      }

      return state;
    }
    default:
      return state;
  }
}
