import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_COLLECTION,
  CLEAR_IMAGE_TO_COLLECTION,
  CREATE_COLLECTION_FAILURE,
  CREATE_COLLECTION_REQUEST,
  CREATE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_SUCCESS,
  GET_LIST_COLLECTION_FAILURE,
  GET_LIST_COLLECTION_REQUEST,
  GET_LIST_COLLECTION_SUCCESS,
  GET_ONE_COLLECTION_REQUEST,
  GET_ONE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAILURE,
  UPDATE_COLLECTION_REQUEST,
  UPDATE_COLLECTION_SUCCESS,
} from './Collection.constant';
import { CollectionActionsTypes, ICollectionState } from './Collection.type';

const initialState: ICollectionState = {
  status: ActionStatus.NONE,
  collectionList: [],
  meta: META_DEFAULT,
  collection: null,
  loading: false,
};

export default function collectionReducer(
  state = initialState,
  action: CollectionActionsTypes,
): ICollectionState {
  switch (action.type) {
    case GET_LIST_COLLECTION_REQUEST:
    case GET_ONE_COLLECTION_REQUEST:
      return {
        ...state,
        status: ActionStatus.NONE,
        loading: true,
      };

    case GET_LIST_COLLECTION_SUCCESS:
      return {
        ...state,
        collectionList: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_COLLECTION_FAILURE:
    case UPDATE_COLLECTION_FAILURE:
    case CREATE_COLLECTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_COLLECTION_REQUEST:
    case UPDATE_COLLECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COLLECTION_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
      };
    }
    case UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
      };
    case DELETE_COLLECTION_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
      };
    }
    case GET_ONE_COLLECTION_SUCCESS: {
      return {
        ...state,
        collection: action.payload,
        loading: false,
      };
    }
    case CLEAR_COLLECTION: {
      return {
        ...state,
        status: ActionStatus.NONE,
        collection: null,
      };
    }
    case CLEAR_IMAGE_TO_COLLECTION: {
      return {
        ...state,
        ...(state.collection && {
          collection: { ...state.collection, image: null },
        }),
      };
    }
    default:
      return state;
  }
}
