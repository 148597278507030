import { CreateReviewRequest, FullReview } from '@entities/Review';
import { FullUser } from '@entities/User';

import {
  CLEAR_LIST_USER,
  CREATE_REVIEW_FAILURE,
  CREATE_REVIEW_REQUEST,
  CREATE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAILURE,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  GET_LIST_REVIEW_FAILURE,
  GET_LIST_REVIEW_REQUEST,
  GET_LIST_REVIEW_SUCCESS,
  GET_LIST_USER_FAILURE,
  GET_LIST_USER_REQUEST,
  GET_LIST_USER_SUCCESS,
  GET_ONE_REVIEW_FAILURE,
  GET_ONE_REVIEW_REQUEST,
  GET_ONE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAILURE,
  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_SUCCESS,
} from './Review.constant';
import { ReviewActionsTypes } from './Review.type';

export function getOneReviewRequest(
  payload: Partial<Query> & { reviewId: number },
): ReviewActionsTypes {
  return {
    type: GET_ONE_REVIEW_REQUEST,
    payload,
  };
}

export function getOneReviewSuccess(
  payload: DataResponse<FullReview>,
): ReviewActionsTypes {
  return {
    type: GET_ONE_REVIEW_SUCCESS,
    payload,
  };
}

export function getOneReviewFailure(): ReviewActionsTypes {
  return {
    type: GET_ONE_REVIEW_FAILURE,
  };
}

export function getListReviewRequest(query = ''): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListReviewSuccess(
  payload: DataResponse<FullReview[]>,
): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_SUCCESS,
    payload,
  };
}

export function getListReviewFailure(): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_FAILURE,
  };
}

export function getListUserRequest(query = ''): ReviewActionsTypes {
  return {
    type: GET_LIST_USER_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListUserSuccess(
  payload: DataResponse<FullUser[]>,
): ReviewActionsTypes {
  return {
    type: GET_LIST_USER_SUCCESS,
    payload,
  };
}

export function getListUserFailure(): ReviewActionsTypes {
  return {
    type: GET_LIST_USER_FAILURE,
  };
}

export function clearListUser(): ReviewActionsTypes {
  return {
    type: CLEAR_LIST_USER,
  };
}

export function createReviewRequest(
  payload: CreateReviewRequest,
): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_REQUEST,
    payload,
  };
}

export function createReviewSuccess(): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_SUCCESS,
  };
}

export function createReviewFailure(): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_FAILURE,
  };
}

export function deleteReviewRequest(payload: Id): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_REQUEST,
    payload,
  };
}

export function deleteReviewSuccess(): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_SUCCESS,
  };
}

export function deleteReviewFailure(): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_FAILURE,
  };
}

export function updateReviewRequest(
  payload: Pick<FullReview, 'id' | 'message' | 'rating'>,
): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_REQUEST,
    payload,
  };
}

export function updateReviewSuccess(): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_SUCCESS,
  };
}

export function updateReviewFailure(): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_FAILURE,
  };
}
