import { DeepPartial } from 'redux';

import { FullMedia } from '@entities/Media';
import { FullUser } from '@entities/User';
import { UploadFile } from '@utils';

import {
  CLEAR_AVATAR_STORE,
  CLEAR_FILTER,
  CLEAR_SEARCH_USER_LIST,
  CLEAR_SORT,
  CLEAR_USER_LIST_STORE,
  CLEAR_USER_ONE_STORE,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_LIST_USER_FAILURE,
  GET_LIST_USER_REQUEST,
  GET_LIST_USER_SUCCESS,
  GET_ONE_USER_FAILURE,
  GET_ONE_USER_REQUEST,
  GET_ONE_USER_SUCCESS,
  GET_SEARCH_LIST_USER_FAILURE,
  GET_SEARCH_LIST_USER_REQUEST,
  GET_SEARCH_LIST_USER_SUCCESS,
  REMOVE_AVATAR,
  RESET_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  SET_FILTER,
  SET_SORT,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
} from './User.constant';
import { UserActionsTypes, UserFilter, UserSort } from './User.type';

export function getListUserRequest(query: Query['query']): UserActionsTypes {
  return {
    type: GET_LIST_USER_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListUserSuccess(payload: {
  data: FullUser[];
  meta: MetaType;
}): UserActionsTypes {
  return {
    type: GET_LIST_USER_SUCCESS,
    payload,
  };
}

export function getListUserFailure(): UserActionsTypes {
  return {
    type: GET_LIST_USER_FAILURE,
  };
}

export function setSort(data: UserSort | null): UserActionsTypes {
  return {
    type: SET_SORT,
    payload: data,
  };
}

export function setFilter(data: UserFilter | null): UserActionsTypes {
  return {
    type: SET_FILTER,
    payload: data,
  };
}

export function getOneUserRequest(id: number): UserActionsTypes {
  return {
    type: GET_ONE_USER_REQUEST,
    payload: {
      id,
    },
  };
}

export function getOneUserSuccess(user: FullUser): UserActionsTypes {
  return {
    type: GET_ONE_USER_SUCCESS,
    payload: { user },
  };
}

export function getOneUserFailure(): UserActionsTypes {
  return {
    type: GET_ONE_USER_FAILURE,
  };
}

export function uploadAvatarRequest(
  file: UploadFile['file'],
): UserActionsTypes {
  return {
    type: UPLOAD_AVATAR_REQUEST,
    payload: {
      file,
    },
  };
}

export function uploadAvatarSuccess(media: FullMedia): UserActionsTypes {
  return {
    type: UPLOAD_AVATAR_SUCCESS,
    payload: { file: media },
  };
}

export function uploadAvatarFailure(): UserActionsTypes {
  return {
    type: UPLOAD_AVATAR_FAILURE,
  };
}

export function removeAvatar(): UserActionsTypes {
  return {
    type: REMOVE_AVATAR,
  };
}

export function updateUserRequest(
  payload: {
    body: DeepPartial<FullUser>;
  } & Id &
    Query,
): UserActionsTypes {
  return {
    type: UPDATE_USER_REQUEST,
    payload,
  };
}

export function updateUserSuccess(user: FullUser): UserActionsTypes {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: { user },
  };
}

export function updateUserFailure(): UserActionsTypes {
  return { type: UPDATE_USER_FAILURE };
}

export function deleteUserRequest(payload: Id & Query): UserActionsTypes {
  return {
    type: DELETE_USER_REQUEST,
    payload,
  };
}
export function deleteUserSuccess(): UserActionsTypes {
  return { type: DELETE_USER_SUCCESS };
}

export function deleteUserFailure(): UserActionsTypes {
  return { type: DELETE_USER_FAILURE };
}

export function clearFilter(): UserActionsTypes {
  return { type: CLEAR_FILTER };
}

export function clearSort(): UserActionsTypes {
  return { type: CLEAR_SORT };
}

export function createUserRequest(
  payload: { body: DeepPartial<FullUser> } & Query,
): UserActionsTypes {
  return {
    type: CREATE_USER_REQUEST,
    payload,
  };
}

export function createUserSuccess(): UserActionsTypes {
  return {
    type: CREATE_USER_SUCCESS,
  };
}

export function createUserFailure(): UserActionsTypes {
  return {
    type: CREATE_USER_FAILURE,
  };
}

export function getSearchListUserRequest(
  query: Query['query'],
): UserActionsTypes {
  return {
    type: GET_SEARCH_LIST_USER_REQUEST,
    payload: {
      query,
    },
  };
}

export function getSearchListUserSuccess(payload: {
  data: FullUser[];
}): UserActionsTypes {
  return {
    type: GET_SEARCH_LIST_USER_SUCCESS,
    payload,
  };
}

export function getSearchListUserFailure(): UserActionsTypes {
  return {
    type: GET_SEARCH_LIST_USER_FAILURE,
  };
}

export function resetUserPasswordRequest(id: number): UserActionsTypes {
  return {
    type: RESET_USER_PASSWORD_REQUEST,
    payload: {
      id,
    },
  };
}

export function resetUserPasswordSuccess(): UserActionsTypes {
  return {
    type: RESET_USER_PASSWORD_SUCCESS,
  };
}

export function resetUserPasswordFailure(): UserActionsTypes {
  return {
    type: RESET_USER_PASSWORD_FAILURE,
  };
}

export function clearUserListStore(): UserActionsTypes {
  return {
    type: CLEAR_USER_LIST_STORE,
  };
}

export function clearUserOneStore(): UserActionsTypes {
  return {
    type: CLEAR_USER_ONE_STORE,
  };
}

export function clearAvatarStore(): UserActionsTypes {
  return {
    type: CLEAR_AVATAR_STORE,
  };
}

export function clearSearchUserList(): UserActionsTypes {
  return {
    type: CLEAR_SEARCH_USER_LIST,
  };
}
