export const GET_LIST_CHAT_REQUEST = '@chat/GET_LIST_CHAT_REQUEST';
export const GET_LIST_CHAT_SUCCESS = '@chat/GET_LIST_CHAT_SUCCESS';
export const GET_LIST_CHAT_FAILURE = '@chat/GET_LIST_CHAT_FAILURE';

export const GET_ONE_CHAT_REQUEST = '@chat/GET_ONE_CHAT_REQUEST';
export const GET_ONE_CHAT_SUCCESS = '@chat/GET_ONE_CHAT_SUCCESS';
export const GET_ONE_CHAT_FAILURE = '@chat/GET_ONE_CHAT_FAILURE';

export const DELETE_CHAT_REQUEST = '@chat/DELETE_CHAT_REQUEST';
export const DELETE_CHAT_SUCCESS = '@chat/DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_FAILURE = '@chat/DELETE_CHAT_FAILURE';
