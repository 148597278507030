import { Button, Checkbox, Space } from 'antd';

import { FilterCheckboxOption } from '@entities/Filter';
import { i18next } from '@i18n';

import { Props } from './index.type';

const { Group } = Checkbox;

const WidgetModalCheckboxRadio = ({
  filterValue,
  options,
  onFilterUpdate,
}: Props) => {
  const changeHandler = (values: FilterCheckboxOption[]) => {
    onFilterUpdate(values);
  };

  const clearHandler = () => {
    onFilterUpdate(null);
  };

  return (
    <Space direction="vertical">
      <Group onChange={changeHandler} value={filterValue}>
        <Space direction="vertical">
          {options?.map((option, id) => (
            <Checkbox value={option.value} key={`check_${id}`}>
              {option.label}
            </Checkbox>
          ))}
        </Space>
      </Group>
      <Button
        type="link"
        className="modal-filter__button-clear"
        onClick={clearHandler}
      >
        {i18next.t('Clear')}
      </Button>
    </Space>
  );
};

export default WidgetModalCheckboxRadio;
