import { i18next } from '@i18n';
import { updateBookingRequest } from '@store/Booking';
import { ModalType, showModal } from '@store/Modal';
import { UICellAction } from '@ui';
import { DateHelper, ProcessStatus, REFUND_DAY } from '@utils';

import { Button, Props } from './index.type';

const BookingUIActions = ({
  id,
  status,
  price,
  discount,
  finalPrice,
  startDate,
  endDate,
  duration,
  coupon,
  paymentInfo,
  angel,
  dispatch,
}: Props) => {
  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.BOOKING_EDIT,
        modalProps: { id, duration },
      }),
    );
  };

  const onRefundClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.BOOKING_REFUND,
        modalProps: {
          id,
          price,
          discount,
          finalPrice,
          coupon,
          paymentInfo,
          angel,
        },
      }),
    );
  };

  const onCancelClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: <p>Are you sure you want to cancel your booking?</p>,
          action: () => {
            dispatch(
              updateBookingRequest({
                id,
                status: ProcessStatus.CANCELED,
              }),
            );
          },
        },
      }),
    );
  };

  const renderButton = (): Button[] => {
    const button: Button[] = [
      { title: i18next.t('Edit'), onClick: onEditClickHandler },
    ];
    const endDateRefund = DateHelper.addDate(endDate, REFUND_DAY as number);

    if (
      status === ProcessStatus.SUCCESS &&
      DateHelper.dateIsBefore(startDate, new Date()) &&
      DateHelper.dateIsBefore(new Date(), endDateRefund) &&
      finalPrice > 0
    ) {
      button.push({
        title: i18next.t('Refund'),
        onClick: onRefundClickHandler,
        danger: true,
      });
    }

    if (
      status === ProcessStatus.SUCCESS &&
      DateHelper.dateIsBefore(new Date(), startDate)
    ) {
      button.push({
        title: i18next.t('Cancel'),
        onClick: onCancelClickHandler,
        danger: true,
      });
    }

    return button;
  };

  return UICellAction({
    buttons: renderButton(),
  });
};

export default BookingUIActions;
