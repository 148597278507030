import { List, Space } from 'antd';
import Text from 'antd/lib/typography/Text';

import { removeCommentRequest } from '@store/Comment';
import { useAppDispatch } from '@store/index';
import { Button, DateHelper, FORMAT_TIME_WITH_DAY_PHASE } from '@utils';

import UIAvatar from '../../Avatar';
import UIButton from '../../Button';
import { Props } from './index.type';

const UICommentItem = ({ id, createdBy, createdAt, message, query }: Props) => {
  const dispatch = useAppDispatch();

  const deleteCommentHandler = () => {
    dispatch(removeCommentRequest(id, query));
  };

  const avatar = <UIAvatar path={createdBy?.avatar?.path} />;

  const title = (
    <Space size="large">
      <Text type="secondary">
        {createdBy.profile.firstName} {createdBy.profile.lastName}
      </Text>
      <Text type="secondary">
        {DateHelper.formateDateToString(createdAt, FORMAT_TIME_WITH_DAY_PHASE)}
      </Text>
    </Space>
  );

  const description = (
    <Space size="small" direction="vertical">
      <Text>{message}</Text>
      <UIButton
        type={Button.ANT_BUTTON}
        prop={{ danger: true }}
        typeAntBtn="link"
        handler={deleteCommentHandler}
      >
        Delete
      </UIButton>
    </Space>
  );

  return (
    <List.Item>
      <List.Item.Meta avatar={avatar} title={title} description={description} />
    </List.Item>
  );
};

export default UICommentItem;
