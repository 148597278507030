import Title from 'antd/lib/typography/Title';

import { UIButton } from '@ui';
import { Button, Header } from '@utils';

import { Props } from './index.type';

const UserUITitle = ({ text, type, level }: Props) => {
  const renderButton = () => {
    if (type === Header.BUTTON) {
      return <UIButton type={Button.LINK}>{text}</UIButton>;
    }

    return null;
  };

  const renderTitle = () => {
    if (type === Header.TITLE) {
      return <Title level={level}>{text}</Title>;
    }

    return null;
  };

  switch (type) {
    case Header.BUTTON:
      return renderButton();
    case Header.TITLE:
      return renderTitle();
    default:
      return renderButton();
  }
};

export default UserUITitle;
