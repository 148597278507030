import { Layout } from 'antd';
import { Navigate, RouteProps } from 'react-router-dom';

import { useAuth } from '@hooks';
import { LINK_LOGIN } from '@utils';
import { WidgetHeader, WidgetMenu } from '@widgets';

import './style.scss';

const PrivateRoute = ({ children }: RouteProps) => {
  const { isAuth } = useAuth();

  if (isAuth) {
    return (
      <>
        <WidgetHeader showProfile />
        <Layout>
          <WidgetMenu />
          <Layout.Content className="site-content">{children}</Layout.Content>
        </Layout>
      </>
    );
  }

  return <Navigate to={{ pathname: `/${LINK_LOGIN}` }} />;
};

export default PrivateRoute;
