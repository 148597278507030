import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import { FullTransaction } from '@entities/Transaction';
import { FullUser } from '@entities/User';
import { i18next } from '@i18n';
import { UICellFullName, UITag } from '@ui';
import {
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_TAG,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  LINK_CLIENT,
  LINK_EXPERT,
  Role,
  StringHelper,
  TransactionStatus,
  processStatusOptions,
} from '@utils';

export const columns = (): ColumnsType<FullTransaction> => {
  const columnList = [
    {
      title: i18next.t('Id'),
      dataIndex: 'id',
      key: 'number',
      width: COLUMN_WIDTH_ID,
      fixed: true,
    },
    {
      title: i18next.t('User'),
      dataIndex: 'user',
      key: 'user',
      width: COLUMN_WIDTH_NAME,
      render: (value: FullUser) =>
        UICellFullName({
          lastName: value?.profile?.lastName,
          firstName: value?.profile?.firstName,
          src: value?.avatar?.path,
          link: `/${value?.role === Role.USER ? LINK_CLIENT : LINK_EXPERT}/${
            value.id
          }`,
        }),
    },
    {
      title: i18next.t('Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
    },
    {
      title: i18next.t('Status'),
      dataIndex: 'txnStatus',
      key: 'txnStatus',
      width: COLUMN_WIDTH_TAG,
      render: (value: TransactionStatus) =>
        UITag({
          value,
          values: processStatusOptions,
        }),
    },
    {
      title: i18next.t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: COLUMN_WIDTH_NUMBER,
      render: (value: FullTransaction) => `€${value}`,
    },
  ];

  return columnList;
};

export const orderOption: SortOption[] = [
  {
    label: i18next.t('Id (ascending)'),
    name: 'id',
    direction: 'asc',
  },
  {
    label: i18next.t('Id (descending)'),
    name: 'id',
    direction: 'desc',
  },
  {
    label: i18next.t('User name (A-Z)'),
    name: 'user_name',
    direction: 'asc',
  },
  {
    label: i18next.t('User name (Z-A)'),
    name: 'user_name',
    direction: 'desc',
  },
  {
    label: i18next.t('Date (oldest first)'),
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: i18next.t('Date (newest first)'),
    name: 'created_at',
    direction: 'desc',
  },
  {
    label: i18next.t('Amount (ascending)'),
    name: 'amount',
    direction: 'asc',
  },
  {
    label: i18next.t('Amount (descending)'),
    name: 'amount',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions: FilterOption[] = [
  {
    name: 'created_at',
    label: i18next.t('Date'),
    type: 'radio-range',
    options: [
      {
        label: i18next.t('Today'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: i18next.t('Last 7 days'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: i18next.t('Last 30 days'),
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
  {
    name: 'txnStatus',
    label: i18next.t('Status'),
    type: 'checkbox',
    options: [
      {
        label: i18next.t(StringHelper.capitalize(TransactionStatus.CANCELED)),
        value: TransactionStatus.CANCELED,
      },
      {
        label: i18next.t(StringHelper.capitalize(TransactionStatus.FAILED)),
        value: TransactionStatus.FAILED,
      },
      {
        label: i18next.t(StringHelper.capitalize(TransactionStatus.PENDING)),
        value: TransactionStatus.PENDING,
      },
      {
        label: i18next.t(StringHelper.capitalize(TransactionStatus.SUCCESS)),
        value: TransactionStatus.SUCCESS,
      },
    ],
  },
  {
    name: 'amount',
    label: i18next.t('Amount'),
    type: 'number-range',
    step: 1,
    min: 0,
  },
];
