/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Col, Form, Radio, RadioChangeEvent, Row, Space } from 'antd';

import { CouponCustomer } from '@entities/Coupon';
import { i18next } from '@i18n';
import { UIUserSearch } from '@ui';

import { CouponUIItemAdd } from '../../../ui';
import { Props } from './index.type';

import './style.scss';

const CouponDetailLimitedCustomer = ({ users, setUsers, form }: Props) => {
  const onChange = (e: RadioChangeEvent) => {
    const data = e?.target?.value || CouponCustomer.ANY;

    if (data === CouponCustomer.ANY) {
      setUsers([]);
    }

    form.setFieldsValue({ customer: data });
  };

  return (
    <CouponUIItemAdd title={i18next.t('Limited of customers')}>
      <div className="limited-customer-wrapper">
        <Row>
          <Form.Item name="customer">
            <Radio.Group onChange={onChange}>
              <Space direction="vertical">
                <Radio value={CouponCustomer.ANY}>
                  {i18next.t('Anyone can use')}
                </Radio>
                <Radio value={CouponCustomer.SPECIFIC}>
                  {i18next.t('Specific customers')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Row>

        <Row justify="space-between" className="limited-customer-select">
          <Col span={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.customer !== currentValues.customer
              }
            >
              {({ getFieldValue }) => (
                <UIUserSearch
                  value={users}
                  setValue={setUsers}
                  disabled={
                    getFieldValue('customer') !== CouponCustomer.SPECIFIC
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </CouponUIItemAdd>
  );
};

export default CouponDetailLimitedCustomer;
