import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullCoupon } from '@entities/Coupon';
import { CouponHttp } from '@services/http';
import { alertError } from '@store/Alert';

import {
  createCouponFailure,
  createCouponSuccess,
  getListCouponFailure,
  getListCouponRequest,
  getListCouponSuccess,
  getOneCouponFailure,
  getOneCouponSuccess,
  removeCouponFailure,
  removeCouponSuccess,
  updateCouponFailure,
  updateCouponSuccess,
} from './Coupon.action';
import {
  CREATE_COUPON_REQUEST,
  GET_LIST_COUPON_REQUEST,
  GET_ONE_COUPON_REQUEST,
  REMOVE_COUPON_REQUEST,
  UPDATE_COUPON_REQUEST,
} from './Coupon.constant';
import {
  ICreateCouponRequest,
  IGetListCouponRequest,
  IGetOneCouponRequest,
  IRemoveCouponRequest,
  IUpdateCouponRequest,
} from './Coupon.type';

function* workerGetListCoupon({ payload }: IGetListCouponRequest) {
  try {
    const { query } = payload;
    const res: DataResponse<FullCoupon[]> = yield call(
      CouponHttp.getList,
      query,
    );

    yield put(getListCouponSuccess(res));
  } catch (error) {
    yield put(getListCouponFailure());
    yield put(alertError(error));
  }
}

function* watchGetListCoupon() {
  yield takeLatest(GET_LIST_COUPON_REQUEST, workerGetListCoupon);
}

function* workerGetOneCoupon({ payload }: IGetOneCouponRequest) {
  try {
    const { id } = payload;
    const res: DataResponse<FullCoupon> = yield call(CouponHttp.getOne, id);

    yield put(getOneCouponSuccess(res));
  } catch (error) {
    yield put(getOneCouponFailure());
    yield put(alertError(error));
  }
}

function* watchGetOneCoupon() {
  yield takeLatest(GET_ONE_COUPON_REQUEST, workerGetOneCoupon);
}

function* workerCreateCoupon({ payload }: ICreateCouponRequest) {
  try {
    const { body, query } = payload;

    const res: DataResponse<FullCoupon> = yield call(CouponHttp.create, body);

    yield put(getListCouponRequest(query));
    yield put(createCouponSuccess(res));
  } catch (error) {
    yield put(createCouponFailure());
    yield put(alertError(error));
  }
}

function* watchCreateCoupon() {
  yield takeLatest(CREATE_COUPON_REQUEST, workerCreateCoupon);
}

function* workerUpdateCoupon({ payload }: IUpdateCouponRequest) {
  try {
    const { query, body, id } = payload;

    const res: DataResponse<FullCoupon> = yield call(
      CouponHttp.update,
      id,
      body,
    );

    yield put(getListCouponRequest(query));
    yield put(updateCouponSuccess(res));
  } catch (error) {
    yield put(updateCouponFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateCoupon() {
  yield takeLatest(UPDATE_COUPON_REQUEST, workerUpdateCoupon);
}

function* workerRemoveCoupon({ payload }: IRemoveCouponRequest) {
  try {
    const { id, query } = payload;

    yield call(CouponHttp.remove, id);

    yield put(getListCouponRequest(query));
    yield put(removeCouponSuccess());
  } catch (error) {
    yield put(removeCouponFailure());
    yield put(alertError(error));
  }
}

function* watchRemoveCoupon() {
  yield takeLatest(REMOVE_COUPON_REQUEST, workerRemoveCoupon);
}

export const couponWatchers: ForkEffect[] = [
  fork(watchGetListCoupon),
  fork(watchGetOneCoupon),
  fork(watchCreateCoupon),
  fork(watchUpdateCoupon),
  fork(watchRemoveCoupon),
];
