import { DeepPartial } from 'redux';

import { FullMedia } from '@entities/Media';
import { FullUser } from '@entities/User';
import { UploadFile } from '@utils';

import {
  CLEAR_PROFILE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  REMOVE_AVATAR,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
} from './Profile.constant';
import { ProfileActionsTypes } from './Profile.type';

export function getProfileRequest(): ProfileActionsTypes {
  return {
    type: GET_PROFILE_REQUEST,
  };
}

export function getProfileSuccess(user: FullUser): ProfileActionsTypes {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: {
      user,
    },
  };
}

export function getProfileFailure(): ProfileActionsTypes {
  return {
    type: GET_PROFILE_FAILURE,
  };
}

export function clearProfile(): ProfileActionsTypes {
  return {
    type: CLEAR_PROFILE,
  };
}

export function updateProfileRequest(
  body: DeepPartial<FullUser>,
): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: {
      body,
    },
  };
}

export function updateProfileSuccess(user: FullUser): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: { user },
  };
}

export function updateProfileFailure(): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_FAILURE,
  };
}

export function uploadAvatarRequest(
  file: UploadFile['file'],
): ProfileActionsTypes {
  return {
    type: UPLOAD_AVATAR_REQUEST,
    payload: {
      file,
    },
  };
}

export function uploadAvatarSuccess(media: FullMedia): ProfileActionsTypes {
  return {
    type: UPLOAD_AVATAR_SUCCESS,
    payload: { file: media },
  };
}

export function uploadAvatarFailure(): ProfileActionsTypes {
  return {
    type: UPLOAD_AVATAR_FAILURE,
  };
}

export function removeAvatar(): ProfileActionsTypes {
  return {
    type: REMOVE_AVATAR,
  };
}
