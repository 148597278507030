import { SessionStatistic } from '@entities/Statistic';

import {
  CLEAR_SESSION,
  GET_SESSION_FAILURE,
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
} from './Statistic.constant';
import { SessionActionsTypes } from './Statistic.type';

export function getSessionRequest(query = ''): SessionActionsTypes {
  return {
    type: GET_SESSION_REQUEST,
    payload: {
      query,
    },
  };
}

export function getSessionSuccess(
  payload: SessionStatistic[],
): SessionActionsTypes {
  return {
    type: GET_SESSION_SUCCESS,
    payload,
  };
}

export function getSessionFailure(): SessionActionsTypes {
  return {
    type: GET_SESSION_FAILURE,
  };
}

export const clearSession = (): SessionActionsTypes => ({
  type: CLEAR_SESSION,
});
