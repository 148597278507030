import { ColumnsType } from 'antd/lib/table';

import { i18next } from '@i18n';
import { Header } from '@utils';

import { UserUITitle } from '../../ui';

export const columns = (): ColumnsType<{ id: number }> => [
  {
    title: i18next.t('Request number'),
    dataIndex: 'number',
    key: 'number',
    render: (text: string) => <UserUITitle text={text} type={Header.BUTTON} />,
  },
  {
    title: i18next.t('Request date'),
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: i18next.t('Topic'),
    dataIndex: 'topic',
    key: 'topic',
  },
  {
    title: i18next.t('Status'),
    key: 'status',
    dataIndex: 'status',
  },
];
