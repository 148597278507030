import { LayoutMenu } from '@layouts';
import { removeCouponRequest } from '@store/Coupon';
import { ModalType, showModal } from '@store/Modal';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const CouponUIActionList = ({ id, dispatch }: Props) => {
  const onDeleteCouponHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: <p>Are you sure you want to delete the coupon?</p>,
          action: () => dispatch(removeCouponRequest(id)),
        },
      }),
    );
  };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [{ title: 'Delete', onClick: onDeleteCouponHandler }],
    }),
  });
};

export default CouponUIActionList;
