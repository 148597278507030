import { useCallback, useEffect, useMemo } from 'react';

import { Col, Empty, Pagination, Row, Timeline } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useSelector } from 'react-redux';

import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import { clearList, getListRequest } from '@store/UserAction';
import { DateHelper, FORMAT_DATE_TIME_WITH_DOT, QueryHelper } from '@utils';

import { Props } from './index.type';

import './style.scss';

const UserDetailAction = ({ userId }: Props) => {
  const dispatch = useAppDispatch();

  const { userActionList, meta } = useSelector(
    (state: RootState) => state.userAction,
  );

  const query = useCallback(
    (page?: number, limit?: number) =>
      QueryHelper.stringify({
        limit,
        page,
        filter: {
          user_id: userId,
        },
      }),
    [userId],
  );

  const onPaginationChange = (currentPage: number, limit?: number) => {
    dispatch(getListRequest(query(currentPage, limit || meta.limit)));
  };

  useEffect(() => {
    if (userId) {
      dispatch(getListRequest(query()));
    }
  }, [dispatch, userId, query]);

  useEffect(() => {
    return () => {
      dispatch(clearList());
    };
  }, [dispatch]);

  const renderList = useMemo(
    () =>
      userActionList.map((item, key) => (
        <Timeline.Item
          key={`action_${key}`}
          label={DateHelper.getDateFormat(
            item.createdAt,
            FORMAT_DATE_TIME_WITH_DOT,
          )}
        >
          {item.message}
        </Timeline.Item>
      )),
    [userActionList],
  );

  return (
    <div className="user-action-wrapper">
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={5}>{i18next.t('User Actions')}</Title>
        </Col>
      </Row>
      <div className="actions-wrapper">
        {userActionList.length ? (
          <Timeline mode="left">{renderList}</Timeline>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
      {!!userActionList.length && (
        <Pagination
          defaultPageSize={meta.limit}
          current={meta.currentPage}
          total={meta.totalItems}
          onChange={onPaginationChange}
        />
      )}
    </div>
  );
};

export default UserDetailAction;
