import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserProps } from '@entities/index';
import { LayoutContent } from '@layouts';
import { deleteChatRequest, getOneChatRequest } from '@store/Chat';
import { RootState, useAppDispatch } from '@store/index';
import { UIButton, UIHeader, UIOverview } from '@ui';
import { ActionStatus, Button, Role } from '@utils';

import { ChatDetailMessageList } from './Message';

import './style.scss';

const ChatDetail = () => {
  const dispatch = useAppDispatch();
  const { messageList, chat, status } = useSelector(
    (state: RootState) => state.chat,
  );
  const { chatId } = useParams<'chatId'>();

  useEffect(() => {
    if (chatId) {
      dispatch(getOneChatRequest(+chatId));
    }
  }, [dispatch, chatId]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
  }, [status]);

  const onDeleteHandler = () => {
    if (chatId) {
      dispatch(deleteChatRequest(+chatId));
    }
  };

  const users = (): UserProps[] => {
    if (!chat) {
      return [];
    }

    const { angel, user } = chat;

    return [
      {
        id: angel.id,
        role: Role.ANGEL,
        firstName: angel.profile.firstName,
        lastName: angel.profile.lastName,
        avatar: angel.avatar?.path,
      },
      {
        id: user.id,
        role: Role.USER,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        avatar: user.avatar?.path,
      },
    ];
  };

  const content = <ChatDetailMessageList messages={messageList} />;

  const aside = (
    <>
      <UIOverview users={users()} date={chat?.createdAt} />
      <Row>
        <Col span={24}>
          <UIButton
            children="Delete chat"
            type={Button.ANT_BUTTON}
            prop={{ danger: true, block: true }}
            handler={onDeleteHandler}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <UIHeader title="Chat" isBack />
      <LayoutContent content={content} aside={aside} />
    </>
  );
};

export default ChatDetail;
