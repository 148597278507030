import { useCallback } from 'react';

import { Col, Row } from 'antd';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIAvatar, UICellRate } from '@ui';

import { Props } from './index.type';

import './style.scss';

const ReviewDetailItem = (props: Props) => {
  const { className } = props;
  const { reviewId } = useParams<'reviewId'>();

  const dispatch = useAppDispatch();

  const { review } = useSelector((state: RootState) => state.review);

  const editHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType: ModalType.EDIT_REVIEW,
        modalProps: {
          reviewId,
        },
      }),
    );
  }, [dispatch, reviewId]);

  return (
    <div className={cn('review-detail-item', className)}>
      <UIAvatar path={review?.user.avatar?.path} />
      <div className="content">
        <Row className="header">
          <Col>
            <Row align="middle">
              <div className="fullname">
                {review?.user?.profile?.fullName || ''}
              </div>
              <UICellRate
                allowHalf
                count={5}
                rating={review?.rating}
                disabled
              />
            </Row>
          </Col>

          <Col className="edit-button" onClick={editHandler}>
            {i18next.t('Edit review')}
          </Col>
        </Row>

        <div className="text">{review?.message}</div>
      </div>
    </div>
  );
};

export default ReviewDetailItem;
