import { FullSpecialty } from '@entities/Specialty';

import {
  GET_LIST_SPECIALTY_FAILURE,
  GET_LIST_SPECIALTY_REQUEST,
  GET_LIST_SPECIALTY_SUCCESS,
} from './Specialty.constant';
import { SpecialtyActionsTypes } from './Specialty.type';

export function getListSpecialtyRequest(): SpecialtyActionsTypes {
  return {
    type: GET_LIST_SPECIALTY_REQUEST,
  };
}

export function getListSpecialtySuccess(
  payload: DataResponse<FullSpecialty[]>,
): SpecialtyActionsTypes {
  return {
    type: GET_LIST_SPECIALTY_SUCCESS,
    payload,
  };
}

export function getListSpecialtyFailure(): SpecialtyActionsTypes {
  return {
    type: GET_LIST_SPECIALTY_FAILURE,
  };
}
