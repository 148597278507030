import { Rate, Space } from 'antd';
import Text from 'antd/lib/typography/Text';

import { Props } from './index.type';

const UICellRate = (props: Props) => {
  const {
    rating,
    votes,
    count,
    onChangedHandler,
    allowHalf = true,
    disabled = false,
  } = props;

  return (
    <Space size="small" direction="horizontal" wrap={false} align="center">
      <Rate
        allowHalf={allowHalf}
        value={rating}
        count={count || 1}
        onChange={onChangedHandler}
        disabled={disabled}
      />
      {votes && (
        <span>
          <Text type="warning">{rating}</Text> ({votes})
        </span>
      )}
    </Space>
  );
};

export default UICellRate;
