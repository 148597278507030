import { Route, Routes } from 'react-router-dom';

import { PrivateRoute } from '@routes';
import {
  LINK_BOOKING,
  LINK_CHAT,
  LINK_CLIENT,
  LINK_COLLECTION,
  LINK_COMMISSION,
  LINK_COUPON,
  LINK_EXPERT,
  LINK_LOGIN,
  LINK_PAYOUT,
  LINK_REVIEW,
} from '@utils';
import ViewCommission from '@views/Commission';

import { ViewAuthLogin } from './Auth';
import ViewBooking from './Booking';
import ViewChat from './Chat';
import ViewClient from './Client';
import ViewCollection from './Collection';
import ViewCoupon from './Coupon';
import ViewDashboard from './Dashboard';
import ViewExpert from './Expert';
import ViewPayout from './Payout';
import ViewReview from './Review';
import ViewTransaction from './Transaction';

const View = () => {
  return (
    <Routes>
      <Route path={LINK_LOGIN} element={<ViewAuthLogin />} />
      <Route
        path={`/`}
        element={
          <PrivateRoute>
            <ViewClient />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_CLIENT}/*`}
        element={
          <PrivateRoute>
            <ViewClient />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_COLLECTION}/*`}
        element={
          <PrivateRoute>
            <ViewCollection />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_EXPERT}/*`}
        element={
          <PrivateRoute>
            <ViewExpert />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_CHAT}/*`}
        element={
          <PrivateRoute>
            <ViewChat />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_PAYOUT}/*`}
        element={
          <PrivateRoute>
            <ViewPayout />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_COUPON}/*`}
        element={
          <PrivateRoute>
            <ViewCoupon />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_COMMISSION}/*`}
        element={
          <PrivateRoute>
            <ViewCommission />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_REVIEW}/*`}
        element={
          <PrivateRoute>
            <ViewReview />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_BOOKING}/*`}
        element={
          <PrivateRoute>
            <ViewBooking />
          </PrivateRoute>
        }
      />
      <Route
        path="transactions/*"
        element={
          <PrivateRoute>
            <ViewTransaction />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/"
        element={
          <PrivateRoute>
            <ViewDashboard />
          </PrivateRoute>
        }
      /> */}
    </Routes>
  );
};

export default View;
