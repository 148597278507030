import { Route, Routes } from 'react-router-dom';

import { ReviewDetail, ReviewList } from '@modules/Review';

const ViewReview = () => {
  return (
    <Routes>
      <Route path=":reviewId" element={<ReviewDetail />} />
      <Route path="/" element={<ReviewList />} />
    </Routes>
  );
};

export default ViewReview;
