import { useEffect } from 'react';

import { Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AddUpdateCommission } from '@entities/index';
import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import {
  addUpdateCommissionRequest,
  deleteCommissionRequest,
  getCommissionRequest,
  resetCommission,
} from '@store/Commission';
import { RootState, useAppDispatch } from '@store/index';
import { UIHeader, UITable } from '@ui';
import { LINK_COMMISSION, LINK_COMMISSION_ADD } from '@utils';

import DeleteCommissionUIActions from '../ui/Actions/DeleteCommission';
import CommissionUIForm from '../ui/Form';
import { columns } from './constants';

const CommissionDetail = () => {
  const { commissionId } = useParams<'commissionId'>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { commission, loading, metaCommissionUsers, commissionList } =
    useSelector((state: RootState) => state.commission);

  const { query, updateQuery } = useQuery();

  const onPaginationChange = (
    page: number,
    limit = metaCommissionUsers.limit,
  ) => {
    updateQuery({ page, limit });
  };

  const onSave = (data: AddUpdateCommission) => {
    const newData = data;

    if (commission?.id) {
      newData.id = commission?.id;
    }
    dispatch(addUpdateCommissionRequest(newData));
  };

  const onDelete = () => {
    if (commission?.id) {
      dispatch(deleteCommissionRequest({ id: commission.id }));
    }
  };

  useEffect(() => {
    if (
      commissionId &&
      commission?.id !== +commissionId &&
      commissionList.find((item) => item.id === +commissionId)
    ) {
      dispatch(getCommissionRequest(+commissionId, query));
    }
  }, [dispatch, commissionId, commission?.id, commissionList, query]);

  useEffect(() => {
    if (
      commissionId &&
      !commission &&
      !commissionList.find((item) => item.id === +commissionId)
    ) {
      navigate(`/${LINK_COMMISSION}/${LINK_COMMISSION_ADD}`);
    }
  }, [commission, commissionList]);

  useEffect(() => {
    if (!commissionId && !!commission) {
      navigate(`/${LINK_COMMISSION}/${commission.id}`);
    }
  }, [commission]);

  useEffect(() => {
    return () => {
      dispatch(resetCommission());
    };
  }, [dispatch]);

  return (
    <Spin spinning={loading}>
      <UIHeader
        isBack
        backLink={`/${LINK_COMMISSION}`}
        title={
          commission?.name
            ? `${i18next.t('Commission')}: ${commission?.name}`
            : i18next.t('Add commission')
        }
      />
      {commission?.id ? (
        <Row align="top" gutter={[16, 4]}>
          <CommissionUIForm
            name={commission.name}
            amount={commission.amount}
            onSave={onSave}
          />
          <DeleteCommissionUIActions
            createdAt={commission.createdAt}
            updatedAt={commission.updatedAt}
            onDelete={onDelete}
          />
        </Row>
      ) : (
        <CommissionUIForm onSave={onSave} />
      )}

      {commission?.users ? (
        <>
          <Title style={{ marginLeft: '4px', marginTop: '20px' }} level={4}>
            {i18next.t('List of psychologists')}
          </Title>
          <Text style={{ marginLeft: '4px' }}>
            {i18next.t('This commission is set for these psychologists')}
          </Text>
          <Row>
            <UITable
              dataSource={commission?.users}
              columns={columns()}
              loading={loading}
              meta={metaCommissionUsers}
              onPaginationChange={onPaginationChange}
            />
          </Row>
        </>
      ) : null}
    </Spin>
  );
};

export default CommissionDetail;
