import { META_DEFAULT } from '@utils';

import {
  CLEAR_LIST,
  GET_LIST_FAILURE,
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
} from './UserAction.constant';
import { IUserActionState, UserActionActionsTypes } from './UserAction.type';

const initialState: IUserActionState = {
  userActionList: [],
  meta: META_DEFAULT,
  loading: false,
};

export default function scheduleReducer(
  state = initialState,
  action: UserActionActionsTypes,
): IUserActionState {
  switch (action.type) {
    case GET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_SUCCESS: {
      return {
        ...state,
        userActionList: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      };
    }
    case GET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_LIST: {
      return {
        ...state,
        userActionList: [],
      };
    }
    default:
      return state;
  }
}
