import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullSpecialty } from '@entities/Specialty';
import { SpecialtyHttp } from '@services/http';

import {
  getListSpecialtyFailure,
  getListSpecialtySuccess,
} from './Specialty.action';
import { GET_LIST_SPECIALTY_REQUEST } from './Specialty.constant';

function* workerGetListSpecialty() {
  try {
    const res: DataResponse<FullSpecialty[]> = yield call(
      SpecialtyHttp.getList,
    );

    yield put(getListSpecialtySuccess(res));
  } catch (error) {
    yield put(getListSpecialtyFailure());
  }
}

function* watchGetListSpecialty() {
  yield takeLatest(GET_LIST_SPECIALTY_REQUEST, workerGetListSpecialty);
}

export const specialtyWatchers: ForkEffect[] = [fork(watchGetListSpecialty)];
