import { useCallback, useEffect } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import {
  clearListTransaction,
  getListTransactionRequest,
} from '@store/Transaction';
import { UIHeader, UITable } from '@ui';
import { TransactionOperation } from '@utils';

import { columns, filterOptions, orderOption } from '../constant';

const PayoutList = () => {
  const dispatch = useAppDispatch();
  const { query, updateQuery } = useQuery({
    filter: { txnOperation: TransactionOperation.WITHDRAWAL },
  });
  const { transactionList, meta, loading } = useSelector(
    (state: RootState) => state.transaction,
  );

  const getRequest = useCallback(() => {
    dispatch(getListTransactionRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  useEffect(() => {
    return () => {
      dispatch(clearListTransaction());
    };
  }, [dispatch]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <>
      <UIHeader
        title={i18next.t('Payouts')}
        isSearch
        searchTitle={i18next.t('Filter payout')}
        sortOption={orderOption}
        filterOptions={filterOptions}
        viewContainerName={i18next.t('Payouts')}
      />
      <Row>
        <UITable
          dataSource={transactionList}
          loading={loading}
          columns={columns()}
          meta={meta}
          onPaginationChange={onPaginationChange}
          isScrollDefault
        />
      </Row>
    </>
  );
};

export default PayoutList;
