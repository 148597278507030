import { useCallback } from 'react';

import { useAppDispatch } from '@store/index';
import { showModal } from '@store/Modal';
import { Button as ButtonType } from '@utils';

import UIButton from '../../../Button';
import { Props } from './index.type';

const UIViewHeaderButton = (props: Props) => {
  const { children, modalType, type } = props;

  const dispatch = useAppDispatch();

  const onClickHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType,
      }),
    );
  }, [dispatch, modalType]);

  return (
    <UIButton
      typeAntBtn={type}
      type={ButtonType.ANT_BUTTON}
      handler={onClickHandler}
    >
      {children}
    </UIButton>
  );
};

export default UIViewHeaderButton;
