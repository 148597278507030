import { FullUserAction } from '@entities/UserAction';

import {
  CLEAR_LIST,
  GET_LIST_FAILURE,
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
} from './UserAction.constant';
import { UserActionActionsTypes } from './UserAction.type';

export const clearList = (): UserActionActionsTypes => ({
  type: CLEAR_LIST,
});

export function getListFailure(): UserActionActionsTypes {
  return {
    type: GET_LIST_FAILURE,
  };
}

export function getListRequest(query = ''): UserActionActionsTypes {
  return {
    type: GET_LIST_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListSuccess(
  payload: DataResponse<FullUserAction[]>,
): UserActionActionsTypes {
  return {
    type: GET_LIST_SUCCESS,
    payload,
  };
}
