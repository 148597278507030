export const LINK_BOOKING = 'bookings';
export const LINK_CHAT = 'chats';
export const LINK_CLIENT = 'clients';
export const LINK_COLLECTION = 'collections';
export const LINK_COUPON = 'coupons';
export const LINK_COUPON_ADD = 'add';
export const LINK_DASHBOARD = 'dashboard';
export const LINK_EXPERT = 'psychologists';
export const LINK_LOGIN = 'login';
export const LINK_PAYOUT = 'payouts';
export const LINK_REVIEW = 'reviews';
export const LINK_SETTING = 'settings';
export const LINK_TRANSACTION = 'transactions';
export const LINK_COMMISSION = 'commissions';
export const LINK_COMMISSION_ADD = 'add';
