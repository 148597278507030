import {
  REFUND_FAILURE,
  REFUND_REQUEST,
  REFUND_SUCCESS,
} from './Payment.constant';
import { IPaymentState, PaymentActionsTypes } from './Payment.type';

const initialState: IPaymentState = {
  loading: false,
};

export default function paymentReducer(
  state = initialState,
  action: PaymentActionsTypes,
): IPaymentState {
  switch (action.type) {
    case REFUND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REFUND_FAILURE:
    case REFUND_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
