import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { QueryHelper, QueryOptions } from '@utils';

type QueryCtx = {
  encode: boolean;
};

const useQuery = (data?: QueryOptions, ctx?: Partial<QueryCtx>) => {
  const option = {
    addQueryPrefix: false,
    ...(ctx?.encode && { encode: ctx.encode }),
  };
  const query = data ? `&${QueryHelper.stringify(data, option)}` : '';

  const [queryOptions, setQueryOptions] = useState<QueryOptions>({});

  const havigate = useNavigate();
  const { search } = useLocation();

  const updateQuery = (options: QueryOptions) => {
    const fullFilter = options.filter && {
      ...queryOptions.filter,
      ...options.filter,
    };

    havigate({
      search: QueryHelper.stringify(
        { ...queryOptions, ...options, filter: fullFilter },
        {
          ...(ctx?.encode && { encode: ctx.encode }),
        },
      ),
    });
  };

  const setQuery = (options: QueryOptions) => {
    havigate({
      search: QueryHelper.stringify(options, {
        ...(ctx?.encode && { encode: ctx.encode }),
      }),
    });
  };

  useEffect(() => {
    setQueryOptions(QueryHelper.parse(search));
  }, [search]);

  return {
    query: `?${search.slice(1)}${query}`,
    queryOptions,
    setQuery,
    updateQuery,
  };
};

export default useQuery;
