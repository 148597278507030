import { ColumnType, ColumnsType } from 'antd/lib/table';

import { ChatUser, FilterOption, FullChat, SortOption } from '@entities/index';
import { i18next } from '@i18n';
import { UICellFullName, UICellLink, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_LONG_TEXT,
  COLUMN_WIDTH_NAME,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  LINK_CHAT,
  LINK_CLIENT,
  LINK_EXPERT,
  Role,
} from '@utils';

const clientColumn: ColumnType<FullChat> = {
  title: i18next.t('Client'),
  dataIndex: 'user',
  key: 'user',
  render: (value: ChatUser) =>
    UICellFullName({
      lastName: value?.profile?.lastName || '',
      firstName: value?.profile?.firstName || '',
      src: value?.avatar?.path || '',
      link: `/${LINK_CLIENT}/${value.id}`,
    }),
  width: COLUMN_WIDTH_NAME,
};

const angelColumn: ColumnType<FullChat> = {
  title: i18next.t('Expert'),
  dataIndex: 'angel',
  key: 'angel',
  render: (value: ChatUser) =>
    UICellFullName({
      lastName: value?.profile?.lastName || '',
      firstName: value?.profile?.firstName || '',
      src: value?.avatar?.path || '',
      link: `/${LINK_EXPERT}/${value.id}`,
    }),
  width: COLUMN_WIDTH_NAME,
};

const renderColumn = (role?: Role.ANGEL | Role.USER) => {
  if (role === Role.ANGEL) {
    return [clientColumn];
  }

  if (role === Role.USER) {
    return [angelColumn];
  }

  return [angelColumn, clientColumn];
};

export const columns = (
  role?: Role.ANGEL | Role.USER,
): ColumnsType<FullChat> => [
  {
    title: i18next.t('Id'),
    dataIndex: 'id',
    key: 'id',
    render: (value: number) =>
      UICellLink({ value, link: `/${LINK_CHAT}/${value}` }),
    width: COLUMN_WIDTH_ID,
    fixed: 'left',
  },
  ...renderColumn(role),
  {
    title: i18next.t('Date'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value: string) =>
      DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
    width: COLUMN_WIDTH_FULL_DATE,
  },
  {
    title: i18next.t('Text'),
    dataIndex: 'message',
    width: COLUMN_WIDTH_LONG_TEXT,
    key: 'message',
    ellipsis: true,
    render: (text) => UICellTooltip({ text }),
  },
];

const clientSort: SortOption[] = [
  {
    label: i18next.t('Client name (A-Z)'),
    name: 'user_full_name',
    direction: 'asc',
  },
  {
    label: i18next.t('Client name (Z-A)'),
    name: 'user_full_name',
    direction: 'desc',
  },
];

const angelSort: SortOption[] = [
  {
    label: i18next.t('Expert name (A-Z)'),
    name: 'angel_full_name',
    direction: 'asc',
  },
  {
    label: i18next.t('Expert name (Z-A)'),
    name: 'angel_full_name',
    direction: 'desc',
  },
];

const renderSort = (role?: Role) => {
  if (role === Role.ANGEL) {
    return clientSort;
  }

  if (role === Role.USER) {
    return angelSort;
  }

  return [...clientSort, ...angelSort];
};

export const sortOption = ({ role }: { role?: Role }): SortOption[] => [
  {
    label: i18next.t('Id (ascending)'),
    name: 'id',
    direction: 'asc',
  },
  {
    label: i18next.t('Id (descending)'),
    name: 'id',
    direction: 'desc',
  },
  ...renderSort(role),
  {
    label: i18next.t('Date (oldest first)'),
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: i18next.t('Date (newest first)'),
    name: 'created_at',
    direction: 'desc',
  },
];

export const filterOptions: FilterOption[] = [
  {
    name: 'created_at',
    label: i18next.t('Date'),
    type: 'radio-range',
    options: [
      {
        label: i18next.t('Today'),
        value: {
          min: DateHelper.getStartDayFormatString({ format: FORMAT_DATE }),
          max: DateHelper.getEndDayFormatString({ format: FORMAT_DATE }),
        },
      },
      {
        label: i18next.t('Last 7 days'),
        value: {
          min: DateHelper.getStartDayFormatString({
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({ format: FORMAT_DATE }),
        },
      },
      {
        label: i18next.t('Last 30 days'),
        value: {
          min: DateHelper.getStartDayFormatString({
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({ format: FORMAT_DATE }),
        },
      },
    ],
  },
];
