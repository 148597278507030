import { Props } from './index.type';

import './style.scss';

const UserUISquare = (props: Props) => {
  const { title, value } = props;

  return (
    <div className="user-square">
      <span>{title}</span>
      <span>{value}</span>
    </div>
  );
};

export default UserUISquare;
