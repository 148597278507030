import { FullCoupon, RequestCoupon } from '@entities/Coupon';
import { http } from '@utils';

const basePath = '/api/coupons';

export const getList = async (
  query?: string,
): Promise<DataResponse<FullCoupon[]>> => {
  const { data } = await http.get<DataResponse<FullCoupon[]>>(
    `${basePath}${query || ''}`,
  );

  return data;
};

export const getOne = async (id: number): Promise<DataResponse<FullCoupon>> => {
  const { data } = await http.get<DataResponse<FullCoupon>>(
    `${basePath}/${id}`,
  );

  return data;
};

export const create = async (
  body: Omit<RequestCoupon, 'isActive'>,
): Promise<DataResponse<FullCoupon>> => {
  const { data } = await http.post<DataResponse<FullCoupon>>(basePath, body);

  return data;
};

export const update = async (
  id: number,
  body: Partial<RequestCoupon>,
): Promise<DataResponse<FullCoupon>> => {
  const { data } = await http.put<DataResponse<FullCoupon>>(
    `${basePath}/${id}`,
    body,
  );

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};
