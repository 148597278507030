import { useEffect } from 'react';

import { Button, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { RequestCoupon } from '@entities/Coupon';
import { i18next } from '@i18n';
import { LayoutContent } from '@layouts';
import {
  createCouponRequest,
  getOneCouponRequest,
  resetCouponOne,
  updateCouponRequest,
} from '@store/Coupon';
import { RootState, useAppDispatch } from '@store/index';
import { UIHeader } from '@ui';
import { LINK_COUPON } from '@utils';

import CouponDetailFrom from './Form';
// import CouponDetailHistory from './History';
import CouponDetailSummary from './Summary';

import './style.scss';

const CouponDetail = () => {
  const { couponId } = useParams<'couponId'>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { coupon, loading } = useSelector((state: RootState) => state.coupon);

  useEffect(() => {
    if (couponId && coupon?.id !== +couponId) {
      dispatch(getOneCouponRequest(+couponId));
    }
  }, [dispatch, couponId, coupon?.id]);

  useEffect(() => {
    if (coupon?.id) {
      navigate(`/${LINK_COUPON}/${coupon.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon?.id]);

  const createCoupon = (values: RequestCoupon) => {
    dispatch(createCouponRequest(values));
  };

  const updateCoupon = (values: Partial<RequestCoupon>) => {
    if (couponId) {
      dispatch(updateCouponRequest(+couponId, values));
    }
  };

  const onFormSubmitHandler = (values: RequestCoupon) => {
    if (couponId) {
      updateCoupon(values);
    } else {
      createCoupon(values);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetCouponOne());
    };
  }, [dispatch]);

  const aside = [
    <>
      {coupon && (
        <>
          <CouponDetailSummary coupon={coupon} />
          {/* <div className="discount-wrapper">
            <CouponDetailHistory />
          </div> */}
          <Button
            type="primary"
            className="discount-add__delete"
            onClick={() => {
              updateCoupon({ isActive: !coupon?.isActive });
            }}
            {...(coupon?.isActive && { danger: true })}
          >
            {coupon?.isActive
              ? i18next.t('Disable coupon')
              : i18next.t('Active coupon')}
          </Button>
        </>
      )}
    </>,
  ];

  return (
    <Spin spinning={loading}>
      <UIHeader
        isBack
        backLink={`/${LINK_COUPON}`}
        title={coupon?.code ? i18next.t('Detail') : i18next.t('Add Coupon')}
      />
      <LayoutContent
        content={[
          <CouponDetailFrom
            coupon={coupon}
            onFormSubmit={onFormSubmitHandler}
          />,
        ]}
        aside={aside}
      />
    </Spin>
  );
};

export default CouponDetail;
