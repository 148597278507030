import {
  AddUpdateCommission,
  DetailCommission,
  FullCommission,
} from '@entities/index';
import { http } from '@utils';

const basePath = '/api/commissions';

export const getList = async (): Promise<DataResponse<FullCommission[]>> => {
  const { data } = await http.get<DataResponse<FullCommission[]>>(basePath);

  return data;
};

export const getOneAndUsers = async (
  id: number,
  query?: string,
): Promise<{
  data: DetailCommission;
  meta: MetaType;
}> => {
  const { data } = await http.get<DataResponse<DetailCommission>>(
    `${basePath}/${id}${query || ''}`,
  );

  return data;
};

export const addUpdate = async (
  body: AddUpdateCommission,
): Promise<DataResponse<DetailCommission>> => {
  const { data } = await http.post<DataResponse<DetailCommission>>(
    `${basePath}`,
    body,
  );

  return data;
};

export const deleteCommission = async (id: number): Promise<void> => {
  await http.delete<DataResponse<void>>(`${basePath}/${id}`);
};
