import { META_DEFAULT } from '@utils';

import {
  CREATE_COUPON_FAILURE,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  GET_LIST_COUPON_FAILURE,
  GET_LIST_COUPON_REQUEST,
  GET_LIST_COUPON_SUCCESS,
  GET_ONE_COUPON_FAILURE,
  GET_ONE_COUPON_REQUEST,
  GET_ONE_COUPON_SUCCESS,
  REMOVE_COUPON_FAILURE,
  REMOVE_COUPON_REQUEST,
  REMOVE_COUPON_SUCCESS,
  RESET_COUPON_LIST,
  RESET_COUPON_ONE,
  UPDATE_COUPON_FAILURE,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
} from './Coupon.constant';
import { CouponActionsTypes, ICouponState } from './Coupon.type';

const initialState: ICouponState = {
  couponList: [],
  coupon: null,
  meta: META_DEFAULT,
  loading: false,
};

export default function couponReducer(
  state = initialState,
  action: CouponActionsTypes,
): ICouponState {
  switch (action.type) {
    case GET_LIST_COUPON_REQUEST:
    case CREATE_COUPON_REQUEST:
    case UPDATE_COUPON_REQUEST:
    case REMOVE_COUPON_REQUEST:
    case GET_ONE_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_COUPON_SUCCESS:
      return {
        ...state,
        couponList: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      };
    case GET_ONE_COUPON_SUCCESS:
    case CREATE_COUPON_SUCCESS:
    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.payload.data,
        loading: false,
      };
    case GET_LIST_COUPON_FAILURE:
    case CREATE_COUPON_FAILURE:
    case REMOVE_COUPON_SUCCESS:
    case UPDATE_COUPON_FAILURE:
    case REMOVE_COUPON_FAILURE:
    case GET_ONE_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RESET_COUPON_LIST:
      return {
        ...state,
        couponList: [],
        meta: META_DEFAULT,
        loading: false,
      };
    case RESET_COUPON_ONE:
      return {
        ...state,
        coupon: null,
        loading: false,
      };
    default:
      return state;
  }
}
