import { useCallback, useEffect } from 'react';

import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { i18next } from '@i18n';
import { getListCommissionRequest } from '@store/Commission';
import { RootState, useAppDispatch } from '@store/index';
import { UIHeader, UITable } from '@ui';
import { LINK_COMMISSION_ADD } from '@utils';

import { columns } from './constants';

const CommissionList = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { commissionList, loading } = useSelector(
    (state: RootState) => state.commission,
  );

  const getRequest = useCallback(() => {
    dispatch(getListCommissionRequest());
  }, [dispatch]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  const onButtonClick = () => {
    navigate(`${pathname}/${LINK_COMMISSION_ADD}`);
  };

  const headerButtons = (
    <Button size="large" type="primary" onClick={onButtonClick}>
      {i18next.t('Add commission')}
    </Button>
  );

  return (
    <>
      <UIHeader
        title={i18next.t('Commissions')}
        buttons={headerButtons}
        searchTitle={i18next.t('Filter commissions')}
        viewContainerName={i18next.t('Commissions')}
      />
      <Row>
        <UITable
          dataSource={commissionList}
          loading={loading}
          columns={columns({ path: pathname, dispatch })}
        />
      </Row>
    </>
  );
};

export default CommissionList;
