/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { EuroCircleOutlined, PercentageOutlined } from '@ant-design/icons';
import { Col, Form, Input, InputNumber, Radio, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { CouponCodeType } from '@entities/Coupon';
import { i18next } from '@i18n';

import { CouponUIItemAdd } from '../../../ui';

import './style.scss';

const CouponDetailCreation = () => {
  return (
    <CouponUIItemAdd title={i18next.t('Creation')}>
      <div className="creation-wrapper">
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <Text type="secondary">{i18next.t('Coupon code')}</Text>
          </Col>
        </Row>
        <Form.Item
          name="code"
          required
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Title level={5}>{i18next.t('Types')}</Title>
            <Form.Item name="codeType">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={CouponCodeType.PERCENTAGE}>
                    {i18next.t('Percentage of the cost')}
                  </Radio>
                  <Radio value={CouponCodeType.FIX_AMOUNT}>
                    {i18next.t('Fixed amount')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className="discount-value">
          <Col span={24}>
            <Text type="secondary">{i18next.t('Coupon value')}</Text>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.codeType !== currentValues.codeType
              }
            >
              {({ getFieldValue: getValue }) => (
                <Form.Item
                  name="value"
                  required
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 1,
                      type: 'number',
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    addonAfter={
                      getValue('codeType') === CouponCodeType.PERCENTAGE ? (
                        <PercentageOutlined />
                      ) : (
                        <EuroCircleOutlined />
                      )
                    }
                    {...(getValue('codeType') === CouponCodeType.PERCENTAGE && {
                      max: 100,
                    })}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </CouponUIItemAdd>
  );
};

export default CouponDetailCreation;
