import { Route, Routes } from 'react-router-dom';

import User from '@modules/User';
import UserDetail from '@modules/User/Detail';
import { Role } from '@utils';

const ViewClient = () => {
  return (
    <Routes>
      <Route path=":userId" element={<UserDetail role={Role.USER} />} />
      <Route path="/" element={<User role={Role.USER} />} />
    </Routes>
  );
};

export default ViewClient;
