import { useSelector } from 'react-redux';

import { RootState } from '@store/index';
import { ModalType } from '@store/Modal';

import WidgetModalAddUserToCollection from './AddUserToCollection';
import { WidgetModalBookingEdit, WidgetModalBookingRefund } from './Booking';
import WidgetModalCollection from './Collection';
import WidgetModalConfirm from './Confirm';
import WidgetModalCreateTransaction from './CreateTransaction';
import UIModalFilter from './Filter';
import WidgetModalProfile from './Profile';
import { WidgetModalReviewCreate, WidgetModalReviewEdit } from './Review';
import WidgetModalUser from './User';

const WidgetModal = () => {
  const { modalType, modalProps } = useSelector(
    (state: RootState) => state.modal,
  );

  switch (modalType) {
    case ModalType.FILTER:
      return <UIModalFilter {...modalProps} />;
    case ModalType.PROFILE:
      return <WidgetModalProfile {...modalProps} />;
    case ModalType.USER:
      return <WidgetModalUser {...modalProps} />;
    case ModalType.CONFIRM:
      return <WidgetModalConfirm {...modalProps} />;
    case ModalType.CREATE_COLLECTION:
      return <WidgetModalCollection {...modalProps} />;
    case ModalType.CREATE_REVIEW:
      return <WidgetModalReviewCreate />;
    case ModalType.EDIT_REVIEW:
      return <WidgetModalReviewEdit {...modalProps} />;
    case ModalType.BOOKING_EDIT:
      return <WidgetModalBookingEdit {...modalProps} />;
    case ModalType.BOOKING_REFUND:
      return <WidgetModalBookingRefund {...modalProps} />;
    case ModalType.ADD_USER_TO_COLLECTION:
      return <WidgetModalAddUserToCollection {...modalProps} />;
    case ModalType.CREATE_TRANSACTION:
      return <WidgetModalCreateTransaction {...modalProps} />;
    default:
      return null;
  }
};

export default WidgetModal;
