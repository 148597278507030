import { Fragment } from 'react';

import { Col, Divider, Row } from 'antd';

import { Props } from './index.type';

import './style.scss';

const LayoutContainerDivider = (props: Props) => {
  const { children } = props;

  const renderChildren = () =>
    children.map((item, index) => (
      <Fragment key={`divider_${index}`}>
        <Col span={24} className="divider-container__cell">
          {item}
        </Col>
        {index !== children.length - 1 && <Divider />}
      </Fragment>
    ));

  return <Row className="divider-container">{renderChildren()}</Row>;
};

export default LayoutContainerDivider;
