import { StarOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';

import { i18next } from '@i18n';

import UIViewOverlay from '../Overlay';
import { Props } from './index.type';

const UIViewButton = (props: Props) => {
  return (
    <Dropdown overlay={<UIViewOverlay {...props} />} placement="bottomRight">
      <Button type="primary" size="large" icon={<StarOutlined />}>
        {i18next.t('Save view')}
      </Button>
    </Dropdown>
  );
};

export default UIViewButton;
