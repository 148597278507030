import { Route, Routes } from 'react-router-dom';

import { CollectionDetail, CollectionList } from '@modules/Collection';

const ViewCollection = () => {
  return (
    <Routes>
      <Route path=":collectionId" element={<CollectionDetail />} />
      <Route path="/" element={<CollectionList />} />
    </Routes>
  );
};

export default ViewCollection;
