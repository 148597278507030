import {
  CLEAR_SESSION,
  GET_SESSION_FAILURE,
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
} from './Statistic.constant';
import { ISessionState, SessionActionsTypes } from './Statistic.type';

const initialState: ISessionState = {
  session: [],
  loading: false,
};

export default function scheduleReducer(
  state = initialState,
  action: SessionActionsTypes,
): ISessionState {
  switch (action.type) {
    case GET_SESSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SESSION_SUCCESS: {
      return {
        ...state,
        session: action.payload,
        loading: false,
      };
    }
    case GET_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_SESSION: {
      return {
        ...state,
        session: [],
      };
    }
    default:
      return state;
  }
}
