import { HIDE_MODAL, SHOW_MODAL } from './Modal.constant';
import { IModalState, ModalActionsTypes } from './Modal.type';

export function showModal(
  payload: Pick<IModalState, 'modalProps' | 'modalType'>,
): ModalActionsTypes {
  return {
    type: SHOW_MODAL,
    payload,
  };
}

export function hideModal(): ModalActionsTypes {
  return { type: HIDE_MODAL };
}
