export default (() => {
  const set = <T>(key: string, value: T): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const get = <T>(key: string): T | null => {
    const value = localStorage.getItem(key);

    if (!value) {
      return null;
    }

    return JSON.parse(value) as T;
  };

  const clear = (key: string): void => {
    localStorage.removeItem(key);
  };

  return {
    set,
    get,
    clear,
  };
})();
