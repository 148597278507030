import { Tag } from 'antd';
import Title from 'antd/lib/typography/Title';

import { Props } from './index.type';

import './style.scss';

const UserUIItemInfo = ({ title, data }: Props) => {
  const renderInfoItem = () =>
    data?.map(({ name, id }) => (
      <Tag key={`info_item_${id}`} color="blue">
        {name}
      </Tag>
    ));

  return (
    <>
      <Title level={5} className="item-info__title">
        {title}
      </Title>
      {renderInfoItem()}
    </>
  );
};

export default UserUIItemInfo;
