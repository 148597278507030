export const GET_LIST_BOOKING_REQUEST = '@booking/GET_LIST_BOOKING_REQUEST';
export const GET_LIST_BOOKING_SUCCESS = '@booking/GET_LIST_BOOKING_SUCCESS';
export const GET_LIST_BOOKING_FAILURE = '@booking/GET_LIST_BOOKING_FAILURE';

export const GET_ONE_BOOKING_REQUEST = '@booking/GET_ONE_BOOKING_REQUEST';
export const GET_ONE_BOOKING_SUCCESS = '@booking/GET_ONE_BOOKING_SUCCESS';
export const GET_ONE_BOOKING_FAILURE = '@booking/GET_ONE_BOOKING_FAILURE';

export const UPDATE_BOOKING_REQUEST = '@booking/UPDATE_BOOKING_REQUEST';
export const UPDATE_BOOKING_SUCCESS = '@booking/UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILURE = '@booking/UPDATE_BOOKING_FAILURE';
