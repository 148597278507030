import { LayoutMenu } from '@layouts';
import { deleteUserFromCollectionRequest } from '@store/Collection';
import { ModalType, showModal } from '@store/Modal';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const CollectionUIActionDetail = ({
  id,
  collectionId,
  query,
  dispatch,
}: Props) => {
  const onDeleteHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: (
            <p>
              Are you sure you want to delete a psychologist from Collection
            </p>
          ),
          action: () =>
            dispatch(
              deleteUserFromCollectionRequest({
                id: collectionId,
                userId: id,
                query,
              }),
            ),
        },
      }),
    );
  };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [{ title: 'Delete from collection', onClick: onDeleteHandler }],
    }),
  });
};

export default CollectionUIActionDetail;
