import './style.scss';
import { EditOutlined, LockOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Space } from 'antd';
import Link from 'antd/lib/typography/Link';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { i18next } from '@i18n';
import { useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { resetUserPasswordRequest } from '@store/User';
import { UIAvatar, UICellRate, UIDetailRow, UITag } from '@ui';
import { DateHelper, FORMAT_SHORT_DATE, Role, StringHelper } from '@utils';

import { UserUIExperience, UserUISquare, UserUITariff } from '../../ui';
import { Props } from './index.type';

const UserDetailProfile = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    user: {
      profile,
      id,
      avatar,
      createdAt,
      email,
      isActive,
      rating,
      noRate,
      cancellationRate,
      moneyEarned,
      moneySpent,
      bookingCount,
      balance,
      votes,
      tariff,
    },
    role,
  } = props;

  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.USER,
        modalProps: { userId: id, query: '', role },
      }),
    );
  };

  const onResetPassword = () => {
    dispatch(resetUserPasswordRequest(id));
  };

  const isActiveValues = {
    true: {
      title: i18next.t('Active'),
      color: 'green',
    },
    false: {
      title: i18next.t('Inactive'),
      color: 'red',
    },
  };

  const isUser = role === Role.USER;

  return (
    <div className="user-profile">
      <Row
        justify="space-between"
        className="user-profile__container"
        id="user-profile-header"
        align="middle"
      >
        <Col span={4}>
          <UIAvatar path={avatar?.path} />
        </Col>
        <Col span={16}>
          <Space direction="vertical" size={0}>
            <Text strong>{profile?.fullName || ''}</Text>
          </Space>
        </Col>
        <Col span={4}>
          <UITag value={`${isActive}`} values={isActiveValues} />
        </Col>
      </Row>
      {!isUser && (
        <Row className="user-profile__container" align="middle" gutter={16}>
          <Col>
            <UICellRate rating={rating || 0} votes={votes || 0} />
          </Col>
          {tariff && (
            <Col>
              <UserUITariff tariff={tariff} />
            </Col>
          )}
          {!!profile?.experience && (
            <Col>
              <UserUIExperience experience={profile.experience} />
            </Col>
          )}
        </Row>
      )}
      <Divider />
      <Row
        className="user-profile__container user-profile__container--top"
        justify="space-between"
        align="stretch"
        gutter={32}
      >
        <Col span={12}>
          {isUser ? (
            <UserUISquare
              title={i18next.t('Spent')}
              value={StringHelper.toMoney(moneySpent)}
            />
          ) : (
            <UserUISquare
              title={i18next.t('Earned')}
              value={StringHelper.toMoney(moneyEarned)}
            />
          )}
        </Col>
        <Col span={12}>
          <UserUISquare
            title={i18next.t('Booking Count')}
            value={bookingCount || 0}
          />
        </Col>
      </Row>
      <UIDetailRow
        title={i18next.t('Balance')}
        children={StringHelper.toMoney(balance ? balance?.amount : 0)}
        className="user-profile__container"
      />
      <UIDetailRow
        title={i18next.t('No show rate')}
        children={StringHelper.toMoney(noRate)}
        className="user-profile__container"
      />
      <UIDetailRow
        title={i18next.t('Cancellation rate')}
        children={StringHelper.toMoney(cancellationRate)}
        className="user-profile__container"
      />
      <Divider />
      <Row
        className="user-profile__container"
        justify="space-between"
        wrap={false}
        align="middle"
      >
        <Col span={9}>
          <Title level={5}>{i18next.t('Overview')}</Title>
        </Col>
        <Col>
          <Link onClick={onResetPassword}>
            <LockOutlined />
            {i18next.t('Reset password')}
          </Link>
        </Col>
        <Col>
          <Link onClick={onEditClickHandler}>
            <EditOutlined />
            {i18next.t('Edit profile')}
          </Link>
        </Col>
      </Row>
      <UIDetailRow
        title={i18next.t('Email')}
        children={email}
        className="user-profile__container"
      />
      <UIDetailRow
        title={i18next.t('Reg Date')}
        children={DateHelper.formateDateToString(createdAt, FORMAT_SHORT_DATE)}
        className="user-profile__container"
      />
    </div>
  );
};

export default UserDetailProfile;
