/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  message,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import type { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';

import { academicInitialList, healsRoleList } from '@data/PreferenceOptions';
import { FullMedia, FullSpecialty } from '@entities/index';
import { getLanguage, i18next } from '@i18n';
import { RootState } from '@store/index';
import { UIAntDatePicker } from '@ui';
import {
  DateHelper,
  FORMAT_DATE,
  FORMAT_SHORT_DATE,
  Gender,
  Role,
  StringHelper,
  VALIDATE_MAX_PHONE,
  VALIDATE_MAX_SEO_DESCRIPTION,
  VALIDATE_MAX_SEO_TITLE,
  VALIDATE_MAX_USERNAME,
  VALIDATE_MIN_BIRTHDAY,
} from '@utils';

import validation from '../../../../i18n/validation';
import { WidgetModalUIImage, WidgetModalUIUploadFile } from '../../ui';
import { INITIAL_VALUES_ANGEL, INITIAL_VALUES_USER } from './constant';
import { FormAngel, FormUser, Props } from './index.type';

import './style.scss';

const { Option } = Select;

const WidgetModaUserForm = ({
  userId,
  user,
  avatar,
  greeting,
  onFormSubmit,
  form,
  onUploadImage,
  onRemoveImage,
  role,
}: Props) => {
  const { timezoneList } = useSelector((state: RootState) => state.timezone);
  const { languageList } = useSelector((state: RootState) => state.language);
  const { therapyList } = useSelector((state: RootState) => state.therapy);
  const { specialtyList } = useSelector((state: RootState) => state.specialty);
  const [specialtyListState, setSpecialtyList] = useState(specialtyList);

  const { commissionList } = useSelector(
    (state: RootState) => state.commission,
  );

  const [activeKey, setActiveKey] = useState('about-lang-en');

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const getContentListingClass = () => {
    return activeKey === 'about-lang-en'
      ? 'content-listing-en'
      : 'content-listing-ru';
  };

  useEffect(() => {
    if (getLanguage() === 'ru') {
      const newState = specialtyList.map((item: FullSpecialty) => ({
        ...item,
        name: item.name_ru || item.name,
      }));

      setSpecialtyList(newState);
    }
  }, []);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        ...user,
        profile: {
          ...user.profile,
          ...(user?.profile?.birthday && {
            birthday: DateHelper.stringToDayjs(user?.profile?.birthday),
          }),
          ...(user?.profile && {
            academicInitials:
              user.profile.academicInitials === ''
                ? 'noAcademicInitials'
                : user.profile.academicInitials,
          }),
        },
        ...(user?.therapies && {
          therapies: user.therapies.map((item) => item.id),
        }),
        ...(user?.languages && {
          languages: user.languages.map((item) => item.id),
        }),
        ...(user?.specialties && {
          specialties: user.specialties.map((item) => item.id),
        }),
        avatar,
        greeting,
      });
    } else {
      form.setFieldsValue({ avatar, greeting });
    }
  }, [avatar, form, user, greeting]);

  const INITIAL_VALUES =
    role === Role.ANGEL ? INITIAL_VALUES_ANGEL : INITIAL_VALUES_USER;

  const handlerFinish = (values: FormAngel | FormUser) => {
    const angelValue = values as FormAngel;

    const therapies = angelValue?.therapies;
    const languages = angelValue?.languages;
    const specialties = angelValue?.specialties;
    const seo = angelValue?.seo;
    const tariff = angelValue?.tariff;
    const file = angelValue?.greeting as UploadChangeParam<FullMedia> | null;
    const healthRole = angelValue?.profile.healthRole as string | null;
    const academicInitials = angelValue?.profile.academicInitials as
      | string
      | null;

    onFormSubmit({
      ...values,
      profile: {
        ...values.profile,
        ...(values?.profile?.birthday && {
          birthday: DateHelper.formateDateToString(
            values.profile.birthday,
            FORMAT_DATE,
          ),
        }),
        ...(healthRole && {
          healthRole_ru: i18next.t(healthRole),
        }),
        ...(academicInitials && {
          academicInitials:
            academicInitials !== 'noAcademicInitials' ? academicInitials : '',
        }),
      },
      role,
      ...(therapies && {
        therapies: therapies.map((id) => ({ id })),
      }),
      ...(languages && {
        languages: languages.map((id) => ({ id })),
      }),
      ...(specialties && {
        specialties: specialties.map((id) => ({ id })),
      }),
      ...(tariff && {
        tariff: {
          ...user?.tariff,
          ...tariff,
        },
      }),
      ...(seo && {
        seo: {
          ...user?.seo,
          ...seo,
        },
      }),
      ...(role === Role.ANGEL && {
        greeting: file?.fileList?.length !== 0 ? greeting : null,
      }),
    });
  };

  const getQualificationCode = (key: string | number) => {
    return StringHelper.capitalize(
      form.getFieldValue('qualifications')?.[key]?.code as string,
    );
  };

  const disabledDate = (current: Dayjs) =>
    DateHelper.dateIsBefore(current.toISOString(), VALIDATE_MIN_BIRTHDAY);

  const onFinishFailed = () => {
    message.error({
      content: i18next.t('The form is incomplete, please see the tabs!'),
      duration: 4,
    });
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 18 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      onFinishFailed={onFinishFailed}
      className="modal-create"
      autoComplete="off"
      validateMessages={validation.messages}
    >
      <WidgetModalUIImage
        view="avatar"
        path={avatar?.path}
        onUploadImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      <Tabs className="user-edit-tab" defaultActiveKey="1" size={'small'}>
        <Tabs.TabPane tab={i18next.t('Profile')} key="1" forceRender>
          <Form.Item
            label={i18next.t('Username')}
            name={['username']}
            required
            rules={[
              {
                required: role === Role.ANGEL,
                whitespace: role === Role.ANGEL,
              },
            ]}
          >
            <Input placeholder={i18next.t('Username')} />
          </Form.Item>
          <Form.Item
            label={i18next.t('First Name')}
            name={['profile', 'firstName']}
            required
            rules={[
              { required: true, whitespace: true },
              { max: VALIDATE_MAX_USERNAME, type: 'string' },
            ]}
          >
            <Input
              showCount
              placeholder={i18next.t('First Name')}
              maxLength={VALIDATE_MAX_USERNAME}
            />
          </Form.Item>
          <Form.Item
            label={i18next.t('Last name')}
            name={['profile', 'lastName']}
            required
            rules={[
              { required: true, whitespace: true },
              { max: VALIDATE_MAX_USERNAME, type: 'string' },
            ]}
          >
            <Input
              showCount
              placeholder={i18next.t('Last name')}
              maxLength={VALIDATE_MAX_USERNAME}
            />
          </Form.Item>
          <Form.Item
            label={i18next.t('Email')}
            name="email"
            required
            rules={[
              {
                required: true,
                whitespace: true,
                type: 'email',
              },
            ]}
          >
            <Input placeholder={i18next.t('Email')} autoComplete="off" />
          </Form.Item>
          <Form.Item
            label={i18next.t('Phone')}
            name="phone"
            rules={[{ max: VALIDATE_MAX_PHONE }]}
          >
            <Input placeholder={i18next.t('Phone')} />
          </Form.Item>
          {!userId && (
            <Form.Item
              label={i18next.t('Password')}
              name="password"
              rules={[
                { required: true, whitespace: true },
                { min: 6, type: 'string' },
              ]}
            >
              <Input.Password
                placeholder={i18next.t('Password')}
                autoComplete="off"
              />
            </Form.Item>
          )}
          <Form.Item
            label={i18next.t('Birthday')}
            name={['profile', 'birthday']}
            required
            className="modal-create__birthday"
          >
            <UIAntDatePicker
              placeholder={i18next.t('Birthday')}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item
            label={i18next.t('Gender')}
            name="gender"
            rules={[{ required: true }]}
          >
            <Select
              optionFilterProp="children"
              placeholder={i18next.t('Gender')}
            >
              {Object.values(Gender).map((gen) => (
                <Option
                  value={gen}
                  key={`gender_${gen}`}
                  label={i18next.t(StringHelper.capitalize(gen))}
                >
                  {i18next.t(StringHelper.capitalize(gen))}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {role === Role.ANGEL && (
            <Form.Item label="LGBTQ+" name="lgbt" valuePropName="checked">
              <Switch />
            </Form.Item>
          )}
          {user?.createdAt && (
            <Form.Item label={i18next.t('Reg Date')}>
              <Input
                value={DateHelper.formateDateToString(
                  user.createdAt,
                  FORMAT_SHORT_DATE,
                )}
                disabled
              />
            </Form.Item>
          )}
          {role === Role.ANGEL && (
            <WidgetModalUIUploadFile
              formName="greeting"
              listType="picture"
              label={i18next.t('Video/audio introduction')}
              defaultFile={greeting}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18next.t('Location')} key="2" forceRender>
          <Form.Item
            label={i18next.t('Timezone')}
            name={['timezone', 'id']}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder={i18next.t('Timezone')}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {timezoneList.map((timezone) => (
                <Option
                  value={timezone.id}
                  key={`timezone_${timezone.id}`}
                  label={timezone.name}
                >
                  {timezone.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={i18next.t('Language')} name={['language', 'id']}>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder={i18next.t('Language')}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {languageList.map((language) => (
                <Option
                  value={language.id}
                  key={`language_${language.id}`}
                  label={language.name}
                >
                  {language.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18next.t('Notification')} key="3" forceRender>
          <Form.Item
            label={i18next.t('Email')}
            name="isNotifyEmail"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={i18next.t('Text chats')}
            name="isNotifyChat"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Tabs.TabPane>
        {role === Role.ANGEL && (
          <>
            <Tabs.TabPane tab={i18next.t('Listing')} key="4" forceRender>
              <Tabs
                className={'lang-tab'}
                defaultActiveKey="about-lang-en"
                activeKey={activeKey}
                onChange={handleTabChange}
              >
                <Tabs.TabPane
                  tab={'En'}
                  key="about-lang-en"
                  className="listing-tab-en"
                />

                <Tabs.TabPane tab={'Ру'} key="about-lang-ru" />
              </Tabs>

              <div className={getContentListingClass()}>
                <Form.Item
                  label={i18next.t('About')}
                  name={
                    activeKey === 'about-lang-ru'
                      ? ['profile', 'about_ru']
                      : ['profile', 'about']
                  }
                >
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  label={i18next.t('Rate')}
                  name={['tariff', 'rate']}
                  required
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    decimalSeparator=","
                    placeholder={i18next.t('Rate')}
                    min={0.01}
                    step={0.01}
                    addonBefore="€"
                  />
                </Form.Item>
                <Form.Item
                  label={i18next.t('Platform commission')}
                  name={['tariff', 'commission', 'id']}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={i18next.t('Commission')}
                  >
                    {commissionList.map((commission) => (
                      <Option
                        value={commission.id}
                        key={`commission_${commission.id}`}
                        label={commission.name}
                      >
                        {commission.name} - {commission.amount}%
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={i18next.t('Experience')}
                  name={['profile', 'experience']}
                  rules={[{ min: 0, type: 'number' }]}
                >
                  <InputNumber
                    placeholder={i18next.t('Experience')}
                    min={0}
                    step={1}
                  />
                </Form.Item>
                <Form.Item
                  label={i18next.t('Mental health role')}
                  name={['profile', 'healthRole']}
                >
                  <Select>
                    {healsRoleList.map((item) => (
                      <Option
                        value={item.name}
                        key={`healthRole_${item.id}`}
                        label={item.name}
                      >
                        {activeKey === 'about-lang-ru'
                          ? i18next.t(item.name)
                          : item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={i18next.t('Academic initials')}
                  name={['profile', 'academicInitials']}
                >
                  <Select>
                    {academicInitialList.map((item) => (
                      <Option
                        value={item.id}
                        key={`academicInitials_${item.id}`}
                        label={item.name}
                      >
                        {i18next.t(item.name)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={i18next.t('Qualifications')}>
                  <Form.List name="qualifications">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={`qualifications_${key}`}
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="baseline"
                            className="qualification-block"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'code']}
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'name']}
                              rules={[
                                {
                                  required: true,
                                  message: i18next.t(
                                    'The field must not be empty',
                                  ),
                                },
                              ]}
                            >
                              <Input placeholder={getQualificationCode(name)} />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Row gutter={16}>
                          <Col span={24}>
                            <Button
                              type="dashed"
                              onClick={() => add({ name: '', code: 'license' })}
                              block
                              icon={<PlusOutlined />}
                            >
                              {i18next.t('Add license')}
                            </Button>
                          </Col>
                          <Col span={24}>
                            <Button
                              type="dashed"
                              onClick={() =>
                                add({ name: '', code: 'prelicense' })
                              }
                              block
                              icon={<PlusOutlined />}
                            >
                              {i18next.t('Add prelicense')}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
                <Form.Item label={i18next.t('Specialties')} name="specialties">
                  <Checkbox.Group>
                    <Row>
                      {specialtyListState.map((specialty) => (
                        <Col span={12} key={`col_specialty_${specialty.id}`}>
                          <Checkbox
                            value={specialty.id}
                            key={`specialty_${specialty.id}`}
                          >
                            {specialty.name}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item
                  label={i18next.t('Types of therapy')}
                  name="therapies"
                >
                  <Checkbox.Group>
                    <Row>
                      {therapyList.map((therapy) => (
                        <Col span={12} key={`col_therapy_${therapy.id}`}>
                          <Checkbox
                            value={therapy.id}
                            key={`therapy_${therapy.id}`}
                          >
                            {therapy.name}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item label={i18next.t('Languages')} name="languages">
                  <Select
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    placeholder={i18next.t('Language')}
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {languageList.map((language) => (
                      <Option
                        value={language.id}
                        key={`languages_${language.id}`}
                        label={language.name}
                      >
                        {language.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="SEO" key="5" forceRender>
              <>
                <Form.Item
                  label={i18next.t('SEO title')}
                  name={['seo', 'title']}
                  required
                  rules={[
                    { required: true, whitespace: true },
                    { max: VALIDATE_MAX_SEO_TITLE, type: 'string' },
                  ]}
                >
                  <Input
                    showCount
                    placeholder={i18next.t('Enter the seo title')}
                    maxLength={VALIDATE_MAX_SEO_TITLE}
                  />
                </Form.Item>
                <Form.Item
                  label={i18next.t('SEO description')}
                  name={['seo', 'description']}
                  required
                  rules={[
                    { required: true, whitespace: true },
                    { max: VALIDATE_MAX_SEO_DESCRIPTION, type: 'string' },
                  ]}
                >
                  <TextArea
                    showCount
                    rows={4}
                    placeholder={i18next.t('Enter the seo description')}
                    maxLength={VALIDATE_MAX_SEO_DESCRIPTION}
                  />
                </Form.Item>
                <Form.Item
                  label={i18next.t('SEO keywords')}
                  name={['seo', 'keywords']}
                >
                  <TextArea
                    rows={4}
                    placeholder={i18next.t('Enter the seo keywords')}
                  />
                </Form.Item>
              </>
            </Tabs.TabPane>
          </>
        )}
      </Tabs>
    </Form>
  );
};

export default WidgetModaUserForm;
