import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { CookieStorage } from '@services/storage';
import { RootState } from '@store/index';
import { LINK_LOGIN } from '@utils';

const usePageVisibility = () => {
  const { data: storeData } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const cookieData = CookieStorage.parseToken();
        let link = '';

        if (cookieData?.userId && !storeData?.userId) {
          link = '/';
        }

        if (!cookieData?.userId && storeData?.userId) {
          link = LINK_LOGIN;
        }

        if (cookieData?.userId !== storeData?.userId) {
          link = LINK_LOGIN;
        }

        if (link) {
          window.location.href = link;
          window.location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default usePageVisibility;
