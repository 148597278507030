import dayjs from 'dayjs';

import {
  GET_LIST_SCHEDULE_FAILURE,
  GET_LIST_SCHEDULE_REQUEST,
  GET_LIST_SCHEDULE_SUCCESS,
  SET_DATE_OF_VIEW,
} from './Schedule.constant';
import { IScheduleState, ScheduleActionsTypes } from './Schedule.type';

const initialState: IScheduleState = {
  scheduleList: [],
  dateOfView: dayjs(),
  loading: false,
};

export default function scheduleReducer(
  state = initialState,
  action: ScheduleActionsTypes,
): IScheduleState {
  switch (action.type) {
    case GET_LIST_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_SCHEDULE_SUCCESS: {
      return {
        ...state,
        scheduleList: action.payload,
        loading: false,
      };
    }
    case GET_LIST_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_DATE_OF_VIEW: {
      return {
        ...state,
        dateOfView: action.payload.date,
      };
    }
    default:
      return state;
  }
}
