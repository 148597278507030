import { FullSpecialty } from '@entities/Specialty';
import { http } from '@utils';

const basePath = '/api/specialties';

export const getList = async (
  query?: Query['query'],
): Promise<{
  data: FullSpecialty[];
}> => {
  const { data } = await http.get<DataResponse<FullSpecialty[]>>(
    `${basePath}${query || ''}`,
  );

  return data;
};

export const create = async (
  body: Pick<FullSpecialty, 'name' | 'description'>,
) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullSpecialty>>(`${basePath}`, body);

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const getOne = async (
  id: FullSpecialty['id'],
): Promise<FullSpecialty> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullSpecialty>>(`${basePath}/${id}`);

  return data;
};

export const update = async (
  id: number,
  data: Partial<Omit<FullSpecialty, 'numberOfView' | 'numberOfExpert'>>,
): Promise<void> => {
  await http.put(`${basePath}/${id}`, data);
};

export const addUserToSpecialty = async ({
  id,
  userId,
}: {
  id: number;
  userId: number[];
}): Promise<void> => {
  await http.post(`${basePath}/${id}/add-angels`, { userId });
};

export const deleteUserFromSpecialty = async ({
  id,
  userId,
}: {
  id: number;
  userId: number;
}): Promise<void> => {
  await http.delete(`${basePath}/${id}/angel/${userId}`);
};
