import { useMemo } from 'react';

import { Button, Radio, RadioChangeEvent, Space, Typography } from 'antd';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';

import { Props } from '../index.type';

const { Title } = Typography;
const { Group } = Radio;

const UISortOverlay = ({ sortOption }: Props) => {
  const {
    queryOptions: { order },
    updateQuery,
  } = useQuery();

  const onChangeSortHandler = ({ target: { value } }: RadioChangeEvent) => {
    updateQuery({ order: JSON.parse(value as string) });
  };

  const onClearHandler = () => {
    updateQuery({ order: null });
  };

  const renderOptions = useMemo(
    () =>
      sortOption.map((item) => (
        <Radio
          value={JSON.stringify({ [item.name]: item.direction })}
          key={`option_${item.label}`}
        >
          {item.label}
        </Radio>
      )),
    [sortOption],
  );

  return (
    <Space direction="vertical" className="sort__overlay">
      <Title level={5}>{i18next.t('Sort')}</Title>
      <Group onChange={onChangeSortHandler} value={JSON.stringify(order)}>
        <Space direction="vertical">{renderOptions}</Space>
      </Group>
      <Button type="link" onClick={onClearHandler}>
        {i18next.t('Clear')}
      </Button>
    </Space>
  );
};

export default UISortOverlay;
