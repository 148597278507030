import { CookieStorage } from '@services/storage';

import {
  AUTH_LOGIN_EMAIL_REQUEST,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_LOGOUT_SUCCESS,
} from './Auth.constant';
import { AuthActionsTypes, IAuthState } from './Auth.type';

const initialState: IAuthState = {
  data: CookieStorage.parseToken(),
  loadingLogin: false,
};

export default function authReducer(
  state = initialState,
  action: AuthActionsTypes,
): IAuthState {
  switch (action.type) {
    case AUTH_LOGIN_EMAIL_REQUEST:
      return {
        ...state,
        loadingLogin: true,
      };
    case AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        data: CookieStorage.parseToken(),
        loadingLogin: false,
      };
    }
    case AUTH_LOGOUT_SUCCESS: {
      return {
        ...state,
        data: null,
      };
    }
    case AUTH_LOGIN_FAILURE:
    case AUTH_LOGOUT_FAILURE:
      return {
        ...state,
        loadingLogin: false,
      };
    default:
      return state;
  }
}
