/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Checkbox, Col, Form, Radio, RadioChangeEvent, Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';

import { CouponProduct } from '@entities/Coupon';
import { i18next } from '@i18n';

import { CouponUIItemAdd } from '../../../ui';
import { Props } from './index.type';

import './style.scss';

const CouponDetailSpecific = ({ form }: Props) => {
  const onChange = (e: RadioChangeEvent) => {
    const value = e?.target?.value || CouponProduct.ALL;

    if (value === CouponProduct.ALL) {
      form.setFieldsValue({ consultation: [] });
    }

    form.setFieldsValue({ product: value });
  };

  return (
    <CouponUIItemAdd title={i18next.t('Specific products')}>
      <div className="specific-wrapper">
        <Row>
          <Form.Item name="product">
            <Radio.Group onChange={onChange}>
              <Space direction="vertical">
                <Radio value={CouponProduct.ALL}>
                  {i18next.t('All products')}
                </Radio>
                <Radio value={CouponProduct.SPECIFIC}>
                  {i18next.t('Specific products')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={5}>{i18next.t('Select consultation')} </Title>
          </Col>
        </Row>
        <Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.product !== currentValues.product
            }
          >
            {({ getFieldValue }) => (
              <Form.Item name="consultation" className="specific-checkbox">
                <Checkbox.Group
                  className="specific-checkbox"
                  {...(getFieldValue('product') !== CouponProduct.SPECIFIC && {
                    disabled: true,
                  })}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <Checkbox value={30} className="custom-checkbox">
                        30 мин
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value={60} className="custom-checkbox">
                        60 мин
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value={90} className="custom-checkbox">
                        90 мин
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            )}
          </Form.Item>
        </Row>
      </div>
    </CouponUIItemAdd>
  );
};

export default CouponDetailSpecific;
