import { Route, Routes } from 'react-router-dom';

import { CommissionDetail, CommissionList } from '@modules/Commission';

const ViewCommission = () => {
  return (
    <Routes>
      <Route path=":commissionId" element={<CommissionDetail />} />
      <Route path="/" element={<CommissionList />} />
      <Route path="/add" element={<CommissionDetail />} />
    </Routes>
  );
};

export default ViewCommission;
