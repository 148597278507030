import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useQuery } from '@hooks';
import { columns } from '@modules/Transaction/constant';
import { RootState, useAppDispatch } from '@store/index';
import { getListTransactionRequest } from '@store/Transaction';
import { UITable } from '@ui';

const UserDetailBilling = () => {
  const dispatch = useAppDispatch();

  const { transactionList, meta, loading } = useSelector(
    (state: RootState) => state.transaction,
  );

  const { userId } = useParams<'userId'>();
  const { query, updateQuery } = useQuery({
    filter: {
      userId: userId ? +userId : null,
    },
  });

  const getRequest = useCallback(() => {
    dispatch(getListTransactionRequest(query));
  }, [dispatch, query]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  useEffect(() => {
    getRequest();
  }, [dispatch, getRequest]);

  return (
    <UITable
      dataSource={transactionList}
      loading={loading}
      columns={columns({ isUser: false })}
      meta={meta}
      onPaginationChange={onPaginationChange}
    />
  );
};

export default UserDetailBilling;
