import { Modal } from 'antd';

import { useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';

import { Props } from './index.type';

const UIModal = ({
  onAccept,
  title,
  children,
  className,
  visible,
  footer,
}: Props) => {
  const dispatch = useAppDispatch();

  const onCancelHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      footer={footer}
      className={className}
      title={title}
      visible={visible}
      onCancel={onCancelHandler}
      onOk={onAccept}
    >
      {children}
    </Modal>
  );
};

export default UIModal;
