import { useCallback, useEffect } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';

import { useQuery } from '@hooks';
import { i18next } from '@i18n';
import { getListBookingRequest } from '@store/Booking';
import { RootState, useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';
import { UIHeader, UITable } from '@ui';
import { ActionStatus } from '@utils';

import { columns, filterOptions, sortOption, tab } from '../constant';

const BookingList = () => {
  const { bookingList, meta, loading, status } = useSelector(
    (state: RootState) => state.booking,
  );

  const dispatch = useAppDispatch();

  const { query, updateQuery } = useQuery();

  const getRequest = useCallback(() => {
    dispatch(getListBookingRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    if (status === ActionStatus.UPDATED) {
      getRequest();
      dispatch(hideModal());
    }
  }, [dispatch, getRequest, status]);

  useEffect(() => {
    getRequest();
  }, [getRequest]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <>
      <UIHeader
        tab={tab}
        title={i18next.t('Bookings')}
        isSearch
        searchTitle={i18next.t('Filter bookings')}
        sortOption={sortOption({})}
        filterOptions={filterOptions}
        viewContainerName={i18next.t('Bookings')}
        isCalendar
        calendarField="start_date"
        calendarPlaceholder={[
          i18next.t('Start date min'),
          i18next.t('Start date max'),
        ]}
      />
      <Row>
        <UITable
          dataSource={bookingList}
          loading={loading}
          columns={columns({ dispatch })}
          meta={meta}
          onPaginationChange={onPaginationChange}
          isScrollDefault
        />
      </Row>
    </>
  );
};

export default BookingList;
