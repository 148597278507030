import { useCallback, useEffect, useState } from 'react';

import { Form } from 'antd';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '@store/index';
import { getOneReviewRequest, updateReviewRequest } from '@store/Review';
import { UICellRate, UIModal, UITextArea } from '@ui';

import { Props } from './index.type';

const WidgetModalReviewEdit = (props: Props) => {
  const { reviewId } = props;

  const { review } = useSelector((state: RootState) => state.review);

  const dispatch = useAppDispatch();

  const [message, setMessage] = useState<string>('');
  const [rating, setRating] = useState<number>(0);

  useEffect(() => {
    dispatch(
      getOneReviewRequest({
        reviewId,
      }),
    );
  }, [dispatch, reviewId]);

  useEffect(() => {
    if (review) {
      setRating(review.rating);
      setMessage(review.message);
    }
  }, [review]);

  const onAcceptHandler = useCallback(() => {
    dispatch(
      updateReviewRequest({
        id: reviewId,
        rating,
        message,
      }),
    );
  }, [dispatch, rating, reviewId, message]);

  return (
    <UIModal title="Edit review" visible onAccept={onAcceptHandler}>
      <Form.Item label="Rating">
        <UICellRate
          onChangedHandler={setRating}
          rating={rating}
          count={5}
          allowHalf={false}
        />
      </Form.Item>
      <Form.Item label="Text">
        <UITextArea rows={4} onChangeHandler={setMessage} value={message} />
      </Form.Item>
    </UIModal>
  );
};

export default WidgetModalReviewEdit;
