import { Button, InputNumber, Space } from 'antd';

import { i18next } from '@i18n';

import { Props } from './index.type';

import './style.scss';

const WidgetModalFilterNumberRange = (props: Props) => {
  const { filterValue, onFilterUpdate, min, max, step } = props;

  const clearHandler = () => {
    onFilterUpdate(null);
  };

  return (
    <Space direction="vertical" className="filter-slider__space">
      <Space>
        <span>{i18next.t('Min')}:</span>
        <InputNumber
          min={min}
          max={max}
          value={filterValue?.min}
          step={step}
          onChange={(e) => onFilterUpdate({ ...filterValue, min: e })}
        />
        <span>{i18next.t('Max')}:</span>
        <InputNumber
          min={min}
          max={max}
          value={filterValue?.max}
          step={step}
          onChange={(e) => onFilterUpdate({ ...filterValue, max: e })}
        />
      </Space>
      <Button
        type="link"
        className="modal-filter__button-clear"
        onClick={clearHandler}
      >
        {i18next.t('Clear')}
      </Button>
    </Space>
  );
};

export default WidgetModalFilterNumberRange;
