import { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { alertError } from '@store/Alert';
import { RootState } from '@store/index';
import {
  clearListUser,
  createReviewRequest,
  getListUserRequest,
} from '@store/Review';
import { UICellRate, UIModal, UISelect, UITextArea } from '@ui';
import { QueryHelper, Role } from '@utils';

import './style.scss';

const WidgetModalReviewCreate = () => {
  const dispatch = useDispatch();

  const { userLoading, userList } = useSelector(
    (state: RootState) => state.review,
  );

  const [userId, setUserId] = useState<number>();
  const [angelId, setAngelId] = useState<number>();
  const [review, setReview] = useState<string>('');
  const [rating, setRating] = useState<number>(0);

  const onAcceptHandler = useCallback(() => {
    if (!angelId || !userId) {
      dispatch(alertError('User and angel should be filled'));

      return;
    }

    dispatch(
      createReviewRequest({
        userId,
        angelId,
        rating,
        message: review,
      }),
    );
  }, [angelId, userId, review, rating, dispatch]);

  const searchUserListHandler = (search: string) => {
    dispatch(
      getListUserRequest(
        QueryHelper.stringify({
          filter: { search: search || null, role: Role.USER },
        }),
      ),
    );
  };

  const searchAngelListHandler = (search: string) => {
    dispatch(
      getListUserRequest(
        QueryHelper.stringify({
          filter: { search: search || null, role: Role.ANGEL },
        }),
      ),
    );
  };

  const onSelectHandler = () => {
    dispatch(clearListUser());
  };

  return (
    <UIModal title="Create review" onAccept={onAcceptHandler}>
      <UISelect
        options={userList}
        searchHandler={searchUserListHandler}
        setValue={setUserId}
        isLoading={userLoading}
        labelField="profile.fullName"
        valueField="id"
        title="User"
        onSelectHandler={onSelectHandler}
        className="user-select"
      />
      <UISelect
        title="Angel"
        options={userList}
        searchHandler={searchAngelListHandler}
        setValue={setAngelId}
        isLoading={userLoading}
        labelField="profile.fullName"
        valueField="id"
        onSelectHandler={onSelectHandler}
        className="angel-select"
      />
      <UICellRate
        onChangedHandler={setRating}
        rating={rating}
        count={5}
        allowHalf={false}
      />
      <UITextArea onChangeHandler={setReview} />
    </UIModal>
  );
};

export default WidgetModalReviewCreate;
