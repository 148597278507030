import { Spin } from 'antd';
import Link from 'antd/lib/typography/Link';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { i18next } from '@i18n';
import { URL_COLLECTION } from '@utils';

import { Props } from './index.type';

import './style.scss';

const CollectionDetailSEO = ({
  loading,
  collection,
  onEditClickHandler,
}: Props) => {
  const link = `${URL_COLLECTION}/${collection?.seo?.slug}`;

  return (
    <Spin size="large" spinning={loading}>
      <div className="card-collections-psychologists-preview">
        <div className="card-collections-psychologists-preview-header">
          <Title level={5}>{i18next.t('Search engine listing preview')}</Title>
          <Link onClick={onEditClickHandler}>
            {i18next.t('Edit website SEO')}
          </Link>
        </div>
        <div className="card-collections-psychologists-preview-content">
          <Title level={3}>{collection?.seo?.title}</Title>
          <Text>{collection?.seo?.description}</Text>
        </div>
        <div className="card-collections-psychologists-preview-content">
          <a href={link} target="_blank">
            {link}
          </a>
        </div>
      </div>
    </Spin>
  );
};

export default CollectionDetailSEO;
