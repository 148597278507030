import { FullUserAction } from '@entities/UserAction';
import { http } from '@utils';

const basePath = '/api/users-actions';

export const getList = async (
  query = '',
): Promise<DataResponse<FullUserAction[]>> => {
  const { data } = await http.get<DataResponse<FullUserAction[]>>(
    `${basePath}${query}`,
  );

  return data;
};
