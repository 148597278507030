import { Layout } from 'antd';
import { Provider } from 'react-redux';

import { AppProvider } from './context';
import store from './store';
import ViewRouter from './views';
import { WidgetAlert, WidgetModal } from './widgets';

import 'antd/dist/antd.min.css';
import '@i18n';

function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <Layout className="page-layout">
          <ViewRouter />
          <WidgetAlert />
          <WidgetModal />
        </Layout>
      </AppProvider>
    </Provider>
  );
}

export default App;
