export type FullPayment = {
  info: string;
  price: number;
};

export const PRECENTAGE = [10, 30, 50, 70, 100] as const;

export type Refund = {
  bookingId: number;
  deductFromExpert: boolean;
  percentage: typeof PRECENTAGE[number];
};
