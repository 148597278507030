import { FullTimezone } from '@entities/Timezone';

import {
  GET_LIST_TIMEZONE_FAILURE,
  GET_LIST_TIMEZONE_REQUEST,
  GET_LIST_TIMEZONE_SUCCESS,
} from './Timezone.constant';
import { TimezoneActionsTypes } from './Timezone.type';

export function getListTimezoneRequest(): TimezoneActionsTypes {
  return {
    type: GET_LIST_TIMEZONE_REQUEST,
  };
}

export function getListTimezoneSuccess(
  timezones: FullTimezone[],
): TimezoneActionsTypes {
  return {
    type: GET_LIST_TIMEZONE_SUCCESS,
    payload: {
      timezones,
    },
  };
}

export function getListTimezoneFailure(): TimezoneActionsTypes {
  return {
    type: GET_LIST_TIMEZONE_FAILURE,
  };
}
