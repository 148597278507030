import { useCallback, useEffect } from 'react';

import { Alert } from 'antd';
import { useSelector } from 'react-redux';

import { i18next } from '@i18n';
import { alertClear } from '@store/Alert';
import { RootState, useAppDispatch } from '@store/index';

import style from './style.module.scss';

const WidgetAlert = () => {
  const dispatch = useAppDispatch();
  const { message, type, description } = useSelector(
    (state: RootState) => state.alert,
  );

  const handlerClose = useCallback(() => {
    dispatch(alertClear());
  }, [dispatch]);

  useEffect(() => {
    if (type && message) {
      setTimeout(() => handlerClose(), 6000);
    }
  }, [type, message, handlerClose]);

  return type && message ? (
    <div className={style.container}>
      <Alert
        message={i18next.t(message)}
        description={description}
        type={type}
        showIcon
      />
    </div>
  ) : null;
};

export default WidgetAlert;
