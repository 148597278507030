import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';

import { Props } from './index.type';

const UICellAction = ({ dropdownMenu, buttons }: Props) => {
  const renderButton = () =>
    buttons?.map(({ title, ...buttonProps }, id) => (
      <Button {...buttonProps} type="link" key={`cell_action_${id}`}>
        {title}
      </Button>
    ));

  return (
    <Space size="small">
      {renderButton()}
      {dropdownMenu && (
        <Dropdown overlay={dropdownMenu} placement="bottomRight">
          <MoreOutlined />
        </Dropdown>
      )}
    </Space>
  );
};

export default UICellAction;
