import { Button, Space } from 'antd';

import { i18next } from '@i18n';
import { useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { Role } from '@utils';

import { Props } from './index.type';

const UserUIButton = ({ role }: Props) => {
  const dispatch = useAppDispatch();

  const onCreateClientClick = () => {
    dispatch(showModal({ modalType: ModalType.USER, modalProps: { role } }));
  };

  const user = role === Role.USER ? 'client' : 'psychologist';

  return (
    <Space>
      <Button type="primary" onClick={onCreateClientClick} size="large">
        {i18next.t(`Create ${user}`)}
      </Button>
    </Space>
  );
};

export default UserUIButton;
