import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

import { Props } from './index.type';

const UIAvatar = (props: Props) => {
  const { path, className, size = 'default' } = props;

  return (
    <Avatar
      className={className}
      src={path}
      size={size}
      icon={<UserOutlined />}
    />
  );
};

export default UIAvatar;
