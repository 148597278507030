import { useCallback, useEffect, useMemo, useState } from 'react';

import { Row } from 'antd';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';

import { i18next } from '@i18n';
import { LayoutInput } from '@layouts';
import { alertError } from '@store/Alert';
import { getOneBookingRequest, updateBookingRequest } from '@store/Booking';
import { RootState, useAppDispatch } from '@store/index';
import { getListScheduleRequest, setDateOfView } from '@store/Schedule';
import { UIAntDatePicker, UIModal, UISelect } from '@ui';
import { DateHelper, QueryHelper } from '@utils';

import { Props } from './index.type';

const WidgetModalBookingEdit = ({ id, duration }: Props) => {
  const { scheduleList, dateOfView, loading } = useSelector(
    (state: RootState) => state.schedule,
  );
  const { booking } = useSelector((state: RootState) => state.booking);

  const dispatch = useAppDispatch();

  const [date, setDate] = useState<string>();

  useEffect(() => {
    dispatch(getOneBookingRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (booking?.angel.id && duration && dateOfView) {
      dispatch(
        getListScheduleRequest(
          QueryHelper.stringify({
            filter: {
              user_id: booking?.angel.id,
              duration,
              start_date: DateHelper.startOfDayToISOString(dateOfView),
              end_date: DateHelper.endOfDayToISOString(dateOfView),
            },
          }),
        ),
      );
    }
  }, [dispatch, duration, dateOfView, booking?.angel.id]);

  const updateDayOfViewHandler = (startDate: Dayjs | null) => {
    if (!startDate) {
      return;
    }

    dispatch(setDateOfView(startDate));
  };

  const onAcceptHandler = useCallback(() => {
    if (!(id && date)) {
      dispatch(alertError(i18next.t('All fields are required')));

      return;
    }

    dispatch(
      updateBookingRequest({
        id,
        date,
      }),
    );
  }, [id, dispatch, date]);

  const vacantScheduleList = useMemo(
    () => scheduleList.filter(({ isBooked }) => !isBooked),
    [scheduleList],
  );

  return (
    <UIModal
      title={i18next.t('Edit booking')}
      visible
      onAccept={onAcceptHandler}
    >
      <LayoutInput title={i18next.t('Duration')} className="vertical-gap">
        <>{duration}</>
      </LayoutInput>

      <LayoutInput title={i18next.t('Date')} className="vertical-gap">
        <UIAntDatePicker onChange={updateDayOfViewHandler} value={dateOfView} />
      </LayoutInput>

      <Row>
        <UISelect
          title={i18next.t('Time')}
          isLoading={loading}
          labelField="startDate"
          valueField="startDate"
          options={vacantScheduleList}
          setValue={setDate}
          size="large"
        />
      </Row>
    </UIModal>
  );
};

export default WidgetModalBookingEdit;
