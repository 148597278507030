import { META_DEFAULT } from '@utils';

import {
  ADD_UPDATE_COMMISSION_FAILURE,
  ADD_UPDATE_COMMISSION_REQUEST,
  ADD_UPDATE_COMMISSION_SUCCESS,
  DELETE_COMMISSION_FAILURE,
  DELETE_COMMISSION_REQUEST,
  DELETE_COMMISSION_SUCCESS,
  GET_COMMISSION_FAILURE,
  GET_COMMISSION_REQUEST,
  GET_COMMISSION_SUCCESS,
  GET_LIST_COMMISSION_FAILURE,
  GET_LIST_COMMISSION_REQUEST,
  GET_LIST_COMMISSION_SUCCESS,
  RESET_COMMISSION,
} from './Commission.constant';
import { CommissionActionsTypes, ICommissionState } from './Commission.type';

const initialState: ICommissionState = {
  commission: null,
  commissionList: [],
  metaCommissionUsers: META_DEFAULT,
  loading: false,
};

export default function commissionReducer(
  state = initialState,
  action: CommissionActionsTypes,
): ICommissionState {
  switch (action.type) {
    case GET_LIST_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_COMMISSION_SUCCESS:
      return {
        ...state,
        commission: null,
        metaCommissionUsers: META_DEFAULT,
        commissionList: action.payload.data,
        loading: false,
      };
    case GET_LIST_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMMISSION_SUCCESS:
      return {
        ...state,
        commission: action.payload.data,
        metaCommissionUsers: action.payload.meta,
        loading: false,
      };
    case GET_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case ADD_UPDATE_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_UPDATE_COMMISSION_SUCCESS:
      return {
        ...state,
        commission: {
          users: [],
          ...(state.commission || {}),
          ...action.payload.data,
        },
        loading: false,
      };

    case ADD_UPDATE_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case DELETE_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RESET_COMMISSION:
      return {
        ...state,
        commission: null,
        metaCommissionUsers: META_DEFAULT,
        loading: false,
      };

    default:
      return state;
  }
}
