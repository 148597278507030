import { ColumnsType } from 'antd/lib/table';

import {
  CouponCodeType,
  CouponPeriod,
  CouponProduct,
  CouponUsage,
  FullCoupon,
} from '@entities/Coupon';
import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import { i18next } from '@i18n';
import type { AppDispatch } from '@store/index';
import { UICellLink, UICellStatus } from '@ui';
import {
  ActiveStatus,
  COLUMN_WIDTH_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_TAG,
  DateHelper,
  FORMAT_DATE_TIME_WITH_DOT,
  StringHelper,
} from '@utils';

import { CouponUIActionList } from '../ui';

export const columns = ({
  dispatch,
  path,
}: {
  dispatch: AppDispatch;
  path: string;
}): ColumnsType<FullCoupon> => [
  {
    title: i18next.t('Id'),
    dataIndex: 'id',
    key: 'id',
    render: (value, record) =>
      UICellLink({ value, link: `${path}/${record.id}` }),
    width: COLUMN_WIDTH_ID,
  },
  {
    title: i18next.t('Code'),
    dataIndex: 'code',
    key: 'code',
    width: COLUMN_WIDTH_NAME,
  },
  {
    title: i18next.t('Status'),
    dataIndex: 'isActive',
    key: 'isActive',
    width: COLUMN_WIDTH_TAG,
    render: (value) =>
      UICellStatus({
        value,
        text: value ? i18next.t('Active') : i18next.t('Inactive'),
      }),
  },
  {
    title: i18next.t('Type'),
    dataIndex: 'codeType',
    key: 'codeType',
    width: COLUMN_WIDTH_NAME,
    render: (value: CouponCodeType) => {
      switch (value) {
        case CouponCodeType.FIX_AMOUNT:
          return i18next.t('Fixed amount');
        default:
          return i18next.t('Percentage');
      }
    },
  },
  {
    title: i18next.t('Value'),
    dataIndex: 'value',
    key: 'value',
    width: COLUMN_WIDTH_NUMBER,
    render: (value, obj) => {
      switch (obj.codeType) {
        case CouponCodeType.FIX_AMOUNT:
          return `€${value}`;
        default:
          return `${value}%`;
      }
    },
  },
  {
    title: i18next.t('Date issued'),
    dataIndex: 'createdAt',
    key: ' createdAt',
    width: COLUMN_WIDTH_DATE,
    render: (value) =>
      DateHelper.formateDateToString(
        value as string,
        FORMAT_DATE_TIME_WITH_DOT,
      ),
  },
  {
    title: i18next.t('Usage limits'),
    dataIndex: 'usage',
    key: 'usage',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: CouponUsage) => {
      switch (value) {
        case CouponUsage.ONE:
          return i18next.t('One use');
        case CouponUsage.LIMIT:
          return i18next.t('Limited use');
        default:
          return i18next.t('Unlimited use');
      }
    },
  },
  {
    title: i18next.t('Specific products'),
    dataIndex: 'product',
    key: 'product',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: CouponProduct) => {
      switch (value) {
        case CouponProduct.ALL:
          return i18next.t('All products');
        default:
          return i18next.t('Specific products');
      }
    },
  },
  {
    title: i18next.t('Period of use'),
    dataIndex: 'period',
    key: 'period',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: CouponPeriod) => {
      switch (value) {
        case CouponPeriod.SPECIFIC:
          return i18next.t('Specific period');
        default:
          return i18next.t('Unlimited time');
      }
    },
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (id: number) => CouponUIActionList({ id, dispatch }),
    align: 'right',
    width: COLUMN_WIDTH_ID,
  },
];

export const orderOption: SortOption[] = [
  {
    label: i18next.t('Id (ascending)'),
    name: 'id',
    direction: 'asc',
  },
  {
    label: i18next.t('Id (descending)'),
    name: 'id',
    direction: 'desc',
  },
  {
    label: i18next.t('Date issued (oldest first)'),
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: i18next.t('Date issued (newest first)'),
    name: 'created_at',
    direction: 'desc',
  },
];

export const filterOptions: FilterOption[] = [
  {
    name: 'is_active',
    label: i18next.t('Active'),
    type: 'radio',
    options: [
      {
        label: i18next.t(StringHelper.capitalize(ActiveStatus.YES)),
        value: 'true',
      },
      {
        label: i18next.t(StringHelper.capitalize(ActiveStatus.NO)),
        value: 'false',
      },
    ],
  },
  {
    name: 'code_type',
    label: i18next.t('Type'),
    type: 'radio',
    options: [
      {
        label: i18next.t('Fixed amount'),
        value: CouponCodeType.FIX_AMOUNT,
      },
      {
        label: i18next.t('Percentage'),
        value: CouponCodeType.PERCENTAGE,
      },
    ],
  },
  {
    name: 'Value',
    label: i18next.t('value'),
    type: 'number-range',
    step: 1,
    min: 0,
  },
  {
    name: 'product',
    label: i18next.t('Specific products'),
    type: 'radio',
    options: [
      {
        label: i18next.t('All products'),
        value: CouponProduct.ALL,
      },
      {
        label: i18next.t('Specific products'),
        value: CouponProduct.SPECIFIC,
      },
    ],
  },
  {
    name: 'usage',
    label: i18next.t('Usage limits'),
    type: 'checkbox',
    options: [
      {
        label: i18next.t('One use'),
        value: CouponUsage.ONE,
      },
      {
        label: i18next.t('Limited use'),
        value: CouponUsage.LIMIT,
      },
      {
        label: i18next.t('Unlimited use'),
        value: CouponUsage.UNLIMIT,
      },
    ],
  },
  {
    name: 'period',
    label: i18next.t('Period of use'),
    type: 'radio',
    options: [
      {
        label: i18next.t('Unlimited time'),
        value: CouponPeriod.UNLIMIT,
      },
      {
        label: i18next.t('At the specified period'),
        value: CouponPeriod.SPECIFIC,
      },
    ],
  },
];
