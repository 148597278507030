import { useEffect, useState } from 'react';

import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import { i18next } from '@i18n';
import {
  filterOptions as bookingFilter,
  sortOption as bookingSort,
} from '@modules/Booking/constant';
import {
  filterOptions as chatFilter,
  sortOption as chatSort,
} from '@modules/Chat/constant';
import {
  filterOptions as reviewFilter,
  sortOption as reviewSort,
} from '@modules/Review/constant';
import {
  filterOptions as billingFilter,
  sortOption as billingSort,
} from '@modules/Transaction/constant';
import { Role } from '@utils';

type Props = {
  activeTab: string;
  role: Role;
};

const useActiveTab = ({ activeTab, role }: Props) => {
  const [state, setState] = useState<{
    filterOptions: FilterOption[];
    orderOption: SortOption[];
    searchTitle: string;
  }>({
    filterOptions: billingFilter,
    orderOption: billingSort({ isUser: false }),
    searchTitle: i18next.t('Filter billing history'),
  });

  useEffect(() => {
    switch (activeTab) {
      case 'Billing History':
        setState({
          filterOptions: billingFilter,
          orderOption: billingSort({ isUser: false }),
          searchTitle: i18next.t('Filter billing history'),
        });
        break;
      case 'Bookings':
        setState({
          filterOptions: bookingFilter,
          orderOption: bookingSort({ role }),
          searchTitle: i18next.t('Filter bookings'),
        });
        break;
      case 'Reviews':
        setState({
          filterOptions: reviewFilter,
          orderOption: reviewSort({ role }),
          searchTitle: i18next.t('Filter review'),
        });
        break;
      case 'Chats':
        setState({
          filterOptions: chatFilter,
          orderOption: chatSort({ role }),
          searchTitle: i18next.t('Filter chat'),
        });
        break;

      default:
        setState({
          filterOptions: billingFilter,
          orderOption: billingSort({ isUser: false }),
          searchTitle: i18next.t('Filter billing history'),
        });
    }
  }, [activeTab, role]);

  return state;
};

export default useActiveTab;
